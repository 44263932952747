import React from 'react';
import styles from './SocialButtons.module.scss';
import iconGoogle from '../../../../assets/images/icons/google_icon_light_normal.svg';
import iconFacebook from '../../../../assets/images/icons/facebook_icon_normal.svg';

const Button = ({ type, label, url }) => {
  return (
    <div className={`button ${styles.socialButton}`}>
      <a
        className={
          `${type === 'Facebook' ? styles.buttonFacebook : styles.buttonGoogle }`
        }
        href={url}
        data-method="POST"
      >
        <div>
          <img
            src={type === 'Facebook' ? iconFacebook : iconGoogle}
            alt={type}
          />
        </div>
        <p>{label} {type}</p>
      </a>
    </div>
  );
};

export default Button;
