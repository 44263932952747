import React from 'react';

const Separator = ({ label }) => {
  return (
    <div className="separate-line">
      <div className="-text-or -bg-normal-gray -tc-">{ label }</div>
    </div>
  );
};

export default Separator;
