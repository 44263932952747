import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class CashflowBarchart extends Component {
  constructor(props) {
    super(props);

    const { numberToIntegerCurrency, locales } = props;

    this.state = {

      series: [{
        name: 'CashFlow P.A.',
        data: this.props.cashflow
      }, {
        name: 'CashFlow Ohne Tilgung',
        data: this.props.cashflow_without_repayments
      }, {
        name: 'Tilgung',
        data: this.props.repayments
      }],
      options: {
        chart: {
          type: 'bar',
          zoom: {
            type: 'x',
            enabled: false
          },
          toolbar: {
            autoSelected: 'zoom',
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          offsetX: -5,
          style: {
            fontSize: "10px",
            colors: ['#095394', '#25a357', '#ff9800']
          },
          formatter (val) {
            return `${numberToIntegerCurrency(val, { locale: locales.en })}`
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        fill: {
          opacity: 1,
          colors: ['#095394', '#25a357', '#ff9800']
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter(value) {
              return `${numberToIntegerCurrency(value, { locale: locales.en })}`;
            }
          },
        },
        colors: ['#095394', '#25a357', '#ff9800']
      },
    };
  }

  render() {
    return (
      <div id="CashflowBarchart">
        <p className="chartHeading">Cashflow-Entwicklung über 10 Jahre</p>
        <div className='stackedChart'>
          <Chart options={this.state.options} series={this.state.series}
            type="bar" width={800}
            height={280}
          />
        </div>
      </div>
    )
  }
}

export default CashflowBarchart;
