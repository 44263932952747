/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import ColdRentRadio from '../../../InvestmentCalculationContainer/ColdRentRadio';
import DebounceInput from 'uiKit/inputs/DebounceInput/DebounceInput'
import I18n from 'i18n-js/index.js.erb';
import InfoTooltip from '../../../Tooltips/InfoTooltip/InfoTooltip';

export default class EditIncomeStatementForm extends PureComponent {
  updateColdRentSelectedOption = (event) => {
    const { name, value } = event.target;

    if (!value) {
      return false;
    } else {
      this.props.handleChange(name, value);
      this.props.updateColdRentSelectedOption();
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    if (!value) {
      return false;
    } else {
      this.props.handleChange(name, value);
    }
  }

  render() {
    const {
      coldRent,
      currencyFormatPercentage,
      extraOrdinaryExpenses,
      formatNumber,
      formatNumberWithoutCurrency,
      lossOfRentalIncome,
      lossOfRentalIncomeConst,
      monthlyReservePayment,
      notAllocableOperatingCosts,
      renderMadeAssumptionTip,
      specialPropertyManagement
    } = this.props;

    const defaultColdRent = parseFloat(this.props.default_cold_rent);
    const defaultLossOfRentalIncome = parseFloat(this.props.defaultLossOfRentalIncome);
    const defaultNotAllocableOperatingCosts = parseFloat(this.props.defaultNotAllocableOperatingCosts);
    const defaultMonthlyReservePayment = parseFloat(this.props.defaultMonthlyReservePayment);
    const defaultSpecialPropertyManagement = parseFloat(this.props.defaultSpecialPropertyManagement);
    const defaultExtraordinaryExpenses = parseFloat(this.props.defaultExtraordinaryExpenses);

    const netPercent = (notAllocableOperatingCosts + monthlyReservePayment) / coldRent * 100;

    return (
      <tbody>
        <tr>
          <td>
          </td>
          <td>
            <p className='global-heading'> {I18n.t('js.investment_calculations.property_results.per_year')} </p>
          </td>
          <td>
            <p className='global-heading'> {I18n.t('js.investment_calculations.property_results.per_month')} </p>
          </td>
        </tr>
        <tr>
          <td> {I18n.t('js.investment_calculations.property_results.cold_rent')} </td>
          <td>
            <p>{formatNumber(coldRent * 12)}</p>
          </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="cold_rent"
              value={formatNumberWithoutCurrency(coldRent)}
              placeholder={0}
              onChange={this.updateColdRentSelectedOption}
              debounceTimeout={1100}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (coldRent != defaultColdRent) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.property_results.cold_rent'),
                      value: formatNumber(defaultColdRent)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td className='modal_radio'>
            <ColdRentRadio handleOptionChangeRent={this.props.handleOptionChangeRent}
              rent_selected_option={this.props.rent_selected_option}
              cold_rent={coldRent}
              default_cold_rent={defaultColdRent}
              cold_rent_15={this.props.cold_rent_15}
              market_price={this.props.market_price}
              comparable_cold_rent={this.props.comparable_cold_rent}
              custom_cold_rent={this.props.custom_cold_rent}
              rentedOut={this.props.rentedOut}
            />
          </td>
        </tr>
        <tr>
          <td className='minusSign'> {I18n.t('js.investment_calculations.property_results.loss_of_rental_income')} </td>
          <td>
            <p>
              {formatNumber(Math.abs(lossOfRentalIncomeConst))}
            </p>
          </td>
          <td>
            <input
              className="field js-closing-costs-field"
              type="text"
              value={formatNumberWithoutCurrency(Math.abs(lossOfRentalIncomeConst / 12))}
              readOnly
            />
            <span className="field symbol">€</span>
          </td>
          <td className="smallWidthInput">
            <DebounceInput
              className="field js-closing-costs-field"
              type="text" name="loss_of_rental_income"
              value={formatNumberWithoutCurrency(lossOfRentalIncome)}
              onChange={this.handleChange}
              placeholder={0}
            />
            <span className="field symbol">%</span>
          </td>
          {
            (lossOfRentalIncome != defaultLossOfRentalIncome) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.property_results.loss_of_rental_income'),
                      value: currencyFormatPercentage(defaultLossOfRentalIncome)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr className='relative'>
          <td className='minusSign'> {I18n.t('js.investment_calculations.property_results.non_allocable_operating_cost')} </td>
          <td>
            <p>{formatNumber(notAllocableOperatingCosts * 12)}</p>
          </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text" name="not_allocable_operating_costs"
              value={formatNumberWithoutCurrency(notAllocableOperatingCosts)}
              onChange={this.handleChange}
              placeholder={0}
              debounceTimeout={1100}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (notAllocableOperatingCosts != defaultNotAllocableOperatingCosts) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.property_results.non_allocable_operating_cost'),
                      value: formatNumber(defaultNotAllocableOperatingCosts)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
          <td className='netPercent'>
            <div className='col1'></div>
            <div className='col2'>
              <div className='row1'></div>
              <div className='row2'></div>
            </div>
            <div className='col3'>
              <span>{(currencyFormatPercentage(!netPercent ? 0 : netPercent))}</span>
              <div className="tipBlock">
                <InfoTooltip>
                  {I18n.t('js.investment_calculations.property_results.net_percent')}
                </InfoTooltip>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className='minusSign'>  {I18n.t('js.investment_calculations.property_results.maintainence_reserve_payment')} </td>
          <td>
            <p>{formatNumber(monthlyReservePayment * 12)}</p>
          </td>
          <td>
            <DebounceInput className="field js-closing-costs-field"
              type="text" name="monthly_reserve_payment"
              value={formatNumberWithoutCurrency(monthlyReservePayment)}
              onChange={this.handleChange}
              placeholder={0}
              debounceTimeout={1100}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (monthlyReservePayment != defaultMonthlyReservePayment) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.property_results.maintainence_reserve_payment'),
                      value: formatNumber(defaultMonthlyReservePayment)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr>
          <td className='minusSign'> {I18n.t('js.investment_calculations.property_results.special_property_management')} </td>
          <td>
            <p>{formatNumber(specialPropertyManagement * 12)}</p>
          </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="special_property_management"
              placeholder={0}
              value={formatNumberWithoutCurrency(specialPropertyManagement)}
              onChange={this.handleChange}
              debounceTimeout={1100}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (specialPropertyManagement != defaultSpecialPropertyManagement) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.property_results.special_property_management'),
                      value: formatNumber(defaultSpecialPropertyManagement)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr>
          <td className='minusSign'> {I18n.t('js.investment_calculations.property_results.additional_cost')} </td>
          <td>
            <p>
              {formatNumber(extraOrdinaryExpenses * 12)}
            </p>
          </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="extraordinary_expenses"
              placeholder={0}
              value={formatNumberWithoutCurrency(extraOrdinaryExpenses)}
              onChange={this.handleChange}
              debounceTimeout={1100}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (extraOrdinaryExpenses != defaultExtraordinaryExpenses) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.property_results.additional_cost'),
                      value: formatNumber(defaultExtraordinaryExpenses)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
      </tbody>
    )
  }
}
