/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';

import {
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const styles = StyleSheet.create({
  table: { display: "table", width: "100%" },
  tableRow: { margin: "auto", flexDirection: "row", display: "table-row" },
  tableRowDarkBG: { margin: "auto", flexDirection: "row", display: "table-row", backgroundColor: "#93989f", color: "#fff", fontWeight: "bold" },
  tableCol: { width: "8%", display: "table-cell" , borderTopWidth: 0, padding: "2px", border:'1 solid #DDD',  },
  tableCol10: { width: "8%", display: "table-cell" , borderTopWidth: 0, padding: "2px", borderBottom:'1 solid #DDD', borderRight:'1 solid #FF9800',borderLeft:'1 solid #FF9800'  },
  tableCol10Year:{ width: "8%", display: "table-cell" , borderTopWidth: 0, padding: "2px", borderRight:'1 solid #FF9800',borderLeft:'1 solid #FF9800',borderTop:'1 solid #FF9800'  },
  tableCol10Bottom:{ width: "8%", display: "table-cell" , borderTopWidth: 0, padding: "2px", borderRight:'1 solid #FF9800',borderLeft:'1 solid #FF9800',borderBottom:'1 solid #FF9800'  },
  tableColHeading: { width: "20%", display: "table-cell" , borderTopWidth: 0, padding: "2px", border:'1 solid #DDD', textAlign: 'left' },
  tableCell: { padding: "2px", fontSize: 8, },
});

export default class CompleteInvestmentCalculation extends PureComponent {
  renderFieldCurrency = (objectKey) => {
    return this.props.total_investment_calculation_pdf.map((obj, key) => {
      const tableColStyles = obj.Year === 10 ? styles.tableCol10 : styles.tableCol;

      return this.renderViewElement(tableColStyles, key, this.props.formatNumber(obj[objectKey]));
    });
  }

  renderFieldCurrencyTaxNegative = (objectKey) => {
    return this.props.total_investment_calculation_pdf.map((obj, key) => {
      const tableColStyles = obj.Year === 10 ? styles.tableCol10 : styles.tableCol;

      return this.renderViewElement(tableColStyles, key, this.props.formatNumber(- obj[objectKey]));
    });
  }

  renderFieldPercentage = (objectKey) => {
    return this.props.total_investment_calculation_pdf.map((obj, key) => {
      let tableColStyles;

      if ((obj.Year === 10) && (objectKey == 'roe_after_sale_without_equity_appreciation_pa')) {
        tableColStyles = styles.tableCol10Bottom;
      } else if (obj.Year === 10) {
        tableColStyles = styles.tableCol10;
      } else {
        tableColStyles = styles.tableCol;
      }

      return this.renderViewElement(tableColStyles, key, this.props.currencyFormatPercentage(obj[objectKey]));
    });
  }

  renderField = (objectKey) => {
    return this.props.total_investment_calculation_pdf.map((obj, key) => {
      const tableColStyles = obj.Year === 10 ? styles.tableCol10Year : styles.tableCol;

      return this.renderViewElement(tableColStyles, key, obj[objectKey]);
    });
  }

  blankBlocks = () => {
    return this.props.total_investment_calculation_pdf.map((d, key) => {
      const tableColStyles = d.Year === 10 ? styles.tableCol10 : styles.tableCol;

      return (
        <View style={tableColStyles} key={key}
          colSpan="2"
        >
          <Text style={styles.tableCell}>
            &nbsp;
          </Text>
        </View>
      );
    });
  }

  renderViewElement = (tableColStyles, key, value) => {
    return (
      <View style={tableColStyles} key={key}
        colSpan="2"
      >
        <Text style={styles.tableCell}>
          { value }
        </Text>
      </View>
    );
  }

  render() {
    const blankBlocks = this.blankBlocks();

    return (
      <View class="customModalPureComponent">
        { this.props.total_investment_calculation_pdf.length > 0 &&
          <View class='customModal complete_investment_calculation'>
            <View style={styles.table}>
              <View style={[styles.tableRow,{ backgroundColor: '#f5f5f5' }]}  class='year'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.complete_investment_calculation')}
                  </Text>
                </View >
                {this.renderField('Year')}
              </View >

              <View style={styles.tableRow}>
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.rent_increase')}
                  </Text>
                </View >
                {this.renderFieldPercentage('rent_increase')}
              </View >

              <View style={styles.tableRow}  class='italic_styling'>
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.monthly_rent_per_m2')}
                  </Text>
                </View >
                {this.renderFieldCurrency('monthly_rent_per_m2')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5', fontFamily: 'Open Sans', fontWeight: 800 } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.net_annual_rent')}
                  </Text>
                </View >
                {this.renderFieldCurrency('net_annual_rent')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.loss_of_rental_income')}
                  </Text>
                </View >
                {this.renderFieldCurrency('loss_of_rental_income')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor:'#e5e5e5', fontFamily: 'Open Sans', fontWeight: 800 }]}  class='rental_income'>
                <View style={[styles.tableColHeading, { fontFamily: 'Open Sans', fontWeight: 800  } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.assumed_rental_income')}
                  </Text>
                </View >
                {this.renderFieldCurrency('rental_income')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.total_taxable_not_allocable_operating_costs')}
                  </Text>
                </View >
                {this.renderFieldCurrency('total_taxable_not_allocable_operating_costs')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.depreciation')}
                  </Text>
                </View >
                {this.renderFieldCurrency('depreciation_form')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.interest_costs')}
                  </Text>
                </View >
                {this.renderFieldCurrency('interest_costs')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#e4f1d5' } ]} class='taxable_income'>
                <View style={[styles.tableColHeading, {  fontFamily: 'Open Sans', fontWeight: 800  } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.taxable_income')}: {I18n.t('js.investment_calculations.complete_investment_table.income_after_interest')}
                  </Text>
                </View >
                {this.renderFieldCurrency('taxable_income')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.tax')}
                  </Text>
                </View >
                {this.renderFieldCurrency('tax')}
              </View >

              <View style={styles.tableRow}  class='white_bg'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>

                  </Text>
                </View >
                {blankBlocks}
              </View >

              <View style={[ styles.tableRow, { backgroundColor:'#e5e5e5', fontFamily: 'Open Sans', fontWeight: 800 }]}  class='rental_income'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.assumed_rental_income')}
                  </Text>
                </View >
                {this.renderFieldCurrency('rental_income')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.total_not_applicable_operation_cost')}
                  </Text>
                </View >
                {this.renderFieldCurrency('not_applicable_operation_cost')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.tax')}
                  </Text>
                </View >
                {this.renderFieldCurrencyTaxNegative('tax')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.interest_costs')}
                  </Text>
                </View >
                {this.renderFieldCurrency('interest_costs')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.repayments')}
                  </Text>
                </View >
                {this.renderFieldCurrency('repayments')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.extraordinary_expenses')}
                  </Text>
                </View >
                {this.renderFieldCurrency('extraordinary_expenses')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#cae3ac', fontFamily: 'Open Sans', fontWeight: 800 } ]} class='cashflow'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.cashflow')}
                  </Text>
                </View >
                {this.renderFieldCurrency('cashflow')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#e4f1d5' } ]}  class='light_green'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.cashflow_per_month')}
                  </Text>
                </View >
                {this.renderFieldCurrency('cashflow_per_month')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#e4f1d5' } ]}  class='light_green'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.return_on_equity_after_tax')}
                  </Text>
                </View >
                {this.renderFieldPercentage('return_on_equity_after_tax')}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.return_on_equity_before_tax')}
                  </Text>
                </View >
                {this.renderFieldPercentage('return_on_equity_before_tax')}
              </View >

              <View style={styles.tableRow}  class='white_bg'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>

                  </Text>
                </View >
                {blankBlocks}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.appreciation_per_annum')}
                  </Text>
                </View >
                {this.renderFieldPercentage('appreciation_per_annum')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor:'#e5e5e5', fontFamily: 'Open Sans', fontWeight: 800 }]}  class='resale_price'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.resale_price')}
                  </Text>
                </View >
                {this.renderFieldCurrency('resale_price')}
              </View >

              <View style={styles.tableRow}  class='italic_styling'>
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.resale_price_per_m2')}
                  </Text>
                </View >
                {this.renderFieldCurrency('resale_price_per_m2')}
              </View >

              <View style={styles.tableRow}  class='white_bg line_height'>
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>

                  </Text>
                </View >
                {blankBlocks}
              </View >

              <View style={styles.tableRow} >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.equity_from_appreciation')}
                  </Text>
                </View >
                {this.renderFieldCurrency('equity_from_appreciation')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.equity_from_repayments')}
                  </Text>
                </View >
                {this.renderFieldCurrency('total_repayments')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.equity_from_cashflow')}
                  </Text>
                </View >
                {this.renderFieldCurrency('equity_from_cashflow')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.real_cost_of_purchasing')}
                  </Text>
                </View >
                {this.renderFieldCurrency('real_cost_of_purchasing')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#cae3ac', fontFamily: 'Open Sans', fontWeight: 800 } ]} class='cashflow'  >
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.capital_appreciation_for_sale')}
                  </Text>
                </View >
                {this.renderFieldCurrency('capital_appreciation_for_sale')}
              </View >

              <View style={styles.tableRow} class='white_bg' >
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>

                  </Text>
                </View >
                {blankBlocks}
              </View >

              <View style={[styles.tableRow,{ backgroundColor: '#f5f5f5', fontFamily: 'Open Sans', fontWeight: 800 }]} class='grey_bg_bold' >
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.capital_appreciation_for_sale')}
                  </Text>
                </View >
                {this.renderFieldCurrency('capital_appreciation_for_sale')}
              </View >

              <View style={styles.tableRow} class='capital_appreciation_for_sale_without_equity_appreciation' >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.capital_appreciation_for_sale_without_equity_appreciation')}
                  </Text>
                </View >
                {this.renderFieldCurrency('capital_appreciation_for_sale_without_equity_appreciation')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.capital_employed')}
                  </Text>
                </View >
                {this.renderFieldCurrency('capital_employed')}
              </View >

              <View style={styles.tableRow} class='italic_styling' >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.equity_to_finance_the_purchase_price')}
                  </Text>
                </View >
                {this.renderFieldCurrency('equity_to_finance_the_purchase_price')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#cae3ac', fontFamily: 'Open Sans', fontWeight: 800 } ]} class='cashflow' >
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.roe_after_sale')}
                  </Text>
                </View >
                {this.renderFieldPercentage('roe_for_sale_incl_betterment')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    Ø {I18n.t('js.investment_calculations.complete_investment_table.return_on_equity_after_sale_PA')}
                  </Text>
                </View >
                {this.renderFieldPercentage('return_on_equity_after_sale_PA')}
              </View >

              <View style={[ styles.tableRow, { backgroundColor: '#e4f1d5' } ]}  >
                <View style={styles.tableColHeading}>
                  <Text style={styles.tableCell}>
                    {I18n.t('js.investment_calculations.complete_investment_table.roe_after_sale_without_equity_appreciation')}
                  </Text>
                </View >
                {this.renderFieldPercentage('roe_after_sale_without_equity_appreciation')}
              </View >

              <View style={styles.tableRow}  >
                <View style={[styles.tableColHeading, { backgroundColor: '#f5f5f5' } ]}>
                  <Text style={styles.tableCell}>
                    Ø {I18n.t('js.investment_calculations.complete_investment_table.roe_after_sale_without_equity_appreciation_pa')}
                  </Text>
                </View >
                {this.renderFieldPercentage('roe_after_sale_without_equity_appreciation_pa')}
              </View >
            </View>
          </View>
        }
      </View>
    )
  }
}
