import React, { useContext, useState } from 'react';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';
import { FormContext } from 'hocHelpers/withContactForm';
import styles from './Checkbox.module.scss';

/*eslint max-lines-per-function: ["error", 200]*/
const Checkbox = ({
  name,
  required,
  validation,
  children,
  isChecked = false
}) => {
  const { register, errors } = useContext(FormContext);
  const [checked, setChecked] = useState(isChecked);

  const validationSettings = {
    required: required && validation.required
  };

  const toggleCheck = () => {
    setChecked(prev => !prev);
  };

  return (
    <div className={ styles.Checkbox }>
      <label htmlFor={ name }>
        <input
          type="checkbox"
          { ...register(name, validationSettings) }
          id={ name }
          checked={checked}
          onClick={toggleCheck}
        />
        <div className="checkbox -orange"></div>
        <div className={ styles.labelText }>
          { children }
        </div>
      </label>
      <ValidationErrorMessage error={ errors[name] } validation={ validation } />
    </div>
  );
};
 
export default Checkbox;
