import React from 'react';
import PrimarySectionHeader from 'commonComponents/Headers/PrimarySectionHeader/PrimarySectionHeader';
import SectionHeader from 'commonComponents/Headers/SectionHeader/SectionHeader';
import Button from 'commonComponents/Button/Button';
import styles from './BaseSection.module.scss';

const BaseSection = ({
  title,
  subtitle,
  anchor,
  button,
  additionalClass = '',
  headerType = 'normal',
  children
}) => {
  const classes = `${styles.BaseSection} ${additionalClass}`;

  return (
    <section className={ classes }>
      { (title && subtitle && headerType === 'normal') && (
        <PrimarySectionHeader
          header={ title }
          subheader={ subtitle }
          anchor={ anchor }
        />
      ) }
      { headerType === 'simple' &&
        <SectionHeader
          title={ title }
          anchor={ anchor }
          isPrime={ true }          
        /> }
      <div className={ styles.content }>
        { children }
        { button && <Button { ...button } /> }
      </div>
    </section>
  );
};

export default BaseSection;
