import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from './LabelBetaTooltip.module.scss';
import I18n from 'i18n-js/index.js.erb';

function LabelBetaTooltip ({ tip, place = 'end', verticalPlace = 'top', 
  specialClass, labelAdditionalClasses }) {
  const classes = `${styles.LabelBetaTooltip} ${styles[place]} ${styles[verticalPlace]} ${specialClass}`;
  const labelClasses = `${styles.labelBeta} ${styles[labelAdditionalClasses]}`;

  function renderTooltip(props) {
    return (
      <Tooltip
        { ...props }
        className={ classes }
      >
        { tip }
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      placement={ `${verticalPlace}-${place}` }
      delay={{ show: 100, hide: 100 }}
      overlay={ renderTooltip }
    >
      <span className={labelClasses}>{I18n.t('js.investment_calculations.document_trial.beta')}</span>
    </OverlayTrigger>
  );
}

export default LabelBetaTooltip;
