export function getMilestoneIconColor(milestone) {
  const bgByMilestone = {
    M0: 'bg-milestone-0',
    M1: 'bg-milestone-1',
    M2: 'bg-milestone-2',
    M3: 'bg-milestone-3',
    M4: 'bg-milestone-4',
    M5: 'bg-milestone-5',
    M6: 'bg-milestone-6'
  }

  return bgByMilestone[milestone]
}

export function getQualifiedStepIconColor(qualifiedStep, shouldBeOrange = false) {
  const bgByQualifiedStep = {
    Q0: 'bg-qualified-0',
    Q1: 'bg-qualified-1',
    Q2: 'bg-qualified-2',
    Q3: 'bg-qualified-3',
    Q4: 'bg-qualified-4',
    Q5: 'bg-qualified-x'
  }

  let selectedClass = bgByQualifiedStep[qualifiedStep]

  if (shouldBeOrange) {
    selectedClass += '-orange'
  } else {
    selectedClass += '-dark-gray'
  }

  return selectedClass
}
