import React, { Component } from 'react'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import styles from './PlyrVideoPlayer.module.scss'

export default class PlyrVideoPlayer extends Component {
  static defaultProps = {
    width: 640,
    overlayHideEnabled: false,
    renderVideoEndOverlay: null
  }

  player = null
  playerRef = React.createRef()

  state = {
    showOverlay: false
  }

  componentDidMount() {
    this.player = new Plyr(this.playerRef.current)
    this.player.on('ended', this.handleVideoEnded)
  }

  handleVideoEnded = () => {
    const { renderVideoEndOverlay } = this.props

    if (renderVideoEndOverlay)  {
      this.setState({ showOverlay: true })
    }
  }

  hideOverlay = () => {
    if (this.props.overlayHideEnabled) {
      this.setState({ showOverlay: false })
    }
  }

  replayVideo = () => {
    this.player.play()
    this.setState({ showOverlay: false })
  }

  renderOverlay() {
    const { renderVideoEndOverlay } = this.props

    return (
      <div className={styles.overlayWrapper} >
        <div className={styles.overlay} onClick={this.hideOverlay}></div>
        <div className={styles.buttonOverlay}>
          {renderVideoEndOverlay(this.replayVideo)}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={styles.main}
        width={this.props.width}
      >
        <div
          data-plyr-provider={this.props.videoProvider}
          data-plyr-embed-id={this.props.videoId}
          ref={this.playerRef}
        />
        {this.state.showOverlay && this.renderOverlay()}
      </div>
    )
  }
}
