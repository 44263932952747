/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */

import roundingFunction from '../subcontainers/roundingFunction';

export default function calculateFinances(financeContainerState) {
  const { annual_repayment, interest_rate, mortgage_value, repayment_rate } = financeContainerState;
  const mortgageValue = !mortgage_value ? 0 : roundingFunction(mortgage_value);
  const interestRate = !interest_rate ? 0 : roundingFunction(interest_rate);
  const repaymentRate = !repayment_rate ? 0 : roundingFunction(repayment_rate);
  const annualRepayment = !annual_repayment ? 0 : roundingFunction(annual_repayment);
  const list = [];

  let actual_loan = mortgageValue;
  let remaining_loans = mortgageValue;
  let annuity = 0;
  let interest_costs = 0;
  let repayments = 0;
  let total_repayments = 0;

  const obj = {
    Year: 0,
    annuity,
    interest_costs,
    repayments,
    annual_repayment: 0,
    remaining_loans,
    total_repayments
  };

  list.push(obj);

  for (let i = 1; i < financeContainerState.number_of_years; i++) {
    interest_costs = roundingFunction(interestRate * actual_loan / 100);
    annuity = roundingFunction(mortgageValue * (interestRate + repaymentRate) / 100);
    repayments = roundingFunction(annuity - interest_costs);
    total_repayments = roundingFunction(total_repayments + repayments + annualRepayment);

    remaining_loans = roundingFunction(actual_loan - repayments - annualRepayment);
    actual_loan = remaining_loans;

    const obj = {
      Year:              i,
      annuity,
      interest_costs,
      repayments:        (repayments).toFixed(2),
      annual_repayment:  annualRepayment,
      remaining_loans,
      total_repayments,
    };

    list.push(obj);

    if (actual_loan < (repayments + annualRepayment)) {
      interest_costs = roundingFunction(interestRate * actual_loan / 100);
      annuity = roundingFunction(actual_loan + interest_costs);
      repayments = roundingFunction(annuity - interest_costs);
      total_repayments = roundingFunction(total_repayments + repayments);

      const obj = {
        Year:             i + 1,
        annuity,
        interest_costs,
        repayments:       (repayments).toFixed(2),
        annual_repayment: 0,
        remaining_loans:  0,
        total_repayments,
      };

      list.push(obj);

      break;
    }
  }

  return list;
}
