import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'i18n-js/setupTranslations.js.erb';
import styles from './ArchivedListingWarningModalContainer.module.scss';
import WarningIcon from '../../../assets/images/icons/warning-yellow.svg';

/*eslint max-lines-per-function: ["error", 200]*/
const ArchivedListingWarningModalContainer = (props) => {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={ show }
      onHide={ () => setShow(false) }
      backdrop="static"
      keyboard={ false }
      className={ styles.ArchivedListingWarningModalContainer }
    >
      <Modal.Header className={ styles.header }>
        <Modal.Title>{ I18n.t('js.archived_listing_warning_modal.title') }</Modal.Title>
      </Modal.Header>

      <Modal.Body className={ styles.mainContent }>
        <div className={ styles.iconContainer }>
          <img src={ WarningIcon } />
        </div>
        <div className={ styles.textContainer }>
          { I18n.t('js.archived_listing_warning_modal.archived_and_read_only') }
          <br />
          { I18n.t('js.archived_listing_warning_modal.changes_are_visible_to') }
        </div>
      </Modal.Body>

      <Modal.Footer className={ styles.footer }>
        <button
          onClick={ () => {
            location.href = props.cancelUrl; 
          } }
          className={ `${styles.buttonGray} ${styles.button}` }
        >
          { I18n.t('js.archived_listing_warning_modal.cancel') }
        </button>
        <button
          className={ `${styles.buttonOrange} ${styles.button}` }
          onClick={ () => setShow(false) }
        >
          { I18n.t('js.archived_listing_warning_modal.continue') }
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchivedListingWarningModalContainer;
