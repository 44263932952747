/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import DebounceInput from 'uiKit/inputs/DebounceInput/DebounceInput';
import I18n from 'i18n-js/index.js.erb';
import InfoTooltip from '../../../Tooltips/InfoTooltip/InfoTooltip';

export default class EditGeneralAssumptionsForm extends PureComponent {
  handleChange = (event) => {
    const { name, value } = event.target;

    if (!value) {
      return false;
    } else {
      this.props.handleChange(name, value);
    }
  }

  render() {
    const {
      currencyFormatPercentage,
      formatNumber,
      formatNumberWithoutCurrency,
      renderMadeAssumptionTip,
      rentIncrease
    } = this.props;

    const rentIncreaseAfterYear = parseFloat(this.props.rentIncreaseAfterYear);
    const defaultRentIncrease = parseFloat(this.props.defaultRentIncrease);
    const defaultRentIncreaseAfterYear = parseFloat(this.props.defaultRentIncreaseAfterYear);
    const buildingAppreciation = parseFloat(this.props.buildingAppreciation);
    const buildingAppreciationAfterYear = parseFloat(this.props.buildingAppreciationAfterYear);
    const defaultBuildingAppreciation = parseFloat(this.props.defaultBuildingAppreciation);
    const defaultBuildingAppreciationAfterYear = parseFloat(this.props.defaultBuildingAppreciationAfterYear);
    const annualRepayment = parseFloat(this.props.annualRepayment);
    const defaultAnnualRepayment = parseFloat(this.props.defaultAnnualRepayment);
    const shareOfBuilding = parseFloat(this.props.shareOfBuilding);
    const defaultShareOfBuilding = parseFloat(this.props.defaultShareOfBuilding);
    const annualGrossIncome = parseFloat(this.props.annualGrossIncome);
    const defaultAnnualGrossIncome = parseFloat(this.props.defaultAnnualGrossIncome);

    return (
      <tbody className="generalAssumptionsTable">
        <tr>
          <td></td>
          <td>
            <p className='global-heading'>{I18n.t('js.investment_calculations.facts_figures.first_year')}</p>
          </td>
          <td>
            <p className='global-heading'>{I18n.t('js.investment_calculations.facts_figures.after_first_year')}</p>
          </td>
        </tr>
        <tr>
          <td> {I18n.t('js.investment_calculations.facts_figures.cold_rent_increase')} </td>
          <td>
            <input className="field js-closing-costs-field"
              type="text" name="rent_increase"
              value={formatNumberWithoutCurrency(rentIncrease)}
              onChange={this.handleChange}
              readOnly
            />
            <span className="field symbol">%</span>
          </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="rent_increase_after_year"
              placeholder={0}
              value={formatNumberWithoutCurrency(rentIncreaseAfterYear)}
              onChange={this.handleChange}
            />
            <span className="field symbol">%</span>
          </td>
          {
            (rentIncrease != defaultRentIncrease ||
             rentIncreaseAfterYear != defaultRentIncreaseAfterYear) &&
             <td className='tooltipMadeAssumption'>
               <InfoTooltip iconType='danger'>
                 {
                   renderMadeAssumptionTip([
                     {
                       name: I18n.t('js.investment_calculations.facts_figures.cold_rent_increase_first_year'),
                       value: currencyFormatPercentage(defaultRentIncrease)
                     },
                     {
                       name: I18n.t('js.investment_calculations.facts_figures.cold_rent_increase_after_year'),
                       value: currencyFormatPercentage(defaultRentIncreaseAfterYear)
                     }
                   ])
                 }
               </InfoTooltip>
             </td>
          }
        </tr>
        <tr>
          <td> {I18n.t('js.investment_calculations.facts_figures.value_increase')} </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="building_appreciation"
              placeholder={0}
              value={formatNumberWithoutCurrency(buildingAppreciation)}
              onChange={this.handleChange}
            />
            <span className="field symbol">%</span>
          </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="building_appreciation_after_year"
              value={formatNumberWithoutCurrency(buildingAppreciationAfterYear)}
              onChange={this.handleChange}
              placeholder={0}
            />
            <span className="field symbol">%</span>
          </td>
          {
            (buildingAppreciation != defaultBuildingAppreciation ||
             buildingAppreciationAfterYear != defaultBuildingAppreciationAfterYear) &&
             <td className='tooltipMadeAssumption'>
               <InfoTooltip iconType='danger'>
                 {
                   renderMadeAssumptionTip([
                     {
                       name: I18n.t('js.investment_calculations.facts_figures.value_increase_first_year'),
                       value: currencyFormatPercentage(defaultBuildingAppreciation)
                     },
                     {
                       name: I18n.t('js.investment_calculations.facts_figures.value_increase_after_year'),
                       value: currencyFormatPercentage(defaultBuildingAppreciationAfterYear)
                     }
                   ])
                 }
               </InfoTooltip>
             </td>
          }
        </tr>
        <tr>
          <td> {I18n.t('js.investment_calculations.detailed_financing_plan.additional_annual_repayment')} </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="annual_repayment"
              placeholder={0}
              value={formatNumberWithoutCurrency(annualRepayment)}
              onChange={this.handleChange}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (annualRepayment != defaultAnnualRepayment) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.detailed_financing_plan.additional_annual_repayment'),
                      value: formatNumber(defaultAnnualRepayment)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr>
          <td> {I18n.t('js.investment_calculations.detailed_financing_plan.share_of_building')} </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="share_of_building"
              value={formatNumberWithoutCurrency(shareOfBuilding)}
              placeholder={0}
              onChange={this.handleChange}
            />
            <span className="field symbol">%</span>
          </td>
          {
            (shareOfBuilding != defaultShareOfBuilding) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.detailed_financing_plan.share_of_building'),
                      value: currencyFormatPercentage(defaultShareOfBuilding)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr>
          <td> {I18n.t('js.investment_calculations.detailed_financing_plan.annual_gross_income')} </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="annual_gross_income"
              value={formatNumberWithoutCurrency(annualGrossIncome)}
              placeholder={0}
              onChange={this.handleChange}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (annualGrossIncome != defaultAnnualGrossIncome) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.detailed_financing_plan.annual_gross_income'),
                      value: formatNumber(defaultAnnualGrossIncome)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
      </tbody>
    )
  }
}
