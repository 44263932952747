/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react';
import PriceRadio from '../../InvestmentCalculationContainer/PriceRadio';
import ColdRentRadio from '../../InvestmentCalculationContainer/ColdRentRadio';
import DebounceSliderInput from 'uiKit/inputs/DebounceSliderInput/DebounceSliderInput';
import I18n from 'i18n-js/index.js.erb';
import {
  calculateMortgageValue
} from '../../InvestmentCalculationContainer/subcontainers/calculations';

export default class ManageAssumptions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing_cost:                this.props.new_real_cost_of_purchasing,
      cold_rent:                   this.props.const_cold_rent,
      cold_rent_15:                this.props.cold_rent_15,
      comparable_cold_rent:        this.props.comparable_cold_rent,
      custom:                      this.props.cold_rent,
      direct_purchase_price:       this.props.direct_purchase_price,
      error_message:               this.props.error_message_manage_assumption,
      final_cold_rent:             this.props.cold_rent,
      finance_amount_percent:      this.props.equity,
      interest_rate:               this.props.const_interest_rate,
      market_price:                this.props.market_price,
      name_cold:                   'cold_rent',
      new_real_cost_of_purchasing: this.props.new_real_cost_of_purchasing,
      open_expandable_panel:       true,
      price_selected_option:       this.props.price_selected_option,
      rent_selected_option:        'Current',
      repayment_rate:              this.props.const_repayment_rate,
      step_purchase_price:         '',
      user_purchase_price:         this.props.user_purchase_price,
    };

    this.baseState = this.state;
  }

  resetAll = () => {
    this.props.resetForm();

    setTimeout(() => {
      this.setState({
        new_real_cost_of_purchasing: this.props.const_real_cost_of_purchasing,
        error_message: false
      });
    }, 10);
  }

  purchasePriceFunction = (event) => {
    this.props.updateRange('user_purchase_price', event);
    this.props.updatepriceSelectedOption();
    this.props.error_message_manage_assumption_function();
  }

  newRealCostOfPurchasingFunction = (event) => {
    this.setState({
      error_message: true,
    });

    this.props.updateRange('new_real_cost_of_purchasing', event);
    this.props.error_message_manage_assumption_function();

    this.setState({
      closing_cost: this.props.new_real_cost_of_purchasing,
      new_real_cost_of_purchasing: this.props.new_real_cost_of_purchasing
    });
  }

  coldRentFunction = (event) => {
    this.props.updateRange('cold_rent', event);
    this.props.updateColdRentSelectedOption();
    this.props.error_message_manage_assumption_function();
  }

  mortgageFunction = (event) => {
    this.setState({
      error_message: true
    }, () => {
      this.props.updateRange('mortgage', event);
      this.props.error_message_manage_assumption_function();
    });
  }

  interestRateFunction = (event) => {
    this.props.updateRange('interest_rate', event);
    this.props.error_message_manage_assumption_function();
    this.setState({
      error_message: true,
      interest_rate: this.props.interest_rate
    });
  }

  repaymentRateFunction = (event) => {
    this.props.updateRange('repayment_rate', event);
    this.props.error_message_manage_assumption_function();
    this.setState({
      error_message: true,
      repayment_rate: this.props.repayment_rate
    });
  }

  togglePanel = () => {
    this.setState({
      open_expandable_panel: !this.state.open_expandable_panel
    })
  }

  selectedColdRent = () => {
    const { cold_rent, cold_rent_15, default_cold_rent, rent_selected_option } = this.props;
    const { comparable_cold_rent, market_price } = this.state;

    switch (rent_selected_option) {
      case 'Market':
        return market_price;
      case 'comparable_cold_rent':
        return comparable_cold_rent;
      case '15':
        return cold_rent_15;
      case 'Current':
        return default_cold_rent;
      default:
        return cold_rent;
    }
  }

  selectedUserPurchasePrice = () => {
    const { const_purchase_price, price_selected_option, user_purchase_price } = this.props;

    switch (price_selected_option) {
      case 'direct_purchase_price':
        return this.state.direct_purchase_price;
      case 'user_purchase_price':
        return const_purchase_price;
      default:
        return user_purchase_price;
    }
  }

  componentDidMount() {
    setTimeout(() => {
      let step_purchase_price;

      if (this.props.const_purchase_price < 150000) {
        step_purchase_price = 1000;
      } else if (this.props.const_purchase_price < 500000) {
        step_purchase_price = 5000;
      } else {
        step_purchase_price = 10000;
      }

      this.setState({
        step_purchase_price,
        closing_cost: this.props.real_cost_of_purchasing,
        new_real_cost_of_purchasing: this.props.new_real_cost_of_purchasing
      });
    }, 10);
  }

  render() {
    const {
      cold_rent,
      cold_rent_15,
      comparable_cold_rent,
      const_purchase_price,
      const_real_cost_of_purchasing,
      currencyFormatPercentage,
      custom_cold_rent,
      custom_user_purchase_price,
      default_cold_rent,
      default_interest_rate,
      default_mortgage,
      default_repayment_rate,
      direct_purchase_price,
      error_message_manage_assumption,
      handleOptionChangePrice,
      handleOptionChangeRent,
      interest_rate,
      living_space,
      market_price,
      mortgage,
      numberToIntegerCurrency,
      numberToLocalizedInteger,
      price_selected_option,
      show_edit_financing_plan,
      real_cost_of_purchasing,
      rentedOut,
      rent_selected_option,
      repayment_rate,
      user_purchase_price
    } = this.props;

    const { open_expandable_panel, step_purchase_price } = this.state;

    return (
      <div className='Range'>
        <div className="calculations-table--row secondary extra">
          <div onClick={this.togglePanel} className="calculations-table--toggle text-orange js-table-body-trigger">
            {open_expandable_panel ? (<i className="icon-next-orange -medium" style={{ transform: 'rotate(90deg)' }}></i>) : (<i className="icon-next-orange -medium"></i>)}
            <span>{I18n.t('js.investment_calculations.manage_assumptions.manage_assumption')}</span>
          </div>
          <div className='error_message'>
            {error_message_manage_assumption && <div className="calculations-table--alert js-custom-assumptions-alert">
              <span className="-secondary info-tip -danger display-right">
                <span className="info-tip--content">
                  {I18n.t('js.investment_calculations.manage_assumptions.made_assumption')}
                </span>
              </span>
              <span className="text-danger">{I18n.t('js.investment_calculations.manage_assumptions.custom_assumption')}</span>
            </div>
            }
          </div>
        </div>
        {open_expandable_panel ? (
          <div className='calculations-table'>
            <div className="calculations-table--row">
              <div className="calculations-table--row-inner">
                <ColdRentRadio handleOptionChangeRent={handleOptionChangeRent}
                  rent_selected_option={rent_selected_option}
                  cold_rent={cold_rent}
                  default_cold_rent={default_cold_rent}
                  cold_rent_15={cold_rent_15}
                  market_price={market_price}
                  comparable_cold_rent={comparable_cold_rent}
                  custom_cold_rent={custom_cold_rent}
                  rentedOut={rentedOut}
                  showRadioLabel
                />
                <div className="calculations-table--col small">
                  <div
                    className="calculations-table--reset js-reset-all"
                    name="user_purchase_price"
                  >
                    <i className="icon-reset-orange -mediumplus"></i>
                    <span
                      className="text-orange"
                      onClick={this.resetAll}
                    >
                      {I18n.t('js.investment_calculations.manage_assumptions.reset')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <PriceRadio
              handleOptionChangePrice={handleOptionChangePrice}
              price_selected_option={price_selected_option}
              direct_purchase_price={direct_purchase_price}
              user_purchase_price={const_purchase_price}
              custom_user_purchase_price={custom_user_purchase_price}
              showRadioLabel
            />
            <div className="calculations-table--row additional">
              <div className="calculations-table--col">
                <div className="calculations-table--slider">
                  <div className="slider -additional">
                    <div>
                      <span className="left"> {I18n.t('js.investment_calculations.facts_figures.purchase_price')} </span>
                      <DebounceSliderInput
                        name="user_purchase_price"
                        min={parseInt(const_purchase_price * (1 - 0.3))}
                        max={parseInt(const_purchase_price * (1 + 0.3))}
                        step={parseInt(step_purchase_price)}
                        value={this.selectedUserPurchasePrice()}
                        markerValue={const_purchase_price}
                        displayCaptionFunc={(value) => `${numberToLocalizedInteger(value / living_space)} €/m²`}
                        displayTopCaptionFunc={(value) => numberToIntegerCurrency(value)}
                        onChange={this.purchasePriceFunction}
                        onClick={() => this.setState({ new_real_cost_of_purchasing: const_real_cost_of_purchasing })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="calculations-table--col Slider-disabled-closing-cost">
                <div className="calculations-table--slider">
                  <div className="slider -additional">
                    <div>
                      <span className="left"> {I18n.t('js.investment_calculations.facts_figures.closing_cost_misc')} </span>
                      <DebounceSliderInput
                        name="new_real_cost_of_purchasing"
                        min={parseFloat(const_real_cost_of_purchasing * (1 - 0.3))}
                        max={parseFloat(const_real_cost_of_purchasing * (1 + 0.3))}
                        step={100}
                        value={parseFloat(real_cost_of_purchasing)}
                        markerValue={const_real_cost_of_purchasing}
                        displayTopCaptionFunc={(value) => numberToIntegerCurrency(value)}
                        displayCaptionFunc={(value) => `${numberToLocalizedInteger(value / living_space)} €/m²`}
                        onChange={this.newRealCostOfPurchasingFunction}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="calculations-table--row additional">
              <div className="calculations-table--col">
                <div className="calculations-table--slider">
                  <div className="slider -additional">
                    <div className="slider-input">
                      <span className="left"> {I18n.t('js.investment_calculations.facts_figures.monthly_rent')} </span>
                      <DebounceSliderInput
                        name="cold_rent"
                        min={parseInt(default_cold_rent / 5)}
                        max={parseInt(default_cold_rent * 2.5)}
                        step={parseFloat(default_cold_rent / 5)}
                        value={this.selectedColdRent()}
                        markerValue={default_cold_rent}
                        displayTopCaptionFunc={(value) => numberToIntegerCurrency(value)}
                        displayCaptionFunc={(value) => `${(value / living_space).toFixed(2)} €/m²`}
                        onChange={this.coldRentFunction}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="calculations-table--col">
                <div className="calculations-table--slider">
                  <div className="slider -additional">
                    <div className="slider-input">
                      <span className="left"> {I18n.t('js.investment_calculations.detailed_financing_plan.mortgage')} </span>
                      <DebounceSliderInput
                        name="mortgage"
                        min={0}
                        max={125}
                        step={1}
                        value={parseInt(mortgage)}
                        markerValue={parseFloat(default_mortgage)}
                        displayTopCaptionFunc={(value) => (
                          numberToIntegerCurrency(
                            calculateMortgageValue(value, user_purchase_price)
                          )
                        )}
                        displayCaptionFunc={(value) => currencyFormatPercentage(parseFloat(value))}
                        onChange={this.mortgageFunction}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="calculations-table--row additional">
              <div className="calculations-table--col">
                <div className="calculations-table--slider">
                  <div className="slider -additional">
                    <div className="slider-input">
                      <span className="left"> {I18n.t('js.investment_calculations.detailed_financing_plan.loan_interest_rate')} </span>
                      <DebounceSliderInput
                        name="interest_rate"
                        min={0}
                        max={15}
                        step={0.1}
                        value={parseFloat(interest_rate)}
                        markerValue={parseFloat(default_interest_rate)}
                        displayTopCaptionFunc={(value) => (
                          currencyFormatPercentage(parseFloat(value))
                        )}
                        onChange={this.interestRateFunction}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="calculations-table--col">
                <div className="calculations-table--slider">
                  <div className="slider -additional">
                    <div className="slider-input">
                      <span className="left"> {I18n.t('js.investment_calculations.complete_investment_table.repayment_rate')} </span>
                      <DebounceSliderInput
                        name="repayment_rate"
                        min={0}
                        max={15}
                        step={0.1}
                        value={parseFloat(repayment_rate)}
                        markerValue={parseFloat(default_repayment_rate)}
                        displayTopCaptionFunc={(value) => (
                          currencyFormatPercentage(parseFloat(value))
                        )}
                        onChange={this.repaymentRateFunction}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="amortization_table">
              <span onClick={show_edit_financing_plan}
                className='button -secondary'
              >
                {I18n.t('js.investment_calculations.manage_assumptions.edit_investment_calculation')}
              </span>
            </span>
          </div>) : null}
      </div>
    )
  }
}
