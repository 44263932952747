import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import Field from 'commonComponents/forms/Field/Field';
import { commonPatterns } from 'lib/constants';

/*eslint max-lines-per-function: ["error", 200]*/
const CommonFields = ({
  fields,
  orderOfFields,
  defaltValues,
  readOnlyFields
}) => {
  const fieldsWithPatterns = cloneDeep(fields);
  const fieldsNames = orderOfFields ||
    Object.keys(fieldsWithPatterns).filter(name => !fields[name].textarea);

  Object.keys(commonPatterns).forEach(key => {
    Object.keys(fieldsWithPatterns).forEach(name => {
      const reg = new RegExp(key);
      if(reg.test(name)) {
        fieldsWithPatterns[name].pattern = commonPatterns[key];
      }
    });
  });

  const renderField = (name, fieldWithPattern, defaultValue, readonly) => {
    return (
      <Field
        key={ name }
        { ...fieldWithPattern }
        name={ name }
        defaultValue={ defaultValue }
        readonly={ readonly }
      />);
  };

  return (
    <>
      { fieldsNames.map(name => renderField(name,
        fieldsWithPatterns[name],
        defaltValues && defaltValues[name] ? defaltValues[name] : '',
        readOnlyFields.includes(name) ? 'readonly' : false
      ))
      }
    </>
  );
};

export default CommonFields;
