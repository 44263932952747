import React from 'react';
import styles from './common.module.scss';

const slideGradient = <div className={ styles.slideGradient }></div>;

const headerSeparator = <span className={ styles.separator }></span>;

const TextBlock = ({ text }) =>
  Array.isArray(text) ? text.map(paragraph => <p key={ paragraph }>{ paragraph }</p> ) : <p>{ text }</p>;

/* eslint-disable-next-line max-len */
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
  slideGradient,
  headerSeparator,
  TextBlock,
  emailRegex
};
