import React from 'react';
import styles from './ValidationErrorMessage.module.scss';

const ValidationErrorMessage = ({ error }) => {
  return (
    <div>
      {
        error &&
        <p className={ styles.errorMessage }>{ error.message }</p>
      }
    </div>
  );
};

export default ValidationErrorMessage;
