export default class YoutubeIframeApiLoader {
  static YOUTUBE_IFRAME_API_URL = "https://www.youtube.com/iframe_api"
  static LOAD_STATES = {
    NOT_LOADED: 'not_loaded',
    LOADING: 'loading',
    LOADED: 'loaded'
  }

  static state = this.LOAD_STATES.NOT_LOADED
  static apiReadyReadyCallbacks = []

  static load() {
    if (
      this.state === this.LOAD_STATES.LOADED ||
      this.state === this.LOAD_STATES.LOADING
    ) {
      // Stop loading if already loaded/loading
      return this
    }


    this.state = this.LOAD_STATES.LOADING

    this.loadYoutubeIframeApiScript()

    window.onYouTubeIframeAPIReady = () => {
      this.handleYoutubeApiReady()
    }

    return this
  }

  static handleYoutubeApiReady() {
    this.state = this.LOAD_STATES.LOADED

    while(this.apiReadyReadyCallbacks.length > 0) {
      const callback = this.apiReadyReadyCallbacks.shift()

      callback()
    }
  }

  static loadYoutubeIframeApiScript() {
    const youtubeScript = document.createElement('script');
    youtubeScript.src = this.YOUTUBE_IFRAME_API_URL

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(youtubeScript, firstScriptTag);
  }

  static onLoad(callback) {
    if (this.state === this.LOAD_STATES.LOADED) {
      // If YoutubeIframeApi is loaded - execute callback immediately
      callback()

      return this
    } else {
      // If YoutubeIframeApi is still not loaded - push callback to
      // this.apiReadyReadyCallbacks callback list
      this.apiReadyReadyCallbacks.push(callback)

      return this
    }
  }
}
