import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';

export default class ColdRentRadio extends PureComponent {
  comparableColdRentEmpty() {
    const comparableColdRent = this.props.comparable_cold_rent
    return isNaN(comparableColdRent) || (comparableColdRent == 0)
  }

  marketPriceEmpty() {
    return isNaN(this.props.market_price) || this.props.market_price == 0
  }

  render() {
    const rentSelectedOption = this.props.rent_selected_option

    return (
      <div className="calculations-table--row -secondary">
        { this.props.showRadioLabel &&
          <div className="calculations-table--col main-label small">
            <span className="text-bold text-with-colon">
              {I18n.t('js.investment_calculations.facts_figures.rent')}
            </span>
          </div>
        }
        <div className="calculations-table--row radios js-rent-radios">
          {this.props.rentedOut &&
            <div className="calculations-table--col small">
              <label className="form--field -radio">
                <input onChange={this.props.handleOptionChangeRent} id="Current"
                  checked={rentSelectedOption === "Current"}
                  value={this.props.default_cold_rent}
                  className="field"
                  type="radio"
                  name='radio_cold_rent_current'
                />
                <span className="radio secondary"></span>
                <span>{I18n.t('js.investment_calculations.facts_figures.current')}</span>
              </label>
            </div>
          }

          {this.props.rentedOut &&
            <div className="calculations-table--col small">
              <label className="form--field -radio">
                <input onChange={this.props.handleOptionChangeRent} id="15"
                  checked={rentSelectedOption === "15"} value={this.props.cold_rent_15}
                  className="field"
                  type="radio"
                  name='radio_cold_rent_15'
                />
                <span className="radio secondary"></span>
                <span>+15%</span>
              </label>
            </div>
          }
          <div className={this.comparableColdRentEmpty() ? 'hidden' : 'calculations-table--col small'} >
            <label className="form--field -radio">
              <input onChange={this.props.handleOptionChangeRent} id="comparable_cold_rent"
                checked={rentSelectedOption === "comparable_cold_rent"}
                value={this.props.comparable_cold_rent}
                className="field"
                type="radio"
                name='radio_cold_rent_comparable'
              />
              <span className="radio secondary"></span>
              <span>{I18n.t('js.investment_calculations.facts_figures.comparable')}</span>
            </label>
          </div>
          <div className={this.marketPriceEmpty() ? 'hidden' : 'calculations-table--col small'}>
            <label className="form--field -radio">
              <input onChange={this.props.handleOptionChangeRent} id="Market"
                checked={rentSelectedOption === "Market"} value={this.props.market_price}
                className="field"
                type="radio"
                name='radio_cold_rent_market'
              />
              <span className="radio secondary"></span>
              <span>{I18n.t('js.investment_calculations.facts_figures.market')}</span>
            </label>
          </div>
          <div className="calculations-table--col small custom">
            <label className="form--field -radio">
              <input onChange={this.props.handleOptionChangeRent} id="radio_cold_rent_custom"
                checked={rentSelectedOption === "radio_cold_rent_custom"}
                value={this.props.custom_cold_rent}
                className="field"
                type="radio"
                name='radio_cold_rent_custom'
              />
              <span className="radio secondary"></span>
              <span>{I18n.t('js.investment_calculations.facts_figures.custom')}</span>
            </label>
          </div>
        </div>

      </div>
    )
  }
}
