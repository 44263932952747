import {
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import React from 'react';

// Register font
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const styles = StyleSheet.create({
  footerIndex: {
    position: 'relative',
    bottom: -55,
    right: 0,
    left: 0
  },
  footerRow1: {
    marginBottom: -2,
    flexDirection: "row",
    backgroundColor: '#2a3441'
  },
  footerRow1C1: {
    width: '33.33%',
    fontSize: 8,
    color: '#fff',
    padding: 15
  },
  footerRow1C1Bold: {
    fontFamily: 'Open Sans',
    fontWeight: 600
  }
});

const FooterPdf = () => {
  return (
    <View style={styles.footerIndex}>
      <View style={styles.footerRow1}>
        <View style={styles.footerRow1C1}>
          <Text style={styles.footerRow1C1Bold}>WunderAgent</Text>
          <Text>Skalitzer Str 34</Text>
          <Text>D-10999 Berlin</Text>
        </View>
        <View style={styles.footerRow1C1}>
          <Text>Büroanschrift Berlin:</Text>
          <Text>Skalitzer Straße 34</Text>
          <Text>D-10999 Berlin</Text>
        </View>
        <View style={styles.footerRow1C1}>
          <Text>Büroanschri Leipzig:</Text>
          <Text>Humboldstr. 9</Text>
          <Text>D-04105 Leipzig</Text>
        </View>
      </View>
      <View style={styles.footerRow1}>
        <View style={styles.footerRow1C1}>
          <Text>Telefon: 0800 / 673 82 23</Text>
          <Text>Fax: 030 / 555 784 88</Text>
          <Text>E-Mail: support@wunderagent.com</Text>
        </View>
        <View style={styles.footerRow1C1}>
          <Text>Öffnungs- / Servicezeiten</Text>
          <Text>Mo. - Fr. 9:00 - 18:00 Uhr</Text>
          <Text>Sowie nach Vereinbarung</Text>
        </View>
      </View>
    </View>
  )
}
export default FooterPdf;
