const importAllImagesFromFolder = (context) => {
  return context.keys().map((item) => context(item));
};

const isHttp = path => /^https?:\/\/.*/.test(path);

const getImages = (context, reg) => {
  const images = {};

  for(const key of context.keys()) {
    const serviceName = key.match(reg)[1];
    images[serviceName] = context(key);
  }

  return images;
};

const getNewsImage = path => {
  if(isHttp(path)) {
    return path;
  } else {
    const context = require.context("oldAssets/images/news", false, /.*\.(webp|jpges|jpg|gif|svg)/);   
    return context(`./${path}`);
  }
};

const importServicesIconsWithText = () => {
  const context = require.context("oldAssets/images/icons/services", false, /.+smart-[a-z]+\.svg$/);

  return getImages(context, /smart-([a-z]*).svg/);
};

const importServicesIcons = () => {
  const context = require.context("oldAssets/images/icons/services", false, /.+smart-[a-z]+-icon\.svg$/);

  return getImages(context, /smart-([a-z]*)-icon.svg/);
};

const importServicesPhotos = () => {
  const context = require.context(
    "oldAssets/images/static_pages/services_overview", false, /.+smart-[a-z]+-[a-z]+\.webp$/);

  return getImages(context, /smart-([a-z]*)-[a-z]+.webp/);
};

const importConsultantsPhotos = () => {
  const context = require.context('oldAssets/images/team_photos', false, /.*-photo\.webp$/);

  return getImages(context, /([A-Za-z-]*)-photo\.webp$/);
}

export {
  importAllImagesFromFolder,
  importServicesIconsWithText,
  importServicesIcons,
  importServicesPhotos,
  importConsultantsPhotos,
  getNewsImage
};
