import React, { Component } from 'react'
import YoutubeIframeApiLoader from 'lib/YoutubeIframeApiLoader.js'
import { generateSequentialID } from 'lib/idGenerators'
import styles from './YoutubePlayer.module.css'


export default class YoutubePlayer extends Component {
  static defaultProps = {
    width: 640,
    height: 390,
    videoId: null,
    overlayHideEnabled: false,
    renderVideoEndOverlay: null
  }

  player = null
  playerId = generateSequentialID('youtube-player')

  state = {
    showOverlay: false
  }

  componentDidMount() {
    YoutubeIframeApiLoader
      .load()
      .onLoad(this.onYoutubeApiReady)
  }

  onYoutubeApiReady = () => {
    const { width, height } = this.props

    this.player = new YT.Player(this.playerId, {
      height,
      width,
      events: {
        onStateChange: this.onPlayerStateChange
      }
    })
  }

  onPlayerStateChange = (event) => {
    if (event.data == YT.PlayerState.ENDED) {
      const { renderVideoEndOverlay } = this.props

      if (renderVideoEndOverlay)  {
        this.setState({ showOverlay: true })
      }
    }
  }

  hideOverlay = () => {
    if (this.props.overlayHideEnabled) {
      this.setState({ showOverlay: false })
    }
  }

  replayVideo = () => {
    this.player.playVideo()
    this.setState({ showOverlay: false })
  }

  renderOverlay() {
    const { renderVideoEndOverlay } = this.props

    return (
      <div className={styles.overlayWrapper} >
        <div className={styles.overlay} onClick={this.hideOverlay}></div>
        <div className={styles.buttonOverlay}>
          {renderVideoEndOverlay()}
        </div>
      </div>
    )
  }

  render() {
    const { width, height, videoId } = this.props

    return (
      <div
        width={width}
        height={height}
        className={styles.main}
      >
        <iframe
          id={this.playerId}
          type="text/html"
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
          frameBorder="0"
        />
        {this.state.showOverlay && this.renderOverlay()}
      </div>
    )
  }
}
