import React from 'react';
import styles from './Button.module.scss';

const Button = ({ label, link, type = 'wide', onClick }) => ( 
  <a
    className={ `${ styles.ButtonComponent } ${ styles[type] }` }
    href={ link }
    onClick={ onClick }
    data-turbolinks="false"
  >{ label }</a>
);

export default Button;