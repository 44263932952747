import React, { useState, useMemo, useRef, useEffect } from 'react';
import ContactFormService from 'services/ContactFormService';
import HiddenFields from 'commonComponents/forms/HiddenFields/HiddenFields';
import InterestedIn from 'commonComponents/forms/InterestedIn/InterestedIn';
import { useForm } from 'react-hook-form';

const FormContext = React.createContext();

/*eslint max-lines-per-function: ["error", 200]*/
const withContactForm = (FormView) => {
  return ({
    form,
    hiddenFields,
    specificFieldsRender,
    token,
    url = '/webpage_contact',
    contactPage = false,
    fileInputs,
    user,
    ...props
  }) => {
    const service = useMemo(() => new ContactFormService(url), [url]);
    const hiddenData = useMemo(() => ({
      authenticity_token: token,
      utf8: '✓'
    }), [token]);

    const reRef = useRef();

    const fields = { ...form.fields };
    const { agree, interested } = form;
    const { notes, company_name, name, message } = fields;

    if (company_name) {
      company_name.size = '85';
    }

    if (name) {
      name.size = '85';
    }

    let requiredFields;
    if (contactPage) {
      requiredFields = ['name', 'phone', 'message', 'email'];
    } else {
      requiredFields = ['last_name', 'first_name', 'email'];
    }

    requiredFields.forEach(name => fields[name].isRequired = true);

    if(notes) {
      notes.textarea = true;
      notes.cols = notes.cols ? notes.cols : 80;
      notes.rows = notes.rows ? notes.rows : 8;
    }

    if (message) {
      message.textarea = true;
      message.cols = message.cols ? message.cols : 80;
      message.rows = message.rows ? message.rows : 8;
    }

    if (agree) {
      agree.type = 'checkbox';
    }

    const {
      register,
      handleSubmit,
      setError,
      formState: { errors }
    } = useForm();
  
    const [responseOk, setResponseOk] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const specificFields = specificFieldsRender ?
      specificFieldsRender(register, setError, errors) :
      (
        <>
          { hiddenFields && <HiddenFields fields={ hiddenFields } /> }
          { interested && <InterestedIn { ...interested } /> }
        </>
      );

    const contactFormProps = {
      ...form,
      ...props,
      user,
      specificFields
    };

    const onSubmit = async (lead) => {   
      const data = { ...hiddenData, lead };      
      let response;
      
      const recaptchaResponse = await reRef.current.executeAsync();
      data.grecaptcha_response = recaptchaResponse;
  
      setSubmitting(true);
      
      if(fileInputs && fileInputs.length) {      
        const files = [lead['file-0'][0]];
        response = await service.sendFilesAndEmail(files, data);
      } else {
        response = await service.sendJSON(data);
      }
  
      setResponseOk(response ? response.ok : false);
    };

    useEffect(() => {
      if(responseOk) {
        setSubmitting(false);
      }
    }, [responseOk]);

    const contextValues = {
      user,
      fields,
      url,
      contactPage,
      service,
      agree,
      hiddenData,
      reRef,
      submitting,
      register,
      setError,
      errors,
      responseOk,
      setResponseOk,
      onSubmit: handleSubmit(onSubmit)
    };

    return (
      <FormContext.Provider value={ contextValues }>
        <FormView { ...contactFormProps } />
      </FormContext.Provider>
    );
  };
};

export { FormContext };

export default withContactForm;
