import React from 'react'
import PlyrVideoPlayer from 'containers/PlyrVideoPlayer/PlyrVideoPlayer'
import styles from './SmartInvestVideo.module.css'
import SpinnerIcon from '../../../../assets/images/icons/spinner.svg';

export default class SmartInvestVideo extends React.Component {
  renderVideoEndOverlay = (replayVideo) => {
    const { buttonText } = this.props

    return (
      <React.Fragment>
        <a
          className={`button -secondary -big ${styles.button}`}
          href="https://smartinvest.wunderagent.com/investment"
        >
          {buttonText}
        </a>
        <button
          className={styles.buttonReplay}
          onClick={replayVideo}
        ><img src={ SpinnerIcon } /></button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <PlyrVideoPlayer
        videoId='471016184'
        videoProvider='vimeo'
        width={Math.min(540, document.body.clientWidth - 20)}
        renderVideoEndOverlay={this.renderVideoEndOverlay}
      />
    )
  }
}
