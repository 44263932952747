/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react'
import Chart from 'react-apexcharts'
import I18n from 'i18n-js/index.js.erb'
import {
  Text,
  View,
} from '@react-pdf/renderer';


const currencyFormatDE = new Intl.NumberFormat("de-DE",
  {
    currency: "EUR",
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
const currencyFormatEN = new Intl.NumberFormat("en-US",
  {
    currency: "EUR",
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
const generateDayWiseTimeSeries = function (baseval) {
  let i = 0;
  const series = [];
  while (i < baseval.length) {
    const x = i;
    const y = baseval[i];
    series.push([x, y]);

    i++;
  }
  return series;
};


export default class Graph extends PureComponent {

  optionsEN = {
    chart: {
      id: 'chart',
      type: 'line',

      animations: {
        enabled: true
      },
      stacked: true,
      zoom: {
        type: 'x',
        enabled: false
      },
      toolbar: {
        autoSelected: 'zoom',
        show: false
      },
      background: '#fff'
    },
    colors: ['#ffdd67', '#e26800', '#ff9134'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      type: 'fill',
      gradient: {
        opacityFrom: 1,
        opacityTo: 1,
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      inverseOrder: true,
      y: {
        formatter(y) {
          if (typeof y !== "undefined") {
            return `${currencyFormatEN.format(Math.round(y))}`;
          }
          return y;
        }
      },
      x: {
        enabled: false,
        show: false,
        formatter(x) {
          if (typeof x !== "undefined") {
            return `Year ${Math.round(x)}`;
          }
          return Math.round(x);
        }
      },
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetX: 2,
        offsetY: -20,
      }
    },
    // legend: {

    //   position:        'top',
    //   horizontalAlign: 'center'
    // },
    xaxis: {
      tooltip: {
        enabled: false
      },
      title: {
        text: I18n.t('js.investment_calculations.detailed_financing_plan.years'),
        align: 'left',
        offsetX: -19,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          cssClass: 'apexcharts-xaxis-title',
        }
      },
      type: '',
      text: 'Year',
      labels: {
        formatter(x) {
          if (typeof x !== "undefined") {
            return `${Math.round(x)}`;
          }
          return Math.round(x);
        }
      }
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `${currencyFormatEN.format(value / 1000)}`;
        }
      },
      title: {
        text: I18n.t('js.investment_calculations.detailed_financing_plan.y_axis_title'),
        align: 'left',
        style: {
          color: undefined,
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          cssClass: 'apexcharts-xaxis-title',
        }
      },
      opposite: true,

      type: '',
      text: 'Year'
    }
  }

  optionsDE = {
    chart: {
      id: 'chart',
      type: 'line',

      animations: {
        enabled: true
      },
      stacked: true,
      zoom: {
        type: 'x',
        enabled: false
      },
      toolbar: {
        autoSelected: 'zoom',
        show: false
      },
      background: '#fff'
    },
    colors: ['#ffdd67', '#e26800', '#ff9134'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      type: 'fill',
      gradient: {
        opacityFrom: 1,
        opacityTo: 1,
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter(y) {
          if (typeof y !== "undefined") {
            return `${currencyFormatDE.format(Math.round(y))}`;
          }
          return y;
        }
      },
      x: {
        enabled: false,
        show: false,
        formatter(x) {
          if (typeof x !== "undefined") {
            return `Year ${Math.round(x)}`;
          }
          return Math.round(x);
        }
      },
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetX: 2,
        offsetY: -20,
      },
    },
    // legend: {

    //   position:        'top',
    //   horizontalAlign: 'center'
    // },
    xaxis: {
      tooltip: {
        enabled: false
      },
      title: {
        text: I18n.t('js.investment_calculations.detailed_financing_plan.years'),
        align: 'left',
        offsetX: -19,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          cssClass: 'apexcharts-xaxis-title',
        }
      },
      type: '',
      text: 'Year',
      labels: {
        formatter(x) {
          if (typeof x !== "undefined") {
            return `${Math.round(x)}`;
          }
          return Math.round(x);
        }
      }
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `${currencyFormatDE.format(value / 1000)}`;
        }
      },
      title: {
        text: I18n.t('js.investment_calculations.detailed_financing_plan.remaining_loan'),
        align: 'left',
        style: {
          color: undefined,
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          cssClass: 'apexcharts-xaxis-title',
        }
      },
      opposite: true,

      type: '',
      text: 'Year'
    }
  }

  series() {
    return [
      {
        name: I18n.t('js.investment_calculations.detailed_financing_plan.remaining_loan'),
        data: generateDayWiseTimeSeries(this.props.remaining_loans)
      },
      {
        name: I18n.t('js.investment_calculations.complete_investment_table.total_repayment'),
        data: generateDayWiseTimeSeries(this.props.total_repayments)
      },
      {
        name: I18n.t('js.investment_calculations.complete_investment_table.equity_from_appreciation'),
        data: generateDayWiseTimeSeries(this.props.equity_from_appreciation)
      }
    ]
  }

  render() {
    if (this.props.current_user_locale == 'en') {
      return (
        <View id="chart">
          <Text> {I18n.t('js.investment_calculations.complete_investment_table.headline')} </Text>
          <Chart options={this.optionsEN} series={this.series()}
            type="area" height="350"
          />
        </View>
      )
    } else {
      return (
        <View id="chart">
          <Text> {I18n.t('js.investment_calculations.complete_investment_table.headline')} </Text>
          <Chart options={this.optionsDE} series={this.series()}
            type="area" height="350"
          />
        </View>
      )
    }

  }
}
