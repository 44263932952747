import React, { memo } from 'react'
import styles from './UserAvatar.module.css'
import images from 'resources/images'
import cn from 'classnames'


function UserAvatar(props) {
  const { imageUrl } = props

  let resultingImageUrl

  if (imageUrl && imageUrl.length > 0) {
    resultingImageUrl = imageUrl
  } else {
    resultingImageUrl = images.userAvatarPlaceholderUrl
  }

  const className = cn(styles.userAvatarMain, props.className)

  return (
    <img
      className={className}
      src={resultingImageUrl}
    />
  )
}

export default memo(UserAvatar)
