export const copyUrlToClipboard = (anchor) => {
  const link = `${location.href.split('#')[0]}#${anchor}`;

  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(link);
  } else {
    const url = document.createElement('textarea');
    url.value = link
    url.setAttribute('readonly', '');
    url.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(url);
    url.select();
    document.execCommand('copy');
    document.body.removeChild(url);
  }
}
