import React, { useState, useCallback } from 'react';
import styles from './PasswordToggle.module.scss';

/*eslint max-lines-per-function: ["error", 200]*/
const PasswordToggle = ({ changeType, showTitle, hideTitle }) => {

  const [hide, setHide] = useState(true);
  const toggleShowHide = useCallback(() => {
    setHide(!hide);
    changeType(hide ? 'text' : 'password');
  },[hide, setHide, changeType]);

  return (
    <span
      className={`fa fa-eye${ hide ? '' : '-slash' } 
        -toggle-password js-toggle-password ${ styles.password }`}
      title={ hide ? showTitle : hideTitle }
      data-show={ showTitle }
      data-hide={ hideTitle }
      onClick={ toggleShowHide }
    ></span>
  );
};

export default PasswordToggle;
