export default class ContactFormService {
  constructor (url) {
    this.url = url;
  }

  async sendData (body, method, headers) {
    const response = await fetch(this.url, {
      method, body, headers
    });

    if (!response.ok) {
      console.error(`Couldn't fetch. Received ${response.status}`);
    }

    const responseBody = await response.json();

    if(responseBody.errors) {
      responseBody.errors
        .forEach(error => console.error(`Status: ${error.status}. Detail: ${error.detail}`));
    }

    return response;
  }

  async sendJSON (data, method = 'post') {
    const headers = {
      'Content-Type': 'application/json;charset=utf-8'
    }

    return this.sendData(JSON.stringify(data), method, headers);
  }

  async sendFilesAndEmail (files, data) {
    const formData = new FormData();

    files.forEach((file, i) => {
      file && formData.append(`file[${i}]`, file);      
    });

    Object.keys(data).forEach(key => {
      const value = (typeof data[key] === 'string') ?
        data[key] :
        JSON.stringify(data[key]);

      formData.append(key, value);
    });

    return this.sendData(formData, 'post');
  }
}
