import React from 'react';
import BaseSection from 'commonComponents/sections/BaseSection/BaseSection';
import styles from './SectionWithBoxes.module.scss';

const SectionWithBoxes = ({
  headers,
  text,
  boxes,
  button,
  headerType,
  boxRender,
  anchor
}) => {
  const baseProps = { ...headers, button, headerType };

  return (
    <BaseSection { ...baseProps } anchor={ anchor } >
      { text && <p className={ styles.text }>{ text }</p> }
      <div className={ styles.boxes }>
        { boxes && boxes.map((box, i) => boxRender(box, i + 1)) }
      </div>
    </BaseSection>
  );
};

export default SectionWithBoxes;
