import React, { useEffect, useState, useCallback } from 'react';
import FormField from '../../FormField/FormField';
import SocialButtons from '../SocialButtons/SocialButtons';
import { keyEnterPressed } from '../../../lib/utils/hotKeysUtil';
import styles from './Form.module.scss';
import classNames from 'classnames';
import 'i18n-js/setupTranslations.js.erb';

/*eslint max-lines-per-function: ["error", 200]*/
const LoginForm = ({
  email,
  password,
  signinLabel,
  socialButtons,
  token,
  rememberMe,
  rememberMeToolTip,
  problemsLoggingIn,
  clickHere,
}) => {
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [checked, setChecked] = useState(false);
  const [rememberMeValue, setRememberMeValue] = useState('0');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [formToken, setFormToken] = useState(token);

  useEffect(() => {
    if (checked) {
      setRememberMeValue('1');
    } else {
      setRememberMeValue('0');
    }
  }, [checked, emailValue, passwordValue, setRememberMeValue]);

  const data = {
    'utf8': '✓',
    'authenticity_token': formToken,
    'user': {
      'email': emailValue,
      'password': passwordValue,
      'remember_me': rememberMeValue.toString(),
    },
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };

  // POST request using fetch with error handling
  const fetchLogin = () => {
    setDisabled(true);
    setError('');
    if (emailValue.length === 0 && passwordValue.length === 0) {
      setDisabled(false);
      setError(I18n.t('js.errors.messages.invalidEmailOrPassword'));
      return false;
    }
    fetch('', requestOptions)
    .then(async response => {
      if (response.redirected) {
        return window.location.href = response.url;
      }
      const data = await response.json();
      if (data.status === 'not_found') {
        setDisabled(false);
        setError(I18n.t('js.errors.messages.invalidEmailOrPassword'));
      } else if (data.user_status === 'blocked') {
        setFormToken(data.authenticity_token);
        setError(I18n.t('js.errors.messages.loginBlocked'));
      } else if (data.user_status === 'confirmation_sent') {
        setError(I18n.t('js.errors.messages.confirmationSent'));
      }
    })
    .catch(error => {
      setDisabled(false);
      if (error === 400) {
        setError(I18n.t('js.errors.messages.badRequest'));
      } else if (error === 404) {
        setError(I18n.t('js.errors.messages.notFound'));
      } else if (error === 500) {
        setError(I18n.t('js.errors.messages.internalServerError'));
      } else {
        setError(I18n.t('js.errors.messages.unknownError'));
      }
    }).finally(() => error && setDisabled(false));
  };

  const onChangeEmail = useCallback((e) => {
    setEmailValue(e.target.value);
    setError('');
  }, [setEmailValue]);

  const onChangePassword = useCallback((e) => {
    setPasswordValue(e.target.value);
    setError('');
  }, [setPasswordValue]);

  const onChangeRememberMe = useCallback(() => setChecked(!checked), [checked]);

  return (
    <div
      className={ classNames('form -new ', styles.login_user) }
      onKeyDown={ (event) => keyEnterPressed(event) && fetchLogin() }
    >
      <FormField
        { ...email }
        type="email"
        autofocus="true"
        id="login_email"
        classes={ classNames('field', error && ' -error') }
        onChange={ e => onChangeEmail(e) }
        autoFocus
      />
      <FormField
        { ...password }
        type="password"
        id="user_password"
        classes={ classNames('field', error && ' -error') }
        onChange={ e => onChangePassword(e) }
      />

      {error && <div className="form--error">{ error }</div>}

      <div className={ styles.rememberMe }>
        <label className="form--field -checkbox" htmlFor="user_remember_me">
          <input
            simple="true"
            className="field"
            type="checkbox"
            value={ rememberMeValue }
            checked={ checked }
            onChange={ onChangeRememberMe }
            name="user[remember_me]"
            id="user_remember_me"
          />
          <span className="checkbox"></span>
          <span className="form--label">{ rememberMe }</span>
          <span className="-secondary info-tip display-right">
            <span className="info-tip--content">
              { rememberMeToolTip }
            </span>
          </span>
        </label>
      </div>

      <button
        onClick={ fetchLogin }
        type="button"
        className="button full-width -secondary -big -tc-"
        disabled={ disabled }
      >
        { signinLabel }
      </button>

      <div className={ styles.underSubmit }>
        <span>{ problemsLoggingIn }</span>
        <a className="button -small-padding -minimize" href="/users/password/new">
          { clickHere }
        </a>
      </div>
      <SocialButtons { ...socialButtons } />
    </div>
  );
};

export default LoginForm;
