/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';

export default class Projection extends PureComponent {
  prepareValue(value) {
    return !value ? 0 : this.props.roundingFunction(value);
  }

  renderRows() {
    return [
      {
        lable: "Equity_From_Appreciation_array",
        name: I18n.t('js.investment_calculations.complete_investment_table.equity_from_appreciation'),
        value: this.prepareValue(this.props.equity_from_appreciation),
        nameClass: 'plusSign'
      },
      {
        lable: "total_repayments",
        name: I18n.t('js.investment_calculations.complete_investment_table.total_repayment'),
        value: this.prepareValue(this.props.total_repayments),
        nameClass: 'plusSign'
      },
      {
        lable: "Predicted_Equity_From_Cash_Flow",
        name: I18n.t('js.investment_calculations.complete_investment_table.predicted_equity_from_cash_flow'),
        value: this.prepareValue(this.props.predicted_equity_from_cashflow),
        nameClass: 'plusSign'
      },
      {
        lable: "Real_Cost_of_Purchasing",
        name: I18n.t('js.investment_calculations.complete_investment_table.real_cost_of_purchasing'),
        value: this.prepareValue(this.props.real_cost_of_purchasing) * -1,
        nameClass: 'plusSign'
      },
      {
        lable: "Predicted_Capital_Appreciation_For_Sale",
        name: I18n.t('js.investment_calculations.complete_investment_table.predicted_capital_appreciation_for_sale'),
        value: this.prepareValue(this.props.predicted_capital_appreciation_for_sale),
        nameClass: 'bold'
      }
    ]
  }

  render() {
    const AvatarListArray = this.renderRows()

    return (
      <div className='Projection'>
        <p> {I18n.t('js.investment_calculations.detailed_financing_plan.projection_header')}</p>
        {AvatarListArray.map((data, key) => {
          return (
            <dl className="box-primary--row" key={key}>
              <dd className={data.nameClass}>
                {data.name}
              </dd>
              <dd className=" calculations-table--title -tr- -hsd-">
                {this.props.numberToIntegerCurrency(data.value)}
              </dd>
            </dl>
          )
        })}
        <p>
          <span onClick={this.props.handle_complete_investment_calculation} className='button -secondary'>
            {I18n.t('js.investment_calculations.complete_investment_table.show_complete_investment_calculation')}
          </span>
        </p>
      </div>
    )
  }
}
