export function updateElementAtIndex(list, index, newElement) {
  const originalItem = list[index]

  return [
    ...list.slice(0, index),
    { ...originalItem, ...newElement },
    ...list.slice(index + 1)
  ]
}

export function reorderElement(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function updateObjectInArrayById(array, id, newAttrs) {
  const elementIndex = array.findIndex(el => el.id === id);
  const newList = [...array];
  const element = newList[elementIndex];

  if (elementIndex >= 0) newList.splice(elementIndex, 1, { ...element, ...newAttrs });

  return newList;
}

