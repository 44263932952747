import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "68%", 
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row"
  },
  tableRowDarkBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#b4b7bc",
    color: "#333",
    fontWeight: "bold",
    fontSize: 14
  },
  tableRowLightBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#ececec",
    color: "#333",
    fontSize: 10
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "6px"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5
  },
  tableCellLargeFont: {
    margin: "auto",
    marginTop: 5,
  },
  tableColHeading:{
    display: 'table-cell',  
    width: '100%',
    textAlign: 'left',
    padding: "6px"
  },
  tableCol25: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "6px"
  }
});

const ObjectInfoTable2Left = (props) =>{
  return(
    <View style={styles.table}>
      <View style={styles.tableRowDarkBG}>
        <View style={styles.tableColHeading}>
          <Text style={styles.tableCellHeading}>
            Miet- Und Marktsituation
          </Text>
        </View>
      </View>
      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Hausgeld: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.reserve_amount} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Vermietet: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.rented_out} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Umlegbare Bk: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.reserve_amount_from} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Vermietet Seit: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.rental_contract_since} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Nicht umlegbare BK: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.land_transfer_cost} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Letzte Anpassung*: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.rental_contract_last_update} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Mtl. Instandhaltungsrücklage: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.monthly_reserve_payment} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Nettokaltmiete: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.estimated_cold_rent} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text>  </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text>  </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Marktmiete: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.market_cold_rent} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text>  </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Mieter: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.rented_out} </Text>
        </View>
      </View>

    </View>
  )
}
export default ObjectInfoTable2Left;
