/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react'
import I18n from 'i18n-js/index.js.erb';
import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
  tableRow: { margin: "auto", flexDirection: "row", display: "table-row" },
  tableRowDarkBG: { margin: "auto", flexDirection: "row", display: "table-row", backgroundColor: "#93989f", color: "#fff", fontWeight: "bold" },
  tableCol: { width: "14.2%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0,display: "table-cell" , borderTopWidth: 0, padding: "10px" },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 }
});

const currencyFormatEN = new Intl.NumberFormat("en-US",
  {
    currency: "EUR",
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
const currencyFormatDE = new Intl.NumberFormat("de-DE",
  {
    currency: "EUR",
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

export default class FinancePlan extends PureComponent {
  state = { show: false };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  roundingFunction = (num) => {
    return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
  }
  prepareValue(value) {
    return !value ? 0 : this.roundingFunction(value)
  }

  renderRows() {
    return [
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.equity'),
        name: "Equity",
        value: this.prepareValue(this.props.equity)
      },
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.dept'),
        name: "Dept",
        value: this.prepareValue(this.props.dept)
      },
      {
        lable: I18n.t('js.investment_calculations.complete_investment_table.interest_rate'),
        name: "interest_rate",
        value: this.prepareValue(this.props.interest_rate)
      },
      {
        lable: I18n.t('js.investment_calculations.complete_investment_table.repayment_rate'),
        name: "repayment_rate",
        value: this.prepareValue(this.props.repayment_rate)
      },
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.monthly_loan_payment'),
        name: "monthly_loan_payment",
        value: this.prepareValue(this.props.monthly_loan_payment)
      },
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.additional_annual_repayment'),
        name: "annual_repayment",
        value: this.prepareValue(this.props.annual_repayment)
      }
    ];
  }

  renderRowsModal() {
    if (this.props.finance_list_modal.length === 0) {
      return null
    } else {
      if (this.props.current_user_locale == 'en') {
        return this.props.finance_list_modal.map(function (d, key) {

          return (

            <View style={styles.tableRow} key={key}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {d.Year}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatEN.format(d.annuity)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatEN.format(d.interest_costs)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatEN.format(d.repayments)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatEN.format(d.annual_repayment)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatEN.format(d.remaining_loans)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatEN.format(d.total_repayments)}
                </Text>
              </View>
            </View>
          )
        })
      } else {
        return this.props.finance_list_modal.map(function (d, key) {

          return (

            <View style={styles.tableRow} key={key}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {d.Year}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatDE.format(d.annuity)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatDE.format(d.interest_costs)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatDE.format(d.repayments)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatDE.format(d.annual_repayment)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatDE.format(d.remaining_loans)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatDE.format(d.total_repayments)}
                </Text>
              </View>
            </View>
          )
        })
      }
    }
  }


  render() {
    const listItemsModal = this.renderRowsModal()
    return (
      <View style={styles.table}>
        {/* <thead> */}
        <View style={styles.tableRowDarkBG}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.complete_investment_table.year')}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.complete_investment_table.annuity')}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.complete_investment_table.interest')}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.complete_investment_table.repayments')}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.detailed_financing_plan.additional_annual_repayment')}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.detailed_financing_plan.remaining_loan')}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}> {I18n.t('js.investment_calculations.complete_investment_table.total_repayment')}</Text> 
          </View>
        </View>
        {listItemsModal}
        {/* </thead> */}
        {/* <tbody>
                {listItemsModal}
              </tbody> */}
      </View>
    )
  }
}
