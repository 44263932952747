import React from 'react';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import styles from './ComparisonTooltip.module.scss';

const ComparisonTooltip = ({ place, ...props }) => { 
  return ( 
    <InfoTooltip place={ place }>
      <ComparisonContent { ...props } />
    </InfoTooltip>
  );
};

const ComparisonContent = ({ description, rows }) => {
  return ( 
    <React.Fragment>
      <p>{ description }</p>
      { rows && rows.map(row => <InfoRow key={ row.type } { ...row } />) }
    </React.Fragment>
  );
};

const InfoRow = ({ type, percentage, text }) => {
  const classes = `${styles.percentage} ${styles[type]}`;
  return ( 
    <div className={ styles.InfoRow }>
      <span className={ classes }>{ percentage }</span>
      <span className={ styles.text }>{ text }</span>
    </div>
  );
};
 
export default ComparisonTooltip;