import React, { useState, useEffect } from 'react'
import FilterItem from './FilterItem'
import styles from './Filters.module.css'

export default function Filters(props) {
  const {
    accountManagers, currentUser, allUsersCount,
    selectedAccountManagerIds, onFilterSelected
  } = props


  if (!accountManagers) {
    return (
      <div className="users-list--top-line"></div>
    )
  }

  const [limit, setLimit] = useState(accountManagers.length)

  const visibleAccountManagers = accountManagers.slice(0, limit)
  const restAccountManagers = accountManagers.slice(limit, -1)

  function calculateItemsLimit() {
    const windowWidth = window.innerWidth
    const $filterItems = $('.js-filter-item')
    let filterWidth = 500

    $filterItems.each((index, filterItem) => {
      const itemWidth = filterItem.clientWidth + 30
      filterWidth = itemWidth + filterWidth

      if (filterWidth > windowWidth) {
        setLimit(index)

        return false
      }
    })
  }

  function setFilter(e, userId) {
    const userIdString = userId.toString()

    if (e.ctrlKey || e.metaKey) {
      const currentAccountManagerIds = new Set(selectedAccountManagerIds)

      if (currentAccountManagerIds.has(userIdString)) {
        currentAccountManagerIds.delete(userIdString)
      } else {
        currentAccountManagerIds.add(userIdString)
      }

      onFilterSelected({
        account_manager_ids: [...currentAccountManagerIds]
      })
    } else {
      onFilterSelected({ account_manager_ids: [userIdString] })
    }
  }

  // componentDidMount
  useEffect(() => calculateItemsLimit(), [])

  return (
    <div className="users-list--top-line">
      <div className="users-list--top-line-part -hsd- js-auto-fits-list">
        <FilterItem
          text='My Users'
          count={currentUser.users_count}
          imageUrl={currentUser.avatar_url}
          active={selectedAccountManagerIds.includes(currentUser.id.toString())}
          onClick={(e) => setFilter(e, currentUser.id)}
        />
        <FilterItem
          text="All"
          count={allUsersCount}
          iconName="people-dark"
          iconNameActiveState="people-light"
          active={selectedAccountManagerIds.includes('all')}
          onClick={(e) => setFilter(e, 'all')}
        />
      </div>
      <div className="users-list--top-line-part -hsd- js-auto-fits-list">
        {visibleAccountManagers.map((user, i) => {
          return (
            <FilterItem
              key={i}
              text={user.full_name}
              count={user.users_count}
              imageUrl={user.avatar_url}
              active={selectedAccountManagerIds.includes(user.id.toString())}
              onClick={(e) => setFilter(e, user.id)}
            />
          )
        })}
      </div>

      {restAccountManagers.length > 0 && (
        <div className={`${styles.accountManagerDropdown} dropdown account-manager-preview--dropdown`}>
          <span>{restAccountManagers.length}</span>
          <span>Others</span>
          <i className="fa fa-caret-down" />
          <div className="dropdown--inner account-manager-preview--dropdown-inner">
            {restAccountManagers.map((user, i) => {
              return (
                <div key={i} className="dropdown--button account-manager-preview--dropdown-button">
                  <FilterItem
                    text={user.full_name}
                    count={user.users_count}
                    imageUrl={user.avatar_url}
                    active={selectedAccountManagerIds.includes(user.id.toString())}
                    onClick={(e) => setFilter(e, user.id)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
