import React, { useState, useEffect, memo } from "react";
import cn from 'classnames'
import LoadingSpinner from 'commonComponents/progress/LoadingSpinner/LoadingSpinner.js'
import styles from './LoadingSpinnerWithOverlay.module.scss'

function LoadingSpinnerWithOverlay(props) {
  const {
    className,
    delayInMiliseconds,
    spinnerClassName = ''
  } = props

  const [showSpinner, setShowSpinner] = useState(!delayInMiliseconds)

  if (delayInMiliseconds) {
    const timeoutDescriptor = setTimeout(
      () => setShowSpinner(true),
      delayInMiliseconds
    )

    // Clear timer on unmount
    useEffect(() => {
      return function cleanup() {
        clearTimeout(timeoutDescriptor)
      }
    })
  }

  if (showSpinner) {
    const resultingClassName = cn('-light overlay', className)
    const resultingSpinnerClassName = cn(styles.spinner, spinnerClassName)

    return (
      <div className={resultingClassName}>
        <LoadingSpinner className={resultingSpinnerClassName} />
      </div>
    )
  } else {
    return null
  }
}

export default memo(LoadingSpinnerWithOverlay)
