import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "30%",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row"
  },
  tableRowDarkBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#b4b7bc",
    color: "#333",
    fontWeight: "bold",
    fontSize: 14
  },
  tableRowLightBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#ececec",
    color: "#333",
    fontSize: 10
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "5px"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5
  },
  tableCellLargeFont: {
    margin: "auto",
    marginTop: 5,
  },
  tableColHeading: {
    display: 'table-cell',
    width: '100%',
    textAlign: 'left',
    padding: "5px"
  },
  tableCol25: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "5px"
  }
});

const ObjectInfoTable1Right = (props) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRowDarkBG}>
        <View style={styles.tableColHeading}>
          <Text style={styles.tableCellHeading}>
            Kapitalbedarf
          </Text>
        </View>
      </View>
      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Zins / Tilgung / Laufzeit: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.interest_rate} / {props.repayment_rate} / {props.finance_list_modal}</Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Eigenkapital: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.down_payment} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Fremdkapitalbedarf: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.purchase_price} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Gesamtkapitalbedarf: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {parseFloat(props.listing_info.purchase_price) + parseFloat(props.down_payment) } </Text>
        </View>
      </View>

    </View>
  )
}
export default ObjectInfoTable1Right;
