/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import FinalCalculate from '../EditInvestmentCalculation/SubComponents/FinalCalculate';
import FactsAndFigures from '../FactsAndFigure/FactsAndFigures';
import PropertyResults from '../PropertyResults/PropertyResults';
import I18n from 'i18n-js/index.js.erb';

export default class EditInvestmentCalculation extends PureComponent {
  handle_financing_plan_modal = () => {
    this.props.handle_financing_plan_modal(this.state);
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {

    return (
      <div className="customModalPureComponent">
        <Modal backdrop={this.props.backdrop} keyboard={this.props.keyboard}
          className='customModal' show={this.props.show_edit_financing_plan}
          onHide={this.handle_financing_plan_modal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('js.investment_calculations.detailed_financing_plan.show_detailed_financing_plan')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FinalCalculate
              roundingFunction={this.props.roundingFunction}
              comparable_cold_rent={this.props.comparable_cold_rent}
              formatNumber={this.props.formatNumber}
              formatNumberWithoutCurrency={this.props.formatNumberWithoutCurrency}
              currencyFormatPercentage={this.props.currencyFormatPercentage}
              annual_gross_income={this.props.annual_gross_income}
              listing_id={this.props.listing_id}
              total_taxable_not_allocable_operating_costs={this.props.total_taxable_not_allocable_operating_costs}
              assumed_rental_income_first={this.props.assumed_rental_income_first}
              isChecked={this.props.isChecked}
              taxes={this.props.taxes}
              custom_cold_rent={this.props.custom_cold_rent}
              calculated_taxes={this.props.calculated_taxes}
              extraordinary_expenses={this.props.extraordinary_expenses}
              annual_repayment={this.props.annual_repayment}
              EditPurchasingCostFormReset={this.props.EditPurchasingCostFormReset}
              EditIncomeStatementFormReset={this.props.EditIncomeStatementFormReset}
              EditFinancingFormReset={this.props.EditFinancingFormReset}
              EditGeneralAssumptionsFormReset={this.props.EditGeneralAssumptionsFormReset}
              updateColdRentSelectedOption={this.props.updateColdRentSelectedOption}
              custom_user_purchase_price={this.props.custom_user_purchase_price}
              renovation_cost={this.props.renovation_cost}
              rent_selected_option={this.props.rent_selected_option}
              cold_rent_15={this.props.cold_rent_15}
              default_cold_rent={this.props.default_cold_rent}
              defaultLossOfRentalIncome={this.props.defaultLossOfRentalIncome}
              defaultNotAllocableOperatingCosts={this.props.defaultNotAllocableOperatingCosts}
              defaultMonthlyReservePayment={this.props.defaultMonthlyReservePayment}
              defaultSpecialPropertyManagement={this.props.defaultSpecialPropertyManagement}
              defaultExtraordinaryExpenses={this.props.defaultExtraordinaryExpenses}
              commissionAmountValue={this.props.commissionAmountValue}
              defaultCommissionValue={this.props.defaultCommissionValue}
              defaultRenovationCost={this.props.defaultRenovationCost}
              defaultRentIncreaseAfterYear={this.props.defaultRentIncreaseAfterYear}
              defaultRentIncrease={this.props.defaultRentIncrease}
              defaultBuildingAppreciation={this.props.defaultBuildingAppreciation}
              defaultBuildingAppreciationAfterYear={this.props.defaultBuildingAppreciationAfterYear}
              defaultAnnualRepayment={this.props.defaultAnnualRepayment}
              defaultShareOfBuilding={this.props.defaultShareOfBuilding}
              defaultAnnualGrossIncome={this.props.defaultAnnualGrossIncome}
              market_price={this.props.market_price}
              handleOptionChangeRent={this.props.handleOptionChangeRent}
              const_purchase_price={this.props.const_purchase_price}
              updatepriceSelectedOption={this.props.updatepriceSelectedOption}
              handleOptionChangePrice={this.props.handleOptionChangePrice}
              price_selected_option={this.props.price_selected_option}
              direct_purchase_price={this.props.direct_purchase_price}
              user_purchase_price={this.props.user_purchase_price}
              mortgage={this.props.mortgage}
              mortgage_value={this.props.mortgage_value}
              commission_percentage_value={this.props.commission_percentage_value}
              commission_value={this.props.commission_value}
              land_transfer_cost={this.props.land_transfer_cost}
              notary_cost={this.props.notary_cost}
              land_registry_cost={this.props.land_registry_cost}
              cold_rent={this.props.cold_rent}
              not_allocable_operating_costs={this.props.not_allocable_operating_costs}
              loss_of_rental_income={this.props.loss_of_rental_income}
              loss_of_rental_income_const={this.props.loss_of_rental_income_const}
              rent_increase={this.props.rent_increase}
              rent_increase_after_year={this.props.rent_increase_after_year}
              equity={this.props.equity}
              interest_rate={this.props.interest_rate}
              building_appreciation={this.props.building_appreciation}
              building_appreciation_after_year={this.props.building_appreciation_after_year}
              share_of_building={this.props.share_of_building}
              per_year_depreciation={this.props.per_year_depreciation}
              depreciation={this.props.depreciation}
              total_cost_of_purchasing={this.props.total_cost_of_purchasing}
              handleChange={this.props.handleChange}
              repayment_rate={this.props.repayment_rate}
              monthly_reserve_payment={this.props.monthly_reserve_payment}
              special_property_management={this.props.special_property_management}
              error_message_general_assumptions={this.props.error_message_general_assumptions}
              error_message_financing={this.props.error_message_financing}
              error_message_income_statement={this.props.error_message_income_statement}
              error_message_purchasing_cost={this.props.error_message_purchasing_cost}
              default_mortgage={this.props.default_mortgage}
              default_interest_rate={this.props.default_interest_rate}
              default_repayment_rate={this.props.default_repayment_rate}
              error_message_purchasing_cost_empty={this.props.error_message_purchasing_cost_empty}
              rentedOut={this.props.rentedOut}
              candidate={this.props.candidate}
            />
            <FactsAndFigures
              currencyFormatPercentage={this.props.currencyFormatPercentage}
              downPayment={this.props.down_payment}
              factor={this.props.factor}
              fullHeader={this.props.fullHeader}
              grossYield={this.props.gross_yield}
              netYield={this.props.net_yield}
              numberToIntegerCurrency={this.props.numberToIntegerCurrency}
              formatNumberWithoutCurrency={this.props.formatNumberWithoutCurrency}
              predictedCapitalAppreciationForSale={this.props.predicted_capital_appreciation_for_sale}
              predictedReturnOnEquityAfterSalePA={this.props.predicted_return_on_equity_after_sale_PA}
              rentedOut={this.props.rentedOut}
              showNetYield={this.props.showNetYield}
            />
            <PropertyResults
              formatNumber={this.props.formatNumber}
              currencyFormatPercentage={this.props.currencyFormatPercentage}
              cold_rent={this.props.cold_rent}
              not_allocable_operating_costs={this.props.not_allocable_operating_costs}
              monthly_reserve_payment={this.props.monthly_reserve_payment}
              special_property_management={this.props.special_property_management}
              additional_cost={this.props.additional_cost}
              extraordinary_expenses={this.props.extraordinary_expenses}
              loss_of_rental_income_const={this.props.loss_of_rental_income_const}
              lossOfRentalIncome={this.props.loss_of_rental_income}
              assumed_rental_income_first={this.props.assumed_rental_income_first}
              calculated_taxes={this.props.calculated_taxes}
              first_interest_cost={this.props.first_interest_cost}
              first_annual_repayment={this.props.first_annual_repayment}
              shareOfBuilding={this.props.share_of_building}
              perYearDepreciation={this.props.per_year_depreciation}
              interestRate={this.props.interest_rate}
              repaymentRate={this.props.repayment_rate}
              taxes={this.props.taxes}
            />
          </Modal.Body>
          <Modal.Footer>
            <span onClick={this.handle_financing_plan_modal} className='button -secondary closeForm'>
              {I18n.t('js.investment_calculations.detailed_financing_plan.cancel')}
            </span>

            <span onClick={this.props.saveFormInfo}
              className={(this.props.error_message_purchasing_cost_empty) ? 'button-disabled button -secondary save' : 'button -secondary save'}
            >
              {I18n.t('js.investment_calculations.detailed_financing_plan.save')}
            </span>

            <label className="form--field -checkbox checkboxForm">
              <input type="checkbox" checked={this.props.isChecked}
                onChange={this.props.handleChangeCheckbox}
              />
              <span className="checkbox"></span>
              <span className="form--label">{I18n.t('js.investment_calculations.detailed_financing_plan.save_for_future')}</span>
            </label>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}
