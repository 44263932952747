import React, { Component } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import debounce from 'lodash.debounce'

import { updateElementAtIndex, updateObjectInArrayById } from 'lib/utils/arrayUtils'
import FilterUrlHistory from 'lib/FilterUrlHistory'

import LoadingSpinnerWithOverlay from 'commonComponents/progress/LoadingSpinnerWithOverlay/LoadingSpinnerWithOverlay.js'

import Filters from './elements/Filters/Filters.js'
import CardList from './elements/CardList/CardList.js'
import NoUsers from './elements/NoUsers/NoUsers.js'
import styles from './SmartiPipelineContainer.module.scss'


export default class SmartiPipelineContainer extends Component {
  static defaultProps = {
    accountManagers: [],
    currentUserImageUrl: '',
    columns: {}
  }

  RESIZE_DEBOUCE_TIME_MS = 200
  candidateProfileApi = WunderJS.ApiClients.CandidateProfileAPI
  cardListWrapperRef = React.createRef()

  state = {
    initialDataLoaded: false,
    filter: {
      account_manager_ids: this.getInitialAccountManagerIds()
    },
    dataLoading: false,
    dataStale: false,
    boardHeight: null
  }

  getInitialAccountManagerIds() {
    const accountManagerIds = this.props.initial_filter.account_manager_ids

    return accountManagerIds ? accountManagerIds : []
  }

  defaultFilter() {
    return (
      {
        account_manager_ids: [this.props.current_user.id.toString()]
      }
    )
  }

  setFilterFromUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    const rawAccountManagerIds = urlParams.get('account_manager_ids')

    const filter = {
      account_manager_ids: JSON.parse(rawAccountManagerIds)
    }

    this.loadData(filter)
  }

  componentDidMount() {
    if (WunderJS.Instances.dynamicInput) {
      WunderJS.Instances.dynamicInput.on('save', () => {
        this.setState({ dataStale: true })
      })
    }

    FilterUrlHistory.onNavigateBack(() => {
      this.setFilterFromUrl()
    })

    this.loadInitialData(() => {
      setTimeout(() => {
        this.calculateColumnHeight()
      }, 100)
    })
  }

  componentWillUnmount() {
    FilterUrlHistory.flushCallback()
  }

  calculateColumnHeight = () => {
    const columnsBoard = this.cardListWrapperRef.current

    if (columnsBoard) {
      this.setState({ boardHeight: columnsBoard.clientHeight })
    }
  }

  handleWindowResize = debounce(this.calculateColumnHeight, this.RESIZE_DEBOUCE_TIME_MS)

  moveDroppableElementsFromOneListToAnother(source, destination, droppableSource) {
    const cloneSource = Array.from(source)
    const cloneDestination = Array.from(destination)
    const [removedElement] = cloneSource.splice(droppableSource.index, 1)

    cloneDestination.push(removedElement)

    return {
      sourceList: cloneSource,
      destinationList: cloneDestination
    }
  }

  handleDragEnd = (dndResult) => {
    const { source, destination } = dndResult

    // Dropped outside the list
    if (!destination) {
      return
    }

    const sourceDroppableId = Number.parseInt(source.droppableId)
    const destinationDropableId = Number.parseInt(destination.droppableId)

    const sourceColumn = this.state.columns[sourceDroppableId]
    const sourceList = sourceColumn.items
    const userId = sourceList[source.index].id

    if (sourceDroppableId !== destinationDropableId) {
      // Moves element from one list to another
      const destinationColumn = this.state.columns[destinationDropableId]
      const destinationList = destinationColumn.items
      const milestone = destinationColumn.milestone

      const moveResult = this.moveDroppableElementsFromOneListToAnother(
        sourceList, destinationList, source
      )
      const newLists = [...this.state.columns]

      const newSourceList = newLists[sourceDroppableId]
      const newDestinationList = newLists[destinationDropableId]

      newSourceList.items = moveResult.sourceList
      newSourceList.items_total_count = sourceColumn.items_total_count - 1

      newDestinationList.items = moveResult.destinationList
      newDestinationList.items_total_count = destinationColumn.items_total_count + 1

      this.setState({ columns: newLists }, () => {
        this.candidateProfileApi
            .updateMilestone(userId, { milestone })
            .then((response) => {
              const newItemsWithReverResponse = updateObjectInArrayById(
                this.state.columns[destinationDropableId].items,
                response.user.id, response.user
              )

              const newListsWithServerResponse = updateElementAtIndex(
                this.state.columns,
                destinationDropableId,
                { items: newItemsWithReverResponse }
              )

              this.setState(
                { columns: newListsWithServerResponse },
                () => this.reloadAccountManagers()
              )
            })
      })
    }
  }

  reloadAccountManagers() {
    WunderJS.ApiClients.UsersApi
      .loadSmartiPipelineAccountManagersData()
      .then((response) => {
        this.setState({
          accountManagers: response.account_managers
        })
      })
  }

  filterUsers = (newFilterProperties) => {
    const { filter } = this.state

    const newFilter = Object.assign({}, filter, newFilterProperties)
    FilterUrlHistory.push(newFilter)

    this.loadData(newFilter)
  }

  loadDataWithFressnessCheck() {
    const { filter, dataStale } = this.state

    if (!dataStale) return

    this.loadData(filter)
  }

  loadInitialData(callback) {
    this.setState({
      dataLoading: false
    }, () => {
      WunderJS.ApiClients.UsersApi
        .loadSmartiPipelineData(this.state.filter)
        .then((response) => {
          this.handleLoadDataResponse(response)

          if (callback) callback()
        })
    })
  }

  loadData(filter) {
    this.setState({
      filter,
      dataLoading: true
    }, () => {
      WunderJS.ApiClients.UsersApi
        .loadSmartiPipelineData(filter)
        .then((response) => {
          this.handleLoadDataResponse(response)
        })
    })
  }

  handleLoadDataResponse(response) {
    this.setState({
      currentUser: response.current_user,
      allUsersCount: response.all_users_count,
      unassignedUsersCount: response.unassigned_users_count,
      accountManagers: response.account_managers,
      columns: response.columns,
      dataLoading: false,
      initialDataLoaded: true,
      dataStale: false
    })
  }

  openClassicDynamicModal({ modalSelector, loadDataFunc, callbacks }) {
    const $modal = $(modalSelector)

    WunderJS.Components.Modal.show($modal, null, callbacks)

    WunderJS.Instances.Overlay.show()
    $modal.dialog('open')

    loadDataFunc()
      .then((response) => {
        $modal.html(response.html)

        WunderJS.Instances.dynamicInput._initUI()
        WunderJS.Instances.Overlay.hide()
      })
  }

  handleUserEditBadgeClick = (userId) => {
    this.openClassicDynamicModal({
      modalSelector: '#additional-information-user-box',
      loadDataFunc: () => WunderJS.ApiClients.CandidateProfileAPI.showAdditionalInfoModal(userId),
      callbacks: { onBeforeClose: () => this.loadDataWithFressnessCheck() }
    })
  }

  hadndleUserSearchProfileBadgeClick = (userId) => {
    this.openClassicDynamicModal({
      modalSelector: '#search_profile_box',
      loadDataFunc: () => WunderJS.ApiClients.CandidateProfileAPI.showSearchProfileModal(userId),
      callbacks: { onBeforeClose: () => this.loadDataWithFressnessCheck() },
    })
  }

  resetFilter = () => {
    const newFilter = this.defaultFilter()

    FilterUrlHistory.push({})

    this.loadData(newFilter)
  }

  showAllUsers = () => {
    this.filterUsers({ account_manager_ids: ['all'] })
  }

  renderColumnTitles() {
    const { columns, initialDataLoaded } = this.state

    if (!initialDataLoaded) return null

    return (
      columns.map((list, i) => (
        <div
          key={list.title + i}
          className={styles.cardListHeading}
        >
          <div className={styles.cardListHeadingInner}>
            <div>
              {list.title}
            </div>
            <div>
              ({list.items_total_count})
            </div>
          </div>
        </div>
      ))
    )
  }

  renderBoard() {
    const { columns, boardHeight } = this.state

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        {columns.map((column, index) => (
          <CardList
            key={index}
            index={index}
            items={column.items}
            boardHeight={boardHeight}
            onUserEditBadgeClick={this.handleUserEditBadgeClick}
            onUserEditSeachProfileBadgeClick={this.hadndleUserSearchProfileBadgeClick}
          />
        ))}
      </DragDropContext>
    )
  }

  myUsersFilterEnabled() {
    const { filter, currentUser } = this.state

    return filter.account_manager_ids[0] === currentUser.id.toString()
  }

  renderNoUsers() {
    return (
      <NoUsers
        showAllUsersButtonEnabled={this.myUsersFilterEnabled()}
        onShowAllUsersButtonClick={this.showAllUsers}
        onResetButtonClick={this.resetFilter}
      />
    )
  }

  usersPresent = () => {
    const { columns } = this.state

    const anyUserPresent =
        Object
          .values(columns)
          .some((column) => column.items.length > 0)

    return anyUserPresent
  }

  renderBoardBody() {
    const { initialDataLoaded } = this.state

    if (!initialDataLoaded) return null

    const usersPresent = this.usersPresent()

    return (
      <>
        {usersPresent && this.renderBoard()}
        {!usersPresent && this.renderNoUsers()}
      </>
    )
  }

  render() {
    const { currentUserImageUrl } = this.props
    const {
      initialDataLoaded, accountManagers, currentUser,
      allUsersCount, unassignedUsersCount, filter, dataLoading
    } = this.state

    const { account_manager_ids } = filter

    return (
      <div className={styles.userCardView}>
        <Filters
          currentUser={currentUser}
          selectedAccountManagerIds={account_manager_ids}
          allUsersCount={allUsersCount}
          unassignedUsersCount={unassignedUsersCount}
          currentUserImageUrl={currentUserImageUrl}
          accountManagers={accountManagers}
          onFilterSelected={this.filterUsers}
        />
        <div className={styles.boardCanvas}>
          <div className={styles.board}>
            <div className={styles.flexWrapper}>
              <div className={styles.cardListsHeadings}>
                {this.renderColumnTitles()}
              </div>
            </div>
            {dataLoading && (
              <LoadingSpinnerWithOverlay delayInMiliseconds={350} />
            )}
            <div
              ref={this.cardListWrapperRef}
              className={styles.cardLists}
            >
              {!initialDataLoaded && <LoadingSpinnerWithOverlay delayInMiliseconds={750} /> }
              {this.renderBoardBody()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
