import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class ObjectInfoPieChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom'
        },
        labels: ['Kaufpreis', 'Provision (käufer)', 'Grunderwerbssteuer', 'Notarkosten']
      },
      series: [
        parseFloat(this.props.purchase_price),
        parseFloat(this.props.commission),
        ((parseFloat(this.props.purchase_price) * parseFloat(this.props.land_transfer_cost)) / 100),
        ((parseFloat(this.props.purchase_price) * parseFloat(this.props.notary_cost)) / 100)
      ],
    }
  }

  render() {
    return (

      <div style={{ visibility: this.props.visibility }} className="donut"
        id="donutChart"
      >
        <Chart options={this.state.options} series={this.state.series}
          type='pie' width="380"
        />
      </div>

    );
  }
}

export default ObjectInfoPieChart;