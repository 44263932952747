import React from 'react'
import PropTypes from 'prop-types'

export default class StoredFiltersSelectorContainer extends React.Component {
  static propTypes = {
    stored_filters:      PropTypes.array,
    current_filter_name: PropTypes.string.isRequired,
    default_option_name: PropTypes.string.isRequired,
    default_path:        PropTypes.string.isRequired
  }

  static defaultProps = {
    stored_filters: []
  }

  DEFAULT_OPTION_VALUE = 'default'

  navigateToFilterUrl = (e) => {
    const { default_path } = this.props
    const selectedOption = e.target.childNodes[e.target.selectedIndex]

    if (selectedOption.value == this.DEFAULT_OPTION_VALUE)  {
      window.location.href = default_path

      return
    }

    const optionPath = selectedOption.getAttribute('path')
    const currentHref = `${optionPath}&filter_name=${selectedOption.value}`

    window.location.href = currentHref
  }

  render() {
    const {
      current_filter_name,
      default_option_name, stored_filters
    } = this.props

    return (
      <select
        id='stored_filters'
        className='field'
        value={current_filter_name}
        onChange={this.navigateToFilterUrl}
      >
        <option
          key={0}
          value={this.DEFAULT_OPTION_VALUE}
          path={null}
        >
          {default_option_name}
        </option>

        {stored_filters.map((filter) =>
          <option
            key={filter.id}
            value={filter.name}
            path={filter.path}
          >
            {filter.name}
          </option>
        )}
      </select>
    )
  }
}
