import React from 'react';
import styles from './Header.module.scss';

const Header = ({ header, subHeader }) => (
  <header className={ styles.loginTitle }>
    <h4>{ header }</h4>
    <h1>{ subHeader }</h1>
  </header>
);

export default Header;
