import React, { useState } from 'react';
import { headerSeparator } from 'commonComponents/common';
import styles from './PrimarySectionHeader.module.scss';
import linkStyles from '../../common.module.scss';
import CopyTip from '../../tooltips/CopyTip/CopyTip';
import { copyUrlToClipboard } from '../../../lib/utils/copyToClipboardUtil';

const PrimarySectionHeader = ({
  header,
  subheader,
  anchor = 'default',
  isMajor = false,
  additionalClass
}) => {
  const classes = `${styles.PrimarySectionHeader} ${ isMajor ? styles.major : '' } ${ additionalClass }`;
  const [copied, setCopied] = useState(false);

  const copyUrl = () => {
    setCopied(true);
    copyUrlToClipboard(anchor);
    setTimeout((function() {
      setCopied(false);
    }), 4000);
  };

  return (
    <div className={ classes } >
      <a name={ anchor }></a>
      <h4 className={ styles.header }>
        { header }
        <div className={ linkStyles.linkIcon } onClick={ copyUrl } />
      </h4>
      { headerSeparator }
      { subheader && <h1>{ subheader }</h1> }
      <CopyTip copied={ copied } />
    </div>
  );
};
 
export default PrimarySectionHeader;