import React from 'react';
import Header from './Header/Header';
import Form from './Form/Form';
import styles from './Login.module.scss';

const Login = ({
  header,
  subHeader,
  ...formProps
}) => {
  const headerProps = { header, subHeader };

  return (
    <div className={ `-bg-login ${ styles.LoginWrapper }` }>
      <div className={ styles.Login }>
        <Header { ...headerProps } />
        <Form { ...formProps } />
      </div>
    </div>
  );
};

export default Login;
