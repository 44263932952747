import React from 'react'
import PropTypes from 'prop-types'

export default class FixedTipContainer extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  }
  // NOTE We need to store about custom scroll and currentTip
  // between all instancces of FixedTipContainer react component
  // NOTE static properties access is possible by using
  // "this.constructor.propertyName" syntax
  static customScrollEventSet = false
  static currentTip = undefined

  componentDidMount() {
    if (this.constructor.customScrollEventSet) return

    document
      .querySelector('.js-custom-scroll')
      .addEventListener('scroll', this.updateTipPosition)

    this.constructor.customScrollEventSet = true
  }

  onMouseEnter = (e) => {
    this.constructor.currentTip = e.currentTarget
    this.updateTipPosition()
  }

  updateTipPosition = () => {
    if (!this.constructor.currentTip) return

    const tipRect = this.constructor.currentTip.getBoundingClientRect()
    const offset = { top: tipRect.top, left: tipRect.left }

    this.constructor
        .currentTip
        .getElementsByClassName('fixed-tip--content')[0]
        .setAttribute('style', `top: ${offset.top}px; left: ${offset.left}px`)
  }

  render() {
    const lines = this.props.text.split('\\n')

    return (
      <div className='fixed-tip' onMouseEnter={this.onMouseEnter}>
        &nbsp;
        <div className='fixed-tip--content'>
          {lines.map((item, index) => (
            <span key={index}>
              {item}<br />
            </span>)
          )}
        </div>
      </div>
    )
  }
}
