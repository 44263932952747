export default class VimeoIframeApiLoader {
  static VIMEO_IFRAME_API_URL = 'https://player.vimeo.com/api/player.js'
  static LOAD_STATES = {
    NOT_LOADED: 'not_loaded',
    LOADING: 'loading',
    LOADED: 'loaded'
  }

  static state = this.LOAD_STATES.NOT_LOADED
  static apiReadyReadyCallbacks = []

  static load() {
    if (this.alreadyLoadedOrInProgress()) {
      // Stop loading if already loaded/loading
      return this
    }

    this.state = this.LOAD_STATES.LOADING

    this.loadVimeoIframePlayerApi()

    return this
  }

  static alreadyLoadedOrInProgress() {
    return (
      this.state === this.LOAD_STATES.LOADED ||
      this.state === this.LOAD_STATES.LOADING
    )
  }

  static handleVimeoApiReady = () => {
    this.state = this.LOAD_STATES.LOADED

    while(this.apiReadyReadyCallbacks.length > 0) {
      const callback = this.apiReadyReadyCallbacks.shift()

      callback()
    }
  }

  static loadVimeoIframePlayerApi() {
    const vimeoScript = document.createElement('script');
    vimeoScript.src = this.VIMEO_IFRAME_API_URL
    vimeoScript.onload = this.handleVimeoApiReady

    const headTag = document.getElementsByTagName('head')[0];
    headTag.append(vimeoScript);
  }

  static onLoad(callback) {
    if (this.state === this.LOAD_STATES.LOADED) {
      // If YoutubeIframeApi is loaded - execute callback immediately
      callback()

      return this
    } else {
      // If YoutubeIframeApi is still not loaded - push callback to
      // this.apiReadyReadyCallbacks callback list
      this.apiReadyReadyCallbacks.push(callback)

      return this
    }
  }
}
