/* eslint-disable no-magic-numbers */

import React, { PureComponent } from 'react';
import EditPurchasingCostForm from "./EditPurchasingCostForm";
import EditIncomeStatementForm from "./EditIncomeStatementForm";
import EditFinancingForm from "./EditFinancingForm";
import EditTaxableProfitForm from "./EditTaxableProfitForm";
import EditGeneralAssumptionsForm from "./EditGeneralAssumptionsForm";
import I18n from 'i18n-js/index.js.erb';


class FinalCalcualte extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      token: ''
    }
  }

  componentDidMount() {
    this.setState({ token: $('meta[name=csrf-token]').attr('content') })
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.props.handleChange(name, value)
  }

  renderMadeAssumptionTip = (array) => {
    return (
      <div>
        <span>{I18n.t('js.investment_calculations.manage_assumptions.you_made_custom_assumption')}</span>
        <br />
        {
          array.map((item, index) => (
            <div key={index}>
              <span>
                {I18n.t('js.investment_calculations.manage_assumptions.original')} {item.name}: {item.value}
              </span>
              <br />
            </div>
          ))
        }
      </div>
    );
  }

  render() {
    const {
      assumed_rental_income_first,
      calculated_taxes,
      candidate,
      currencyFormatPercentage,
      depreciation,
      formatNumber,
      formatNumberWithoutCurrency,
      handleChange,
      roundingFunction,
      total_cost_of_purchasing,
      total_taxable_not_allocable_operating_costs
    } = this.props;

    const rentIncrease = parseFloat(this.props.rent_increase);
    const repaymentRate = roundingFunction(this.props.repayment_rate);
    const mortgageValue = roundingFunction(this.props.mortgage_value);
    const interestRate = parseFloat(this.props.interest_rate);
    const defaultInterestRate = parseFloat(this.props.default_interest_rate);
    const defaultMortgage = parseFloat(this.props.default_mortgage);
    const extraOrdinaryExpenses = roundingFunction(this.props.extraordinary_expenses);
    const lossOfRentalIncomeConst = roundingFunction(this.props.loss_of_rental_income_const);
    const lossOfRentalIncome = roundingFunction(this.props.loss_of_rental_income);
    const specialPropertyManagement = roundingFunction(this.props.special_property_management);
    const monthlyReservePayment = roundingFunction(this.props.monthly_reserve_payment);
    const notAllocableOperatingCosts = roundingFunction(this.props.not_allocable_operating_costs);
    const coldRent = roundingFunction(this.props.cold_rent);
    const mortgage = roundingFunction(this.props.mortgage);

    const interestCosts = mortgageValue * interestRate / 100;
    const repayments = mortgageValue * repaymentRate / 100;

    const netIncomeMonthly = coldRent -
                             notAllocableOperatingCosts -
                             monthlyReservePayment -
                             specialPropertyManagement -
                             lossOfRentalIncomeConst / 12 -
                             extraOrdinaryExpenses;

    const netIncome = 12 * netIncomeMonthly;

    const total_taxable_profit = parseFloat(assumed_rental_income_first) +
                                 parseFloat(total_taxable_not_allocable_operating_costs) +
                                 parseFloat(depreciation) -
                                 interestCosts;

    return (
      <div>
        <form id="modalForm"
          action={`/listings/${this.props.listing_id}/update_investment_calculation`}
          method="post" className="Financing_Plan_form"
        >
          <div className='formPureComponent'>
            <div className="formHeading">
              <p>{I18n.t('js.investment_calculations.complete_investment_table.purchasing_costs')}</p>
              { this.props.error_message_purchasing_cost &&
                <div className="calculations-table--col small resetButtonBlock"
                  onClick={this.props.EditPurchasingCostFormReset}
                >
                  <div className="calculations-table--reset js-reset-all" name="user_purchase_price">
                    <i className="icon-reset-orange -mediumplus"></i>
                    <span className="text-orange">
                      {I18n.t('js.investment_calculations.manage_assumptions.reset')}
                    </span>
                  </div>
                </div>
              }
              <hr className="titleUnderline" />
            </div>
            <div className="formTable">
              <table>
                <EditPurchasingCostForm
                  formatNumber={formatNumber}
                  currencyFormatPercentage={currencyFormatPercentage}
                  formatNumberWithoutCurrency={formatNumberWithoutCurrency}
                  const_purchase_price={this.props.const_purchase_price}
                  updatepriceSelectedOption={this.props.updatepriceSelectedOption}
                  handleOptionChangePrice={this.props.handleOptionChangePrice}
                  custom_user_purchase_price={this.props.custom_user_purchase_price}
                  price_selected_option={this.props.price_selected_option}
                  direct_purchase_price={this.props.direct_purchase_price}
                  handleChange={handleChange}
                  user_purchase_price={this.props.user_purchase_price}
                  commission_percentage_value={this.props.commission_percentage_value}
                  commission_value={this.props.commission_value}
                  land_transfer_cost={this.props.land_transfer_cost}
                  notary_cost={this.props.notary_cost}
                  land_registry_cost={this.props.land_registry_cost}
                  renovation_cost={this.props.renovation_cost}
                  error_message_purchasing_cost_empty={this.props.error_message_purchasing_cost_empty}
                  commissionAmountValue={this.props.commissionAmountValue}
                  defaultCommissionValue={this.props.defaultCommissionValue}
                  defaultRenovationCost={this.props.defaultRenovationCost}
                  renderMadeAssumptionTip={this.renderMadeAssumptionTip}
                />
              </table>
            </div>
            <div className='totalOutput'>
              <span>
                {I18n.t('js.investment_calculations.complete_investment_table.total_purchasing_costs')}
              </span>
              <span className='rightAlign'>
                {!total_cost_of_purchasing ? 0 : formatNumber(total_cost_of_purchasing)}
              </span>
            </div>
          </div>
          <div className='formPureComponent RentalIncome'>
            <div className="formHeading">
              <p> {I18n.t('js.investment_calculations.complete_investment_table.income_statement')} </p>
              { this.props.error_message_income_statement &&
                <div className="calculations-table--col small resetButtonBlock"
                  onClick={this.props.EditIncomeStatementFormReset}
                >
                  <div className="calculations-table--reset js-reset-all" name="user_purchase_price">
                    <i className="icon-reset-orange -mediumplus"></i>
                    <span className="text-orange">
                      {I18n.t('js.investment_calculations.manage_assumptions.reset')}
                    </span>
                  </div>
                </div>
              }
              <hr className="titleUnderline" />
            </div>
            <div className="formTable">
              <table>
                <EditIncomeStatementForm
                  comparable_cold_rent={this.props.comparable_cold_rent}
                  formatNumber={formatNumber}
                  formatNumberWithoutCurrency={formatNumberWithoutCurrency}
                  currencyFormatPercentage={currencyFormatPercentage}
                  updateColdRentSelectedOption={this.props.updateColdRentSelectedOption}
                  rent_selected_option={this.props.rent_selected_option}
                  cold_rent_15={this.props.cold_rent_15}
                  default_cold_rent={this.props.default_cold_rent}
                  market_price={this.props.market_price}
                  custom_cold_rent={this.props.custom_cold_rent}
                  handleOptionChangeRent={this.props.handleOptionChangeRent}
                  handleChange={handleChange}
                  coldRent={coldRent}
                  notAllocableOperatingCosts={notAllocableOperatingCosts}
                  monthlyReservePayment={monthlyReservePayment}
                  specialPropertyManagement={specialPropertyManagement}
                  lossOfRentalIncome={lossOfRentalIncome}
                  defaultSpecialPropertyManagement={this.props.defaultSpecialPropertyManagement}
                  defaultNotAllocableOperatingCosts={this.props.defaultNotAllocableOperatingCosts}
                  defaultMonthlyReservePayment={this.props.defaultMonthlyReservePayment}
                  defaultLossOfRentalIncome={this.props.defaultLossOfRentalIncome}
                  defaultExtraordinaryExpenses={this.props.defaultExtraordinaryExpenses}
                  renderMadeAssumptionTip={this.renderMadeAssumptionTip}
                  lossOfRentalIncomeConst={lossOfRentalIncomeConst}
                  extraOrdinaryExpenses={extraOrdinaryExpenses}
                  rentedOut={this.props.rentedOut}
                />
              </table>
            </div>
            <div className='totalOutput'>
              <span> {I18n.t('js.investment_calculations.complete_investment_table.net_income')} </span>
              <span className='rightAlign'>{formatNumber(netIncome)}</span>
              <span>{formatNumber(netIncomeMonthly)}</span>
            </div>
          </div>
          <div className='formPureComponent'>
            <div className="formHeading">
              <p> {I18n.t('js.investment_calculations.complete_investment_table.financing')} </p>
              { this.props.error_message_financing &&
                <div className="calculations-table--col small resetButtonBlock"
                  onClick={this.props.EditFinancingFormReset}
                >
                  <div className="calculations-table--reset js-reset-all" name="user_purchase_price">
                    <i className="icon-reset-orange -mediumplus"></i>
                    <span className="text-orange">
                      {I18n.t('js.investment_calculations.manage_assumptions.reset')}
                    </span>
                  </div>
                </div>
              }
              <hr className="titleUnderline" />
            </div>
            <div className="formTable">
              <table>
                <EditFinancingForm
                  formatNumberWithoutCurrency={formatNumberWithoutCurrency}
                  formatNumber={formatNumber}
                  currencyFormatPercentage={currencyFormatPercentage}
                  renderMadeAssumptionTip={this.renderMadeAssumptionTip}
                  handleChange={handleChange}
                  equity={this.props.equity}
                  interestRate={interestRate}
                  mortgage={mortgage}
                  mortgageValue={mortgageValue}
                  defaultMortgage={defaultMortgage}
                  defaultInterestRate={defaultInterestRate}
                  defaultRepaymentRate={this.props.default_repayment_rate}
                  repaymentRate={repaymentRate}
                  interestCosts={interestCosts}
                  repayments={repayments}
                />
              </table>
            </div>
            <div className='totalOutput total-financing-cost'>
              <span>
                {I18n.t('js.investment_calculations.complete_investment_table.total_financing_cost')}
              </span>
              <span className='rightAlign'>{formatNumber(roundingFunction(repayments + interestCosts))}</span>
              <span>{formatNumber(roundingFunction((repayments + interestCosts) / 12))}</span>
            </div>
          </div>
          <div className='formPureComponent'>
            <div className="formHeading">
              <p> {I18n.t('js.investment_calculations.complete_investment_table.general_assumptions')} </p>
              { this.props.error_message_general_assumptions &&
                <div className="calculations-table--col small resetButtonBlock"
                  onClick={this.props.EditGeneralAssumptionsFormReset}
                >
                  <div className="calculations-table--reset js-reset-all" name="user_purchase_price">
                    <i className="icon-reset-orange -mediumplus"></i>
                    <span className="text-orange">
                      {I18n.t('js.investment_calculations.manage_assumptions.reset')}
                    </span>
                  </div>
                </div>
              }
              <hr className="titleUnderline" />
            </div>
            <div className="formTable">
              <table>
                <EditGeneralAssumptionsForm
                  currencyFormatPercentage={currencyFormatPercentage}
                  formatNumber={formatNumber}
                  formatNumberWithoutCurrency={formatNumberWithoutCurrency}
                  handleChange={handleChange}
                  renderMadeAssumptionTip={this.renderMadeAssumptionTip}
                  rentIncrease={rentIncrease}
                  rentIncreaseAfterYear={this.props.rent_increase_after_year}
                  buildingAppreciation=
                    {this.props.building_appreciation}
                  buildingAppreciationAfterYear=
                    {this.props.building_appreciation_after_year}
                  annualRepayment={this.props.annual_repayment}
                  shareOfBuilding={this.props.share_of_building}
                  annualGrossIncome={this.props.annual_gross_income}
                  defaultRentIncreaseAfterYear={this.props.defaultRentIncreaseAfterYear}
                  defaultRentIncrease={this.props.defaultRentIncrease}
                  defaultBuildingAppreciation={this.props.defaultBuildingAppreciation}
                  defaultBuildingAppreciationAfterYear={this.props.defaultBuildingAppreciationAfterYear}
                  defaultAnnualRepayment={this.props.defaultAnnualRepayment}
                  defaultShareOfBuilding={this.props.defaultShareOfBuilding}
                  defaultAnnualGrossIncome={this.props.defaultAnnualGrossIncome}
                />
              </table>
            </div>
          </div>
          <div className='formPureComponent TextableRenralIncome'>
            <div className='formHeading'>
              <p>{I18n.t('js.investment_calculations.complete_investment_table.taxable_profit')}</p>
              <hr className="titleUnderline" />
            </div>
            <hr />
            <div className="formTable">
              <table>
                <EditTaxableProfitForm
                  formatNumber={formatNumber}
                  currencyFormatPercentage={currencyFormatPercentage}
                  handleChange={handleChange}
                  total_taxable_not_allocable_operating_costs=
                    {total_taxable_not_allocable_operating_costs}
                  assumed_rental_income_first={assumed_rental_income_first}
                  per_year_depreciation={this.props.per_year_depreciation}
                  depreciation={depreciation}
                  interestCosts={interestCosts}
                />
              </table>
            </div>
            <div className='totalOutput'>
              <span>
                {I18n.t('js.investment_calculations.complete_investment_table.total_taxable_profit')}
              </span>
              <span className='rightAlign'>
                {formatNumber(!total_taxable_profit ? 0 : total_taxable_profit)}
              </span>
            </div>
            <div className='formTable'>
              <table>
                <tbody>
                  <tr>
                    <td> {I18n.t('js.investment_calculations.complete_investment_table.taxes')} </td>
                    <td>
                      <p>{formatNumber(!calculated_taxes ? 0 : calculated_taxes)}</p>
                    </td>
                    <td>
                      <p>{currencyFormatPercentage(this.props.taxes)}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <input name="authenticity_token" type="hidden"
            value={this.state.token}
          />
          <input name="save_to_user_plan" type="hidden"
            value={this.props.isChecked}
          />
          <input name="gross_yield" type="hidden"
            value={this.props.gross_yield}
          />
          <input name="net_yield" type="hidden"
            value={this.props.net_yield}
          />
          {
            candidate &&
            <input name="candidate" type="hidden"
              value={candidate}
            />
          }
        </form>
        <hr />
      </div>
    );
  }
}

export default FinalCalcualte;
