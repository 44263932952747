import React, { PureComponent, Component } from 'react'
import './UserBadges.scss'
import cn from 'classnames'
import Tip from 'commonComponents/tooltips/Tip/Tip'
import 'i18n-js/setupTranslations.js.erb'
import { getMilestoneIconColor, getQualifiedStepIconColor } from './userBadgesHelpers'

function HotLeadIcon(props) {
  const { leadPriority } = props

  if (leadPriority && leadPriority.length > 0 && leadPriority !== 'false') {
    const className = `${leadPriority}_lead_class`

    return (
      <div className="user--badge-wrapper">
        <div
          className={`glyphicon glyphicon-fire applicant-header--hot-lead user--badge-icon ${className}`}
        />
      </div>
    )
  } else {
    return null
  }
}

function ConfirmationProcessIcon(props) {
  const { confirmationStatus } = props
  let tipText = ''
  let iconClassName = ''

  switch (confirmationStatus) {
    case 'not_started_confirmation_process':
      tipText = I18n.t('js.users.icon_badges.confirmation_status.not_confirmed_tip')
      iconClassName = 'icon-user-sand-clock user--badge-icon'
      break

    case 'not_fully_confirmed':
      tipText = I18n.t('js.users.icon_badges.confirmation_status.partially_confirmed_tip')
      iconClassName = 'icon-user-sand-clock-orange user--badge-icon'
      break
  }

  return (
    <div className="user--badge-wrapper">
      <Tip content={tipText}>
        <i className={iconClassName} />
      </Tip>
    </div>
  )
}

function IndividualConsultationProcessIcon(props) {
  const tipContent = (
    <p>
      {I18n.t('js.users.icon_badges.individual_consultation_process.tip')}
    </p>
  )

  return (
    <div className="user--badge-wrapper">
      <Tip content={tipContent}>
        <div
          {...props}
          className="applicant-header--individual-consultation-process user--badge"
        >
          {I18n.t('js.users.icon_badges.individual_consultation_process.icon')}
        </div>
      </Tip>
    </div>
  )
}

function SmartiConsultationIcon(props) {
  const { onClick } = props

  function handleClick(e) {
    e.stopPropagation()
    e.preventDefault()

    onClick()
  }

  return (
    <div
      className="user--badge-wrapper -flex-"
      onClick={handleClick}
    >
      <div
        className="applicant-header--smarti-consultation-process user--badge"
      >
        {I18n.t('js.users.icon_badges.smarti_consultation.icon')}
      </div>
    </div>
  )
}


class MilestoneIcon extends PureComponent {
  handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    this.props.onClick()
  }

  render() {
    const { milestone } = this.props

    const resultingClassName = cn(
      "applicant-header--milestone user--badge",
      getMilestoneIconColor(milestone)
    )

    return (
      <div
        className="user--badge-wrapper"
        onClick={this.handleClick}
      >
        <div className={resultingClassName} >
          {milestone}
        </div>
      </div>
    )
  }
}

function IndividualLocaleIcon(props) {
  const { locale, onClick } = props

  function handleClick(e) {
    e.stopPropagation()
    e.preventDefault()

    onClick()
  }

  return (
    <div
      className="user--badge-wrapper"
      onClick={handleClick}
    >
      <div className="applicant-header--individual-locale user--badge">
        {locale}
      </div>
    </div>
  )
}

function QualificationIcon(props) {
  const {
    qualifiedStep, qualificationLastLog, isOrange, onClick,
    ...restProps
  } = props

  const className = cn(
    'applicant-header--qualified-step user--badge',
    getQualifiedStepIconColor(qualifiedStep, isOrange)
  )

  function handleClick(e) {
    e.stopPropagation()
    e.preventDefault()

    onClick()
  }

  return (
    <div className="user--badge-wrapper">
      <Tip enabled={!!qualificationLastLog} content={qualificationLastLog}>
        <div
          className={className}
          onClick={handleClick}
          {...restProps}
        >
          {qualifiedStep}
        </div>
      </Tip>
    </div>
  )
}

function SalesOpportunityIcon() {
  return (
    <div className="user--badge-wrapper">
      <Tip
        content={I18n.t('js.users.icon_badges.search_blocked.tip')}
      >
        <i className="icon-sales-opportunity applicant--icon" />
      </Tip>
    </div>
  )
}

function LoginBlockedIcon() {
  return (
    <div className="user--badge-wrapper">
      <Tip
        content={I18n.t('js.users.icon_badges.login_blocked.tip')}
      >
        <i className="icon-login-blocked applicant--icon" />
      </Tip>
    </div>
  )
}

function SearchBlockedIcon() {
  return (
    <div className="user--badge-wrapper">
      <Tip
        content={I18n.t('js.users.icon_badges.search_blocked.tip')}
      >
        <i className="icon-search-blocked applicant--icon"></i>
      </Tip>
    </div>
  )
}

function CustomerRatingIcon(props) {
  const { customerRating, onClick } = props

  function handleClick(e) {
    e.stopPropagation()
    e.preventDefault()

    onClick()
  }

  return (
    <div className="user--badge-wrapper">
      <div
        className={`applicant-header--customer-rating user--badge -${customerRating.toLowerCase()}`}
        onClick={handleClick}
      >
        {customerRating}
      </div>
    </div>
  )
}

export default class UserBadges extends Component {
  render() {
    const {
      user, onUserEditBadgeClick, onUserEditSeachProfileBadgeClick
    } = this.props

    const {
      milestone, locale, lead_priority, confirmation_status,
      individual_consultation_process, smartinvest_consultation,
      qualified_step, show_orange_qualified_icon, qualification_last_log,
      search_profile_qualified, login_blocked, search_blocked, customer_rating,
      sales_opportunity_exist
    } = user

    return (
      <div className="user--badges-icons">
        {lead_priority && <HotLeadIcon leadPriority={lead_priority} />}
        {confirmation_status && <ConfirmationProcessIcon confirmationStatus={confirmation_status} />}
        {smartinvest_consultation && (
          <SmartiConsultationIcon
            userId={user.id}
            onClick={() => onUserEditBadgeClick(user.id)}
          />
        )}
        {individual_consultation_process && (
          <IndividualConsultationProcessIcon
            onClick={() => onUserEditBadgeClick(user.id)}
          />
        )}
        {milestone && (
          <MilestoneIcon
            milestone={milestone}
            userId={user.id}
            onClick={() => onUserEditBadgeClick(user.id)}
          />
        )}
        {customer_rating && (
          <CustomerRatingIcon
            customerRating={customer_rating}
            userId={user.id}
            onClick={() => onUserEditBadgeClick(user.id)}
          />
        )}
        {locale && (
          <IndividualLocaleIcon
            locale={locale}
            userId={user.id}
            onClick={() => onUserEditBadgeClick(user.id)}
          />
        )}
        {qualified_step && (
          <QualificationIcon
            isOrange={show_orange_qualified_icon}
            qualifiedStep={qualified_step}
            qualificationLastLog={qualification_last_log}
            searchProfileQualified={search_profile_qualified}
            userId={user.id}
            onClick={() => onUserEditSeachProfileBadgeClick(user.id)}
          />
        )}
        {login_blocked && <LoginBlockedIcon />}
        {search_blocked && <SearchBlockedIcon />}
        {sales_opportunity_exist && <SalesOpportunityIcon />}
      </div>
    )
  }
}

