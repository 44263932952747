import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "68%", 
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row"
  },
  tableRowDarkBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#b4b7bc",
    color: "#333",
    fontWeight: "bold",
    fontSize: 14
  },
  tableRowLightBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#ececec",
    color: "#333",
    fontSize: 10
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "5px"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5
  },
  tableCellLargeFont: {
    margin: "auto",
    marginTop: 5,
  },
  tableColHeading:{
    display: 'table-cell',  
    width: '100%',
    textAlign: 'left',
    padding: "5px"
  },
  tableCol25: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "5px"
  }
});

const ObjectInfoTable1Left = (props) =>{
  return(
    <View style={styles.table}>
      <View style={styles.tableRowDarkBG}>
        <View style={styles.tableColHeading}>
          <Text style={styles.tableCellHeading}>
            Objektdaten
          </Text>
        </View>
      </View>
      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Anschrift: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.street} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Größe: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.living_space} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Plz / Ort: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.restricted_address} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Zimmer / Bäder: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.number_of_bedrooms} / {props.listing_info.number_of_bathrooms} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Etage / Lage: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.floor_number} / {props.listing_info.total_floors} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> Kaufpreis*: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.purchase_price} </Text>
        </View>
      </View>

      <View style={styles.tableRowLightBG}>
        <View style={styles.tableCol25}>
          <Text> Baujahr: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.building_year} </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> *Angebotspreis: </Text>
        </View>
        <View style={styles.tableCol25}>
          <Text> {props.listing_info.instant_purchase_price} </Text>
        </View>
      </View>

    </View>
  )
}
export default ObjectInfoTable1Left;
