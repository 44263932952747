// TODO: rename FilterHistory -> HistoryWithFilter
export default class FilterHistory {
  static push(data) {
    const newState = `${location.pathname}?${this.serializeDataForState(data)}`
    history.pushState({}, null, newState)
  }

  static serializeDataForState(data) {
    return $.param(data)
  }

  static onNavigateBack(callback) {
    window.onpopstate = callback
  }

  static flushCallback() {
    window.onpopstate = null
  }
}
