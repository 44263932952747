/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react'
import I18n from 'i18n-js/index.js.erb'
import {
  Text
} from '@react-pdf/renderer';

export default class FactsAndFigures extends PureComponent {

  prepareValue(value) {
    return !value ? 0 : parseFloat(value).toFixed(1)
  }

  render() {
    //Currency format without the currency symbol
    const currencyFormatNoCurrency = new Intl.NumberFormat((this.props.current_user_locale),
      { currency: 'EUR' });

    //Currency format without decimal format
    const currencyFormatNoDecimal = new Intl.NumberFormat((this.props.current_user_locale),
      {
        currency: "EUR",
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })

    // Percentage format with only single decimal digit
    const currencyFormatPercentage = new Intl.NumberFormat((this.props.current_user_locale),
      {
        style: "percent", minimumFractionDigits: 1,
        maximumFractionDigits: 1
      })

    const factor = this.prepareValue(this.props.factor);
    const net_yield = this.prepareValue(this.props.net_yield);
    const grossYield = parseFloat(this.props.gross_yield).toFixed(2).slice(0,-1) ;
    const predicted_return_on_equity_after_sale_PA = this.prepareValue(this.props.predicted_return_on_equity_after_sale_PA);
    const predictedCapitalAppreciationForSale = this.props.predicted_capital_appreciation_for_sale || 0
    const downPayment = this.props.down_payment || 0

    const tildeClass = this.props.rentedOut ? '' : 'text-with-tilde'
    return (
      <Text className='FactsAndFigures'>
        <Text className='box-primary--title -secondary'>
          <Text className='info-tip--row'>
            <Text> {I18n.t('js.investment_calculations.facts_figures.facts_and_figure')} </Text>
            <Text className='info-tip -secondary display-right'>
              <Text className='info-tip--content -width-400'>
                {I18n.t('js.investment_calculations.facts_figures.notes')}
                {I18n.t('js.investment_calculations.facts_figures.calculation_indicator')}:
                <Text className='formula'>
                  <Text className='formula--body'>
                    <Text className='formula--left'>
                      <Text>
                        {I18n.t('js.investment_calculations.facts_figures.gross_yield')} =
                      </Text>
                    </Text>
                    <Text className='formula--right'>
                      <Text className='top'>
                        12 x {I18n.t('js.investment_calculations.facts_figures.monthly_cold_rent')}
                      </Text>
                      <Text className='bottom'>
                        {I18n.t('js.investment_calculations.facts_figures.purchase_price')}
                      </Text>
                    </Text>
                  </Text>
                </Text>
                <Text className='formula'>
                  <Text className='formula--body'>
                    <Text className='formula--left -top-10'>
                      <Text>
                        {I18n.t('js.investment_calculations.facts_figures.net_yield')} =
                      </Text>
                    </Text>
                    <Text className='formula--right'>
                      <Text className='top'>
                        12 x ({I18n.t('js.investment_calculations.facts_figures.monthly_cold_rent')} - {I18n.t('js.investment_calculations.facts_figures.reserve_payment')} - {I18n.t('js.investment_calculations.property_results.non_allocable_operating_cost')} - {I18n.t('js.investment_calculations.facts_figures.rental_loss')} - {I18n.t('js.investment_calculations.property_results.special_property_management')})
                      </Text>
                      <Text className='bottom'>
                        {I18n.t('js.investment_calculations.facts_figures.purchase_price')}  + {I18n.t('js.investment_calculations.facts_figures.additional_purchasing_costs')}
                      </Text>
                    </Text>
                  </Text>
                </Text>
                <Text className='formula'>
                  <Text className='formula--body'>
                    <Text className='formula--left'>
                      <Text>
                        {I18n.t('js.investment_calculations.facts_figures.factor')} =
                      </Text>
                    </Text>
                    <Text className='formula--right'>
                      <Text className='top'>
                        {I18n.t('js.investment_calculations.facts_figures.purchase_price')}
                      </Text>
                      <Text className='bottom'>
                        12 x {I18n.t('js.investment_calculations.facts_figures.monthly_cold_rent')}
                      </Text>
                    </Text>
                  </Text>
                </Text>
              </Text>
            </Text>
          </Text>
        </Text>
        <Text className="calculations-table--row head">
          <Text className="calculations-table--col">
            <Text className="calculations-table--title">
              <Text className={`js-gross-yield ${tildeClass}`}>
                {currencyFormatNoCurrency.format((!factor || isNaN(factor)) ? 0 : factor)}
              </Text>
            </Text>
            <Text className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.factor')}
            </Text>
          </Text>
          <Text className="calculations-table--col">
            <Text className="calculations-table--title">
              <Text className={`js-net-yield ${tildeClass}`}>
                {Intl.NumberFormat(I18n.locale, { style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 2 }).format( (grossYield / 100))}
              </Text>
            </Text>
            <Text className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.gross_yield')}
            </Text>
          </Text>
          <Text className="calculations-table--col">
            <Text className="calculations-table--title">
              <Text className={`js-factor ${tildeClass}`}>
                {currencyFormatPercentage.format(net_yield / 100)}
              </Text>
            </Text>
            <Text className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.net_yield')}
            </Text>
          </Text>
        </Text>
        <Text className="calculations-table--row head secondRow">
          <Text className="calculations-table--col">
            <Text className="calculations-table--title">
              <Text className={`js-net-yield ${tildeClass}`}>
                {currencyFormatNoDecimal.format(downPayment)}
              </Text>
            </Text>
            <Text className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.down_payment')}
            </Text>
          </Text>
          <Text className="calculations-table--col">
            <Text className="calculations-table--title">
              <Text className={`js-factor ${tildeClass}`}>
                {currencyFormatNoDecimal.format(predictedCapitalAppreciationForSale)}
              </Text>
            </Text>
            <Text className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.projected_wealth')}
            </Text>
          </Text>
          <Text className="calculations-table--col">
            <Text className="calculations-table--title">
              <Text className={`js-gross-yield ${tildeClass}`}>
                {currencyFormatPercentage.format(predicted_return_on_equity_after_sale_PA / 100)}
              </Text>
            </Text>
            <Text className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.average_equity_yield')}
            </Text>
          </Text>
        </Text>
      </Text>
    )
  }
}
