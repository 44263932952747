import React, { PureComponent } from 'react'
import {
  Slider,
  SliderTrack,
  SliderMarker,
  SliderTrackHighlight,
  SliderHandle
} from "@reach/slider";
import "@reach/slider/styles.css";


export default class SliderInput extends PureComponent {
  static defultProps = {
    labelText: null,
    displayTopCaptionFunc: null,
    displayCaptionFunc: null
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { labelText, name, min, max, step, value, markerValue } = nextProps
  //   const prevProps = this.props
  //
  //   return (
  //     nextState.value !== nextProps.value ||
  //     value !== prevProps.value ||
  //     markerValue !== prevProps.markerValue ||
  //     labelText !== prevProps.labelText ||
  //     name !== prevProps.name ||
  //     min !== prevProps.min ||
  //     max !== prevProps.max ||
  //     step !== prevProps.step
  //   )
  // }

  render() {
    const {
      labelText, name, min, max, step, value, markerValue,
      displayTopCaptionFunc, displayCaptionFunc,
      onClick, onChange
    } = this.props

    return (
      <div className="slider-input">
        {labelText && (
          <span className="left">
            {labelText}
          </span>
        )}
        {displayTopCaptionFunc && (
          <span className="right">
            {displayTopCaptionFunc(value)}
          </span>
        )}
        <Slider
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          onClick={onClick}
        >
          <SliderTrack>
            <SliderTrackHighlight />
            <SliderMarker value={markerValue} />

            {displayCaptionFunc && (
              <SliderHandle>
                <span className="slider--val">
                  {displayCaptionFunc(value)}
                </span>
              </SliderHandle>
            )}
          </SliderTrack>
        </Slider>
      </div>
    )
  }
}
