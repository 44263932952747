import React, { PureComponent } from 'react'
import SliderInput from 'uiKit/inputs/SliderInput/SliderInput'
import debounce from 'lodash.debounce';


export default class DebounceSliderInput extends PureComponent {
  static defaultProps = {
    debounceTimeout: 250,
    value: null,
    displayTopCaptionFunc: null,
    displayCaptionFunc: null
  }

  state = {
    value: this.props.value
  }

  isDebouncing = false

  constructor(props) {
    super(props)

    const { debounceTimeout } = this.props
    this.createNotifier(debounceTimeout)
  }

  componentWillUnmount() {
    if (this.flush) {
      this.flush()
    }
  }

  componentDidUpdate(prevProps) {
    // If in debouncing state - then dont trigger rerender
    if (this.isDebouncing) {
      return
    }

    const { value, debounceTimeout } = this.props;
    const {
      debounceTimeout: oldTimeout,
      value: oldValue
    } = prevProps

    const { value: stateValue } = this.state

    const isValueChanged = (
      typeof value !== 'undefined' && oldValue !== value && stateValue !== value
    )

    if (isValueChanged) {
      // Update state.value if new value passed via props, yep re-render should happen
      this.setState({ value })
    }

    // Check if debounceTimeout is changed
    if (debounceTimeout !== oldTimeout) {
      // If changed create new debounce notifier
      this.createNotifier(debounceTimeout)
    }
  }

  handleChange = (value) => {
    this.setState({ value }, () => {
      this.debouncedHandleChangeCallback(value)
    })
  }

  // Creates debounce notifier
  createNotifier = (debounceTimeout) => {
    if (debounceTimeout < 0) {
      this.debouncedHandleChangeCallback = () => null

    } else if (debounceTimeout === 0) {
      this.debouncedHandleChangeCallback = this.props.onChange

    } else {
      const debouncedChangeFunc = debounce(
        (value) => {
          this.isDebouncing = false
          this.props.onChange(value)
        },
        debounceTimeout
      )

      this.debouncedHandleChangeCallback = (value) => {
        this.isDebouncing = true
        debouncedChangeFunc(value)
      };

      this.flush = () => debouncedChangeFunc.flush()

      this.cancel = () => {
        this.isDebouncing = false
        debouncedChangeFunc.cancel()
      };
    }
  };

  render() {
    return (
      <SliderInput
        {...this.props}
        value={this.state.value}
        onChange={this.handleChange}
      />
    )
  }
}
