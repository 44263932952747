import React from 'react';
import { headerSeparator } from 'commonComponents/common';
import styles from './SectionHeader.module.scss';

const SectionHeader = ({
  title,
  subtitle,
  anchor,
  isPrime
}) => {
  const classes = `${styles.SectionHeader} ${isPrime ? styles.prime : ''}`;

  return (
    <div className={ classes }>
      <a name={ anchor }></a>
      <h2>{ title }</h2>
      { headerSeparator }
      { subtitle && <h3>{ subtitle }</h3> }
    </div>
  );
};
 
export default SectionHeader;