import React from 'react';
import image from '../../../../assets/images/icons/green-mail.svg';
import 'i18n-js/setupTranslations.js.erb';
import Header from '../../Login/Header/Header';

const ResetPasswordSuccess = () => {
  const header = I18n.t('js.reset_page.header');
  const subHeader = I18n.t('js.reset_page.sub_header');
  const headerProps = { header, subHeader };

  return (
    <div>
      <Header { ...headerProps } />
      <div className="text-center">
        <img src={ image } className="animated light-speed-in"
          alt="mail"
        />
        <p className="-mb-double">{ I18n.t('js.reset_page.info') }</p>
      </div>
      <button
        onClick={ () => location.href = '/login' }
        name="button"
        type="button"
        className="button full-width -secondary -big -tc-"
      >
        { I18n.t('js.reset_page.button') }
      </button>
    </div>
  );
};

export default ResetPasswordSuccess;
