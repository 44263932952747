/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';
import Assumptions from '../Assumptions';

export default class PropertyResults extends PureComponent {
  prepareValue(value) {
    return !value ? 0 : parseFloat(value);
  }

  renderRows() {
    const { first_annual_repayment } = this.props;
    const coldRent = this.prepareValue(this.props.cold_rent);
    const lossOfRentalIncome = this.prepareValue(this.props.lossOfRentalIncome);
    const lossOfRentalIncomeConst = this.prepareValue(this.props.loss_of_rental_income_const);
    const notAllocableOperatingCosts = this.prepareValue(this.props.not_allocable_operating_costs);
    const monthlyReservePayment = this.prepareValue(this.props.monthly_reserve_payment);
    const specialPropertyManagement = this.prepareValue(this.props.special_property_management);
    const extraOrdinaryExpenses = this.prepareValue(this.props.extraordinary_expenses);
    const assumedRentalIncomeFirst = this.prepareValue(this.props.assumed_rental_income_first);
    const calculatedTaxes = this.prepareValue(this.props.calculated_taxes);
    const firstInterestCost = this.prepareValue(this.props.first_interest_cost);

    const property_cashflow = coldRent - ( lossOfRentalIncomeConst / 12 +
                                           notAllocableOperatingCosts +
                                           monthlyReservePayment +
                                           specialPropertyManagement +
                                           extraOrdinaryExpenses )

    const cashflow_without_repayment = property_cashflow - (calculatedTaxes / 12 + firstInterestCost / 12);

    const cashflow_with_repayment = cashflow_without_repayment +
      (!first_annual_repayment ? 0 : - first_annual_repayment / 12);

    const redemption = !first_annual_repayment ? 0 : - first_annual_repayment / 12;

    return [
      {
        lable:        "cold_rent",
        name:         I18n.t('js.investment_calculations.property_results.cold_rent'),
        value:        coldRent,
        value_yearly: 12 * coldRent
      },
      {
        lable:        "Rent_loss",
        name:         I18n.t('js.investment_calculations.complete_investment_table.loss_of_rental_income_percent', { value: this.props.currencyFormatPercentage(lossOfRentalIncome) }),
        value:        - lossOfRentalIncomeConst / 12,
        value_yearly: - lossOfRentalIncomeConst
      },
      {
        lable:        "assumed_rental_income_first",
        name:         I18n.t('js.investment_calculations.property_results.assumed_rental_income_first'),
        value:        assumedRentalIncomeFirst / 12,
        value_yearly: assumedRentalIncomeFirst
      },
      {
        lable:        "Non_Allocable_Operating_Cost",
        name:         I18n.t('js.investment_calculations.property_results.non_allocable_operating_cost'),
        value:        - notAllocableOperatingCosts,
        value_yearly: -12 * notAllocableOperatingCosts
      },
      {
        lable:        "maintainence_reserve_payment",
        name:         I18n.t('js.investment_calculations.property_results.maintainence_reserve_payment'),
        value:        - monthlyReservePayment,
        value_yearly: -12 * monthlyReservePayment
      },
      {
        lable:        "Special_Property_Management",
        name:         I18n.t('js.investment_calculations.property_results.special_property_management'),
        value:        - specialPropertyManagement,
        value_yearly: -12 * specialPropertyManagement
      },
      {
        lable:        "Extraordinary_Expenses",
        name:         I18n.t('js.investment_calculations.property_results.additional_cost'),
        value:        - extraOrdinaryExpenses,
        value_yearly: -12 * extraOrdinaryExpenses
      },
      {
        lable:        "property_cashflow",
        name:         I18n.t('js.investment_calculations.property_results.property_cashflow'),
        value:        property_cashflow,
        value_yearly: 12 * property_cashflow
      },
      {
        lable:        "Income_Tax",
        name:         I18n.t('js.investment_calculations.property_results.income_tax'),
        value:        - calculatedTaxes / 12,
        value_yearly: - calculatedTaxes
      },
      {
        lable:        "Interest_Expense",
        name:         I18n.t('js.investment_calculations.property_results.interest_cost'),
        value:        - firstInterestCost / 12,
        value_yearly: - firstInterestCost
      },
      {
        lable:        "cashflow_without_repayment",
        name:         I18n.t('js.investment_calculations.property_results.cashflow_without_repayment'),
        value:        cashflow_without_repayment,
        value_yearly: 12 * cashflow_without_repayment
      },
      {
        lable:        "Redemption",
        name:         I18n.t('js.investment_calculations.property_results.repayment'),
        value:        redemption,
        value_yearly: 12 * redemption
      },
      {
        lable:        "Cashflow_with_Repayment",
        name:         I18n.t('js.investment_calculations.property_results.cashflow_with_repayment'),
        value:        cashflow_with_repayment,
        value_yearly: 12 * cashflow_with_repayment
      }
    ]
  }

  render() {
    const AvatarListArray = this.renderRows();

    return (
      <div className='Rent_Output'>
        <div className="details">
          <dl className="bold box-primary--row">
            <dt></dt>
            <dd className="global-heading -tr- -hsd-">
              {I18n.t('js.investment_calculations.property_results.per_year')}
            </dd>
            <dd className="global-heading -tr- -hsd-">
              {I18n.t('js.investment_calculations.property_results.per_month')}
            </dd>
          </dl>
          {AvatarListArray.map((data, key) => {
            return (
              <dl className={[data.lable, "box-primary--row"].join(' ')} key={key}>
                <dt>{data.name}</dt>
                <dd className="-tr- -hsd-">
                  {this.props.formatNumber(data.value_yearly)}
                </dd>
                <dd className="-tr- -hsd-">
                  {this.props.formatNumber(data.value)}
                </dd>
              </dl>
            )
          })}
        </div>
        <Assumptions
          currencyFormatPercentage={this.props.currencyFormatPercentage}
          taxes={this.props.taxes}
          shareOfBuilding={this.props.shareOfBuilding}
          perYearDepreciation={this.props.perYearDepreciation}
          interestRate={this.props.interestRate}
          repaymentRate={this.props.repaymentRate}
          lossOfRentalIncome={this.props.lossOfRentalIncome}
        />
      </div>
    )
  }
}
