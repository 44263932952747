import React from 'react';
import Field from 'commonComponents/forms/Field/Field';

const TextField = ({
  name,
  label,
  defaultValue,
  type,
  maxLength = 40,
  size = 40,
  mainName = 'lead'
}) => {
  const fieldProps = {
    name: `${mainName}[${name}]`,
    type,
    label,
    maxLength,
    size,
    defaultValue
  };
  
  return (
    <Field { ...fieldProps } />
  );
};
 
export default TextField;
