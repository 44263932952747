import React from 'react'
import PropTypes from 'prop-types'
import './Tip.scss'

export default class Tip extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  }

  static defaultProps = {
    enabled: true
  }

  // NOTE We need to store about custom scroll and currentTip
  // between all instancces of FixedTipContainer react component
  // NOTE static properties access is possible by using
  // "this.constructor.propertyName" syntax
  state = {
    top: null,
    left: null,
    show: false
  }

  mainRef = React.createRef()
  tipRef = React.createRef()

  onMouseEnter = () => {
    this.showTip()
  }

  onMouseLeave = () => {
    this.setState({ show: false })
  }

  showTip() {
    const { top, left } = this.getTipPosition()

    this.setState({ top, left }, () => {
      this.setState({ show: true })
    })
  }

  getTipPosition() {
    const wrappedElement = this.mainRef.current
    const tipRect = wrappedElement.getBoundingClientRect()
    const top = tipRect.top - this.tipRef.current.scrollHeight
    const additionalLeftOffset = (wrappedElement.clientWidth / 2)
    const left = tipRect.left + additionalLeftOffset

    return { top, left }
  }

  getCurrentStyle() {
    const { enabled } = this.props
    const { top, left, show } = this.state

    const isVisible = show && enabled

    return {
      top: `${top}px`,
      left: `${left}px`,
      visibility: isVisible ? 'visible' : 'hidden',
      opacity: isVisible ? 1 : 0
    }
  }

  render() {
    const { children, content } = this.props

    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.mainRef}
      >
        {children}
        <div
          style={this.getCurrentStyle()}
          className="new-tip"
          ref={this.tipRef}
        >
          <div className="small-tip--inner text-regular">
            {content}
          </div>
        </div>
      </div>
    )
  }
}
