/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import FactsAndFigures from '../InvestmentCalculationComponents/FactsAndFigure/FactsAndFigures';
import PropertyResults from '../InvestmentCalculationComponents/PropertyResults/PropertyResults';
import Graph from '../InvestmentCalculationComponents/InvestmentGraph/Graph';
import Projection from '../InvestmentCalculationComponents/InvestmentProjection/Projection';
import FinancePlan from '../InvestmentCalculationComponents/FinancingPlan/FinancePlan';
import ManageAssumptions from '../InvestmentCalculationComponents/ManageAssumptions/ManageAssumptions';
import EditInvestmentCalculation from '../InvestmentCalculationComponents/EditInvestmentCalculation/EditInvestmentCalculation';
import CompleteInvestmentCalculation from './CompleteInvestmentCalculation';
import annualGrossIncomeData from '../InvestmentCalculationContainer/annual_gross_income.json';
import {
  calculateMortgageValue
} from './subcontainers/calculations';
import I18n from 'i18n-js/index.js.erb';
import calculateFinances from './calculations/calculateFinances';
import calculateInvestment from './calculations/calculateInvestment';
import DocumentTrial from './DocumentTrial';

export default class index extends PureComponent {
  DEFAULT_DECIMAL_DIGITS_COUNT = 2
  CURRENCY = 'EUR'

  locales = {
    en: 'en-US',
    de: 'de-DE'
  }

  constructor(props) {
    super(props);

    this.state = {
      commission_percentage_value: parseFloat(props.commission_value * 100 / props.user_purchase_price).toFixed(2),
      default_commission_percentage_value: props.default_commission_value * 100 / props.const_purchase_price,
      default_cold_rent: parseFloat(props.rented_out ? props.default_cold_rent : props.market_cold_rent),
      loss_of_rental_income: !props.loss_of_rental_income ? 0 : parseFloat(props.loss_of_rental_income),
      monthly_reserve_payment: !props.monthly_reserve_payment ? 0 : parseFloat(props.monthly_reserve_payment),
      special_property_management: !props.special_property_management ? 0 : parseFloat(props.special_property_management),
      renovation_cost: !props.renovation_cost ? 0 : parseFloat(props.renovation_cost),
      additional_cost: !props.additional_costs ? 0 : parseFloat(props.additional_costs),
      cold_rent_15: parseFloat(props.default_cold_rent * 1.15).toFixed(2),
      commission_value: parseFloat(props.commission_value).toFixed(2),
      user_purchase_price: parseFloat(props.user_purchase_price).toFixed(2),
      const_loan_amount: parseFloat(props.user_purchase_price),
      annual_repayment: parseFloat(props.annual_repayment),
      const_interest_rate: parseFloat(props.default_interest_rate),
      interest_rate: parseFloat(props.interest_rate),
      const_repayment_rate: parseFloat(props.default_repayment_rate),
      loan_amount: parseFloat(props.user_purchase_price),
      repayment_rate: parseFloat(props.repayment_rate),
      direct_purchase_price: parseFloat(props.direct_purchase_price),
      const_purchase_price: parseFloat(props.const_purchase_price),
      custom_user_purchase_price: parseFloat(props.user_purchase_price),
      custom_cold_rent: parseFloat(props.cold_rent),
      mortgage: parseFloat(props.mortgage),
      market_price: parseFloat(props.market_cold_rent),
      comparable_cold_rent: parseFloat(props.comparable_cold_rent),
      const_cold_rent: parseFloat(props.cold_rent),
      cold_rent: parseFloat(props.cold_rent),
      living_space: parseFloat(props.living_space),
      not_allocable_operating_costs: parseFloat(props.not_allocable_operating_costs),
      rent_increase: parseFloat(props.rent_increase),
      rent_increase_after_year: parseFloat(props.rent_increase_after_year),
      building_appreciation: parseFloat(props.building_appreciation),
      building_appreciation_after_year: parseFloat(props.building_appreciation_after_year),
      share_of_building: parseFloat(props.share_of_building),
      capital_employed: parseFloat(props.capital_employed),
      land_transfer_cost: parseFloat(props.land_transfer_cost),
      notary_cost: parseFloat(props.notary_cost),
      land_registry_cost: parseFloat(props.land_registry_cost),
      extraordinary_expenses: parseFloat(props.extraordinary_expenses),
      annual_gross_income: parseFloat(props.annual_gross_income),
      listing_id: props.listing_id,
      rentedOut: props.rented_out,
      final_second_list: [],
      rent_selected_option: props.rented_out ? 'Current' : 'Market',
      price_selected_option: 'user_purchase_price',
      backdrop: 'static',
      per_year_depreciation: props.building_year >= 1925 || !props.building_year ? 2 : 2.5,
      number_of_years: 51,
      rangeVal: 5,
      rental_objects: 0,
      real_cost_of_purchasing: 0,
      const_real_cost_of_purchasing: 0,
      new_real_cost_of_purchasing: 0,
      down_payment: 0,
      total_cost_of_purchasing: 0,
      tax: 0,
      predicted_return_on_equity_after_sale_PA: 0,
      predicted_capital_appreciation_for_sale: 0,
      calculated_taxes: 0,
      mortgage_value: '',
      final_list: '',
      equity: '',
      total_not_allocable_operating_cost: '',
      depreciation: '',
      loss_of_rental_income_const: '',
      return_on_equity_after_sale_PA: '',
      capital_appreciation_for_sale: '',
      factor: '',
      gross_yield: '',
      net_yield: '',
      predicted_equity_from_cashflow: '',
      taxes: '',
      const_taxes: '',
      real_cost_of_purchasing_slider: '',
      assumed_rental_income_first: '',
      total_taxable_not_allocable_operating_costs: '',
      isChecked: false,
      show_edit_financing_plan: false,
      show_complete_investment_calculation: false,
      error_message_manage_assumption: false,
      keyboard: false,
      error_message_general_assumptions: false,
      error_message_financing: false,
      error_message_income_statement: false,
      error_message_purchasing_cost: false,
      error_message_purchasing_cost_empty: false
    };
    this.baseState = this.state
  }

  resetForm = () => {
    const { const_loan_amount, const_real_cost_of_purchasing, rentedOut } = this.state;

    const {
      commission_amount_value,
      commission_percentage_value,
      const_purchase_price,
      default_cold_rent,
      default_interest_rate,
      default_mortgage,
      default_renovation_cost,
      default_repayment_rate,
      market_cold_rent,
    } = this.props;

    this.setState({
      mortgage_value: calculateMortgageValue(default_mortgage, const_purchase_price),
      commission_percentage_value: (!commission_percentage_value ? 0 : parseFloat(commission_percentage_value).toFixed(2)),
      renovation_cost: !default_renovation_cost ? 0 : parseFloat(default_renovation_cost),
      cold_rent: parseFloat(rentedOut ? default_cold_rent : market_cold_rent),
      commission_value: parseFloat(commission_amount_value).toFixed(2),
      user_purchase_price: parseFloat(const_purchase_price),
      loan_amount: parseFloat(const_loan_amount),
      interest_rate: parseFloat(default_interest_rate),
      repayment_rate: parseFloat(default_repayment_rate),
      mortgage: parseFloat(default_mortgage),
      real_cost_of_purchasing: const_real_cost_of_purchasing,
      rent_selected_option: rentedOut ? 'Current' : 'Market',
      price_selected_option: 'user_purchase_price',
      new_real_cost_of_purchasing: '',
      real_cost_of_purchasing_slider: '',
      error_message_manage_assumption: false,
      error_message_purchasing_cost: false,
      error_message_purchasing_cost_empty: false
    }, () => {
      this.EditIncomeStatementFormResetError();
      this.financeCalcualte();
    });
  }

  EditPurchasingCostFormReset = () => {
    const {
      commission_amount_value,
      commission_percentage_value,
      const_purchase_price,
      default_renovation_cost
    } = this.props;

    this.setState({
      renovation_cost: !default_renovation_cost ? 0 : parseFloat(default_renovation_cost),
      commission_percentage_value: parseFloat(commission_percentage_value).toFixed(2),
      commission_value: parseFloat(commission_amount_value).toFixed(2),
      user_purchase_price: parseFloat(const_purchase_price),
      price_selected_option: 'user_purchase_price',
      error_message_purchasing_cost: false,
      error_message_purchasing_cost_empty: false
    }, () => {
      this.financeCalcualte();
    });
  }

  EditIncomeStatementFormReset = () => {
    const {
      default_cold_rent,
      default_extraordinary_expenses,
      default_loss_of_rental_income,
      default_monthly_reserve_payment,
      default_not_allocable_operating_costs,
      default_rent_increase,
      default_special_property_management,
      market_cold_rent,
      rented_out
    } = this.props;

    const defaultExtraordinaryExpenses = parseFloat(default_extraordinary_expenses);

    this.setState({
      cold_rent: parseFloat(rented_out ? default_cold_rent : market_cold_rent),
      rent_increase: parseFloat(default_rent_increase),
      not_allocable_operating_costs: parseFloat(default_not_allocable_operating_costs),
      monthly_reserve_payment: parseFloat(default_monthly_reserve_payment),
      special_property_management: parseFloat(default_special_property_management),
      loss_of_rental_income: parseFloat(default_loss_of_rental_income),
      additional_cost: defaultExtraordinaryExpenses,
      extraordinary_expenses: defaultExtraordinaryExpenses,
      rent_selected_option: rented_out ? 'Current' : 'Market',
      error_message_income_statement: false
    }, () => {
      this.financeCalcualte();
    });
  }

  EditFinancingFormReset = () => {
    const { user_purchase_price } = this.state;
    const { default_interest_rate, default_mortgage, default_repayment_rate } = this.props;

    this.setState({
      mortgage_value: parseFloat(default_mortgage) * user_purchase_price / 100,
      interest_rate: parseFloat(default_interest_rate).toFixed(2),
      repayment_rate: parseFloat(default_repayment_rate).toFixed(2),
      mortgage: parseFloat(default_mortgage),
      equity: 100,
      error_message_financing: false
    }, () => {
      this.financeCalcualte();
    });
  }

  EditGeneralAssumptionsFormReset = () => {
    this.setState({
      rent_increase: parseFloat(this.props.default_rent_increase),
      rent_increase_after_year: parseFloat(this.props.default_rent_increase_after_year),
      building_appreciation: parseFloat(this.props.default_building_appreciation),
      building_appreciation_after_year: parseFloat(this.props.default_building_appreciation_after_year),
      annual_repayment: parseFloat(this.props.default_annual_repayment),
      share_of_building: parseFloat(this.props.default_share_of_building),
      annual_gross_income: parseFloat(this.props.default_annual_gross_income),
      error_message_general_assumptions: false
    }, () => {
      this.filterTaxesFromAnnualGrossIncomeData()
      this.financeCalcualte();
    });
  }

  // If user slides the 'Purchase Price' slider - radio button switches to Custom button and Reset 'Closing Cost & Misc.' slider
  updatepriceSelectedOption = () => {
    let error_message_purchasing_cost_empty = true;
    const userPurchasePrice = parseFloat(this.state.user_purchase_price);
    const price_selected_option = this.priceSelectedOption(userPurchasePrice);

    if (userPurchasePrice != 0) error_message_purchasing_cost_empty = false;

    this.setState({
      price_selected_option,
      error_message_purchasing_cost_empty
    });
  }

  // If user slides the 'Monthly Rent' slider - radio button switches to Custom button
  updateColdRentSelectedOption = () => {
    const rent_selected_option = this.rentSelectedOption(this.state.cold_rent);

    this.setState({
      rent_selected_option
    });
  }

  // When user selects the radio button in 'Purchase Price' this function set the new 'Purchase Price' and recalculates everything
  handleOptionChangePrice = (changeEvent) => {
    changeEvent.persist();

    this.setState({
      price_selected_option: changeEvent.target.id,
      error_message_purchasing_cost_empty: false
    }, () => {
      this.handleChange(changeEvent.target.name, changeEvent.target.value);
    });
  }

  // When user selects the radio button in 'Monthly Rent' this function set the new 'Rent' and recalculates everything
  handleOptionChangeRent = (changeEvent) => {
    changeEvent.persist();

    this.setState({
      rent_selected_option: changeEvent.target.id
    }, () => {
      this.handleChange(changeEvent.target.name, changeEvent.target.value)
    });
  }

  // Manages the checkbox of the 'Show & Edit detailed financing plan' modal
  handleChangeCheckbox = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }));
  }

  // Sends Data of the 'Show & Edit detailed financing plan' modal form to the backend
  saveFormInfo = () => {
    if (!this.state.error_message_purchasing_cost_empty) {
      document.getElementById('modalForm').submit();
    }
  }

  notEqual = (stateElement, propsElement) => {
    return this.roundingFunction(stateElement) != this.roundingFunction(propsElement);
  }

  EditGeneralAssumptionsFormResetError = () => {
    const error_message_general_assumptions =
      this.notEqual(this.state.rent_increase_after_year, this.props.default_rent_increase_after_year) ||
      this.notEqual(this.state.rent_increase, this.props.default_rent_increase) ||
      this.notEqual(this.state.building_appreciation, this.props.default_building_appreciation) ||
      this.notEqual(this.state.building_appreciation_after_year, this.props.default_building_appreciation_after_year) ||
      this.notEqual(this.state.annual_repayment, this.props.default_annual_repayment) ||
      this.notEqual(this.state.share_of_building, this.props.default_share_of_building) ||
      this.notEqual(this.state.annual_gross_income, this.props.default_annual_gross_income);

    this.setState({ error_message_general_assumptions });
  }

  EditFinancingFormResetError = () => {
    const error_message_financing =
      this.notEqual(this.state.mortgage, this.props.default_mortgage) ||
      this.notEqual(this.state.interest_rate, this.props.default_interest_rate) ||
      this.notEqual(this.state.repayment_rate, this.props.default_repayment_rate);

    this.setState({ error_message_financing });
  }

  EditIncomeStatementFormResetError = () => {
    const error_message_income_statement =
      this.notEqual(this.state.cold_rent, this.props.default_cold_rent) ||
      this.notEqual(this.state.loss_of_rental_income, this.props.default_loss_of_rental_income) ||
      this.notEqual(this.state.not_allocable_operating_costs, this.props.default_not_allocable_operating_costs) ||
      this.notEqual(this.state.monthly_reserve_payment, this.props.default_monthly_reserve_payment) ||
      this.notEqual(this.state.special_property_management, this.props.default_special_property_management) ||
      this.notEqual(this.state.extraordinary_expenses, this.props.default_extraordinary_expenses);

    this.setState({ error_message_income_statement });
  }

  EditPurchasingCostFormResetError = () => {
    const error_message_purchasing_cost =
      this.notEqual(this.state.user_purchase_price, this.props.const_purchase_price) ||
      this.notEqual(this.state.commission_percentage_value, this.props.default_commission_value) ||
      this.notEqual(this.state.renovation_cost, this.props.default_renovation_cost) ||
      this.notEqual(this.state.commission_value, this.props.commission_amount_value);

    this.setState({ error_message_purchasing_cost });
  }


  prepareValue(value) {
    let formattedValue = value;

    if (typeof (formattedValue) == 'number') {
      return formattedValue;
    }

    if (I18n.locale == 'en') {
      formattedValue = value.replace(/,/g, '');
    } else {
      formattedValue = value.replace(/\./g, '');
      formattedValue = formattedValue.replace(',', '.');
    }

    return this.roundingFunction(formattedValue);
  }

  // Takes the name and value of each input tag that changes its value
  handleChange = (name, value) => {
    const { commission_percentage_value, mortgage, user_purchase_price } = this.state;
    const preparedValue = this.prepareValue(value);

    // If the input field is 'mortgage' then it should calculate the
    // 'mortgage value' based on the input perentage and set into state
    if (name == 'mortgage') {
      this.setState({
        [name]: preparedValue,
        isChecked: false,
        mortgage_value: !preparedValue || !user_purchase_price ? 0 : preparedValue * user_purchase_price / 100
      }, () => {
        this.financeCalcualte();
        this.EditFinancingFormResetError();
      });
    } else if (name == 'renovation_cost') {
      this.setState({
        [name]: preparedValue,
        real_cost_of_purchasing: preparedValue,
        isChecked: false,
        new_real_cost_of_purchasing: ''
      }, () => {
        this.financeCalcualte();
        this.EditPurchasingCostFormResetError();
      });
    } else if (['radio_direct_purchase_price', 'radio_user_purchase_price'].includes(name)) {
      this.setState({
        user_purchase_price: preparedValue,
        isChecked: false,
        new_real_cost_of_purchasing: '',
        real_cost_of_purchasing: (value),
        commission_value: parseFloat(commission_percentage_value * value / 100).toFixed(2),
        mortgage_value: value * mortgage / 100
      }, () => {
        this.financeCalcualte();
      });
    } else if (name == 'radio_custom_purchase_price') {
      const error_message_purchasing_cost_empty = !value;

      this.setState({
        user_purchase_price: preparedValue,
        error_message_purchasing_cost_empty,
        real_cost_of_purchasing: preparedValue,
        commission_value: !preparedValue || !commission_percentage_value ? 0 : parseFloat(commission_percentage_value * preparedValue / 100).toFixed(2),
        mortgage_value: !preparedValue ? 0 : preparedValue * mortgage / 100
      }, () => {
        this.financeCalcualte();
        this.EditPurchasingCostFormResetError();
      });
    } else if (name == 'user_purchase_price') {
      this.setState({
        user_purchase_price: preparedValue,
        real_cost_of_purchasing: preparedValue,
        isChecked: false,
        new_real_cost_of_purchasing: '',
        custom_user_purchase_price: value,
        commission_value: !preparedValue || !commission_percentage_value ? 0 : parseFloat(commission_percentage_value * preparedValue / 100).toFixed(2),
        mortgage_value: !preparedValue ? 0 : preparedValue * mortgage / 100
      }, () => {
        this.financeCalcualte();
        this.EditPurchasingCostFormResetError();
        this.updatepriceSelectedOption();
      });
    } else if (name == 'cold_rent') {
      this.setState({
        cold_rent: preparedValue,
        isChecked: false,
        custom_cold_rent: value,
      }, () => {
        this.financeCalcualte();
        this.EditIncomeStatementFormResetError();
      });
    } else if (['radio_cold_rent_current', 'radio_cold_rent_15', 'radio_cold_rent_comparable', 'radio_cold_rent_market'].includes(name)) {
      this.setState({
        cold_rent: value
      }, () => {
        this.financeCalcualte();
      });
    } else if (name == 'radio_cold_rent_custom') {
      this.setState({
        cold_rent: preparedValue
      }, () => {
        this.financeCalcualte();
        this.EditIncomeStatementFormResetError();
      });
    } else if (name == 'commission_value') {
      this.setState({
        [name]: preparedValue,
        real_cost_of_purchasing: preparedValue,
        commission_percentage_value: !preparedValue ? 0 : parseFloat(preparedValue * 100 / user_purchase_price).toFixed(2),
        new_real_cost_of_purchasing: ''
      }, () => {
        this.financeCalcualte();
        this.EditPurchasingCostFormResetError();
      });
    } else if (name == 'commission_percentage_value') {
      this.setState({
        [name]: preparedValue,
        real_cost_of_purchasing: preparedValue,
        commission_value: !preparedValue ? 0 : parseFloat(preparedValue * user_purchase_price / 100).toFixed(2),
        new_real_cost_of_purchasing: ''
      }, () => {
        this.financeCalcualte();
        this.EditPurchasingCostFormResetError();
      });
    } else if (name == 'annual_gross_income') {
      this.setState({
        [name]: preparedValue
      }, () => {
        this.filterTaxesFromAnnualGrossIncomeData();
        this.EditGeneralAssumptionsFormResetError();
        this.financeCalcualte();
      });
    } else if (['interest_rate', 'repayment_rate', 'new_real_cost_of_purchasing', 'cold_rent'].includes(name)) {
      this.setState({
        [name]: preparedValue
      }, () => {
        this.financeCalcualte();
        this.EditFinancingFormResetError();
        this.EditIncomeStatementFormResetError();
      });
    } else {
      this.setState({
        [name]: preparedValue,
        isChecked: false
      }, () => {
        this.financeCalcualte();
        this.EditGeneralAssumptionsFormResetError();
        this.EditIncomeStatementFormResetError();
      });
    }
  }

  rentSelectedOption = (coldRent) => {
    const { cold_rent_15 } = this.state;
    const { comparable_cold_rent, default_cold_rent, market_price } = this.props;

    switch (parseFloat(coldRent)) {
      case parseFloat(default_cold_rent):
        return 'Current';
      case parseFloat(cold_rent_15):
        return '15';
      case parseFloat(comparable_cold_rent):
        return 'comparable_cold_rent';
      case parseFloat(market_price):
        return 'Market';
      default:
        return 'radio_cold_rent_custom';
    }
  }

  priceSelectedOption = (userPurchasePrice) => {
    const { const_purchase_price, direct_purchase_price } = this.props;

    switch (userPurchasePrice) {
      case parseFloat(direct_purchase_price):
        return 'direct_purchase_price';
      case parseFloat(const_purchase_price):
        return 'user_purchase_price';
      default:
        return 'radio_custom_purchase_price';
    }
  }

  // When user cancel or click on the cross button of the 'Show and Edit detailed financing plan' then all the fields should be reset to default value
  handle_financing_plan_modal = () => {
    const userPurchasePrice = parseFloat(this.props.user_purchase_price);
    const mortgage = parseFloat(this.props.mortgage);
    const monthlyReservePayment = parseFloat(this.props.monthly_reserve_payment);
    const coldRent = parseFloat(this.props.cold_rent);
    const renovationCost = parseFloat(this.props.renovation_cost) || 0;

    const { commission_value, commission_percentage_value } = this.commissionValues(userPurchasePrice);

    const rent_selected_option = this.rentSelectedOption(coldRent);
    const price_selected_option = this.priceSelectedOption(userPurchasePrice);

    this.setState({
      real_cost_of_purchasing: 0,
      show_edit_financing_plan: !this.state.show_edit_financing_plan,
      backdrop: 'static',
      keyboard: false,
      error_message_purchasing_cost_empty: false,
      price_selected_option,
      user_purchase_price: userPurchasePrice,
      commission_percentage_value,
      commission_value,
      renovation_cost: renovationCost,
      rent_selected_option,
      cold_rent: coldRent,
      not_allocable_operating_costs: parseFloat(this.props.not_allocable_operating_costs.replace(',', '.')),
      monthly_reserve_payment: !monthlyReservePayment ? 0 : monthlyReservePayment,
      special_property_management: parseFloat(this.props.special_property_management),
      loss_of_rental_income: parseFloat(this.props.loss_of_rental_income),
      additional_cost: parseFloat(this.props.additional_costs),
      equity: 100,
      interest_rate: parseFloat(this.props.interest_rate).toFixed(2),
      repayment_rate: parseFloat(this.props.repayment_rate).toFixed(2),
      mortgage,
      mortgage_value: mortgage * userPurchasePrice / 100,
      taxes: this.props.taxes,
      rent_increase: parseFloat(this.props.rent_increase),
      rent_increase_after_year: parseFloat(this.props.rent_increase_after_year),
      building_appreciation: parseFloat(this.props.building_appreciation),
      building_appreciation_after_year: parseFloat(this.props.building_appreciation_after_year),
      annual_repayment: parseFloat(this.props.annual_repayment),
      share_of_building: parseFloat(this.props.share_of_building),
      annual_gross_income: parseFloat(this.props.annual_gross_income)
    }, () => {
      this.financeCalcualte();
      this.EditPurchasingCostFormResetError();
      this.EditIncomeStatementFormResetError();
      this.EditFinancingFormResetError();
      this.EditGeneralAssumptionsFormResetError();
    });
  }

  // Display or Hide the 'Complete investment calculation Modal'
  handle_complete_investment_calculation = () => {
    if (!this.state.show_complete_investment_calculation) {
      this.trackNewRelic('show_complete_investment_calculation');
    }

    this.setState({
      show_complete_investment_calculation: !this.state.show_complete_investment_calculation
    });
  }

  // Display or Hide the 'Show and Edit Detailed financing Plan'
  show_edit_financing_plan = () => {
    this.trackNewRelic('edit_investment_calculation');

    this.setState(prevState => ({
      show_edit_financing_plan: !prevState.show_edit_financing_plan
    }));
  }

  trackNewRelic = (buttonName) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clicked: buttonName,
        candidate: this.props.candidate,
        utf8: '✓',
        authenticity_token: $('meta[name=csrf-token]').attr('content')
      })
    };

    fetch(`/listings/${this.props.listing_id}/send_invesment_calculation_data_to_newrelic`, requestOptions)
      .then(response => response.json())
      .then(data => console.log(data));

  }

  //Show the Error message when user make changes in the slider
  error_message_manage_assumption_function = () => {
    this.setState({
      error_message_manage_assumption: true
    });
  }

  // First set of array object that calculates Year, annuity, interest_cost, repayments, annual_repayment, remaining_loans, total_repayments. The complete output can be seen in Amortization Table
  financeCalcualte = () => {
    this.setState({
      final_list: calculateFinances(this.state)
    }, function () {
      // once the fist part of calculation is done, then the next part is of calculation is called. The values in the second part of the calculation are dependent on the outcome of the first part.
      this.investmentCalculate();
    });
  }

  roundingFunction = (num) => {
    return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
  }

  // This is the second part of calculation. Its outcome can be seen in 'Show Complete Investment Calculation' Modal
  investmentCalculate = () => {
    const newState = calculateInvestment(this.props, this.state);
    this.setState(newState);
  }

  commissionValues = (userPurchasePrice) => {
    const commissionPercentageValue = parseFloat(this.props.commission_percentage_value);
    const commissionValue = parseFloat(this.props.commission_value);

    if (commissionValue == commissionPercentageValue) {
      return {
        commission_value: (commissionPercentageValue * userPurchasePrice / 100).toFixed(2),
        commission_percentage_value: commissionPercentageValue.toFixed(2)
      };
    } else {
      return {
        commission_value: commissionValue.toFixed(2),
        commission_percentage_value: (commissionValue * 100 / userPurchasePrice).toFixed(2)
      };
    }
  }

  componentDidMount() {
    const userPurchasePrice = parseFloat(this.props.user_purchase_price) || 0.0;
    const constPurchasePrice = parseFloat(this.props.const_purchase_price) || 0;
    const commissionPercentageValue = parseFloat(this.props.commission_percentage_value) || 0;
    const landTransferCost = parseFloat(this.state.land_transfer_cost) || 0;
    const notaryCost = parseFloat(this.state.notary_cost) || 0;
    const landRegistryCost = parseFloat(this.state.land_registry_cost) || 0;
    const defaultRenovationCost = parseFloat(this.props.default_renovation_cost) || 0;
    const renovationCost = parseFloat(this.props.renovation_cost) || 0;
    const coldRent = parseFloat(this.props.cold_rent) || 0;
    const defaultColdRent = parseFloat(this.props.default_cold_rent) || 0;
    const coldRentNotDefault = coldRent != defaultColdRent;
    const userPurchaseNotDefault = userPurchasePrice != constPurchasePrice;

    const { commission_value, commission_percentage_value } = this.commissionValues(userPurchasePrice);

    if (userPurchaseNotDefault) {
      this.updatepriceSelectedOption();
    }

    if (coldRentNotDefault) {
      this.updateColdRentSelectedOption();
    }

    const financingNotDefault =
      (parseFloat(this.props.mortgage) != parseFloat(this.props.default_mortgage) ||
        parseFloat(this.props.interest_rate) != parseFloat(this.props.default_interest_rate) ||
        parseFloat(this.props.repayment_rate) != parseFloat(this.props.default_repayment_rate));

    const error_message_manage_assumption = userPurchaseNotDefault || coldRentNotDefault || financingNotDefault;

    const error_message_general_assumptions =
      (parseFloat(this.props.rent_increase_after_year) != parseFloat(this.props.default_rent_increase_after_year) ||
        parseFloat(this.props.rent_increase) != parseFloat(this.props.default_rent_increase) ||
        parseFloat(this.props.building_appreciation) != parseFloat(this.props.default_building_appreciation) ||
        parseFloat(this.props.building_appreciation_after_year) != parseFloat(this.props.default_building_appreciation_after_year) ||
        parseFloat(this.props.annual_repayment) != parseFloat(this.props.default_annual_repayment) ||
        parseFloat(this.props.share_of_building) != parseFloat(this.props.default_share_of_building) ||
        parseFloat(this.props.annual_gross_income) != parseFloat(this.props.default_annual_gross_income));

    const error_message_income_statement =
      (coldRentNotDefault ||
        parseFloat(this.props.loss_of_rental_income) != parseFloat(this.props.default_loss_of_rental_income) ||
        parseFloat(this.props.not_allocable_operating_costs) != parseFloat(this.props.default_not_allocable_operating_costs) ||
        parseFloat(this.props.monthly_reserve_payment) != parseFloat(this.props.default_monthly_reserve_payment) ||
        parseFloat(this.props.special_property_management) != parseFloat(this.props.default_special_property_management) ||
        parseFloat(this.props.extraordinary_expenses) != parseFloat(this.props.default_extraordinary_expenses));

    const error_message_purchasing_cost =
      (userPurchaseNotDefault ||
        commission_value != parseFloat(this.props.commission_amount_value) ||
        renovationCost != defaultRenovationCost)

    const const_real_cost_of_purchasing =
      this.roundingFunction(
        constPurchasePrice * (commissionPercentageValue + landTransferCost + notaryCost + landRegistryCost) / 100 + defaultRenovationCost
      );

    this.setState({
      const_real_cost_of_purchasing,
      error_message_manage_assumption,
      error_message_general_assumptions,
      error_message_financing: financingNotDefault,
      error_message_income_statement,
      error_message_purchasing_cost,
      mortgage_value: calculateMortgageValue(this.state.mortgage, this.state.user_purchase_price),
      user_purchase_price: userPurchasePrice.toFixed(2),
      commission_value,
      commission_percentage_value
    }, () => {
      this.filterTaxesFromAnnualGrossIncomeData();
      this.financeCalcualte();
    })
  }

  // TODO: rename to numberToCurrency
  formatNumber = (number, options = {}) => {
    return new Intl.NumberFormat(options.locale || this.defaultLocale(), {
      currency: this.CURRENCY,
      style: 'currency',
      minimumFractionDigits: options.minDigits || this.DEFAULT_DECIMAL_DIGITS_COUNT,
      maximumFractionDigits: options.maxDigits || this.DEFAULT_DECIMAL_DIGITS_COUNT
    }).format(number);
  }

  // TODO: rename to numberToPercentage
  currencyFormatPercentage = (number, options = {}) => {
    return new Intl.NumberFormat(options.locale || this.defaultLocale(), {
      style: 'percent',
      minimumFractionDigits: options.minDigits || this.DEFAULT_DECIMAL_DIGITS_COUNT,
      maximumFractionDigits: options.maxDigits || this.DEFAULT_DECIMAL_DIGITS_COUNT
    }).format(number / 100);
  }

  defaultLocale = () => {
    return this.props.current_user == 'en' ? this.locales.en : this.locales.de;
  }

  // TODO: rename to numberToLocalizedDecimal
  formatNumberWithoutCurrency = (number, options = {}) => {
    return Intl.NumberFormat(this.props.current_user, {
      minimumFractionDigits: options.minDigits || this.DEFAULT_DECIMAL_DIGITS_COUNT,
      maximumFractionDigits: options.maxDigits || this.DEFAULT_DECIMAL_DIGITS_COUNT
    }).format(number);
  }

  numberToLocalizedInteger = (number) => {
    return Intl.NumberFormat(this.props.current_user, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
  }

  numberToIntegerCurrency = (number, options = {})=> {
    return Intl.NumberFormat(options.locale || this.defaultLocale(), {
      currency: this.CURRENCY,
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
  }

  filterTaxesFromAnnualGrossIncomeData() {
    const currentIncome = Math.floor(this.state.annual_gross_income / 500) * 500;

    const incomeDataRow = annualGrossIncomeData.find((incomeDataRow) => (
      incomeDataRow.income == currentIncome
    ));

    const taxes = parseFloat((!incomeDataRow ? 0.4271 : incomeDataRow.Tax) * 100).toFixed(2);

    this.setState({
      taxes
    });
  }


  renderFactsAndFigures = () => {
    return (
      <FactsAndFigures
        currencyFormatPercentage={this.currencyFormatPercentage}
        downPayment={this.state.down_payment}
        factor={this.state.factor}
        fullHeader={this.props.full_header}
        grossYield={this.state.gross_yield}
        hideFactsAndFiguresTitle={this.props.hide_facts_and_figures_title}
        netYield={this.state.net_yield}
        numberToIntegerCurrency={this.numberToIntegerCurrency}
        formatNumberWithoutCurrency={this.formatNumberWithoutCurrency}
        predictedCapitalAppreciationForSale={this.state.predicted_capital_appreciation_for_sale}
        predictedReturnOnEquityAfterSalePA={this.state.predicted_return_on_equity_after_sale_PA}
        rentedOut={this.state.rentedOut}
        showNetYield={this.props.show_net_yield}
      />
    );
  }

  render() {
    return this.props.show_only_header ? this.renderFactsAndFigures() : this.renderDefault();
  }

  renderDefault = () => {
    let first_interest_cost;
    let first_annual_repayment;
    let equity_from_appreciation_projection;
    let total_repayments_projection;
    let finalSecondListElement;
    let finalListElement;

    const { final_list, final_second_list } = this.state;
    const total_investment_calculation = final_second_list.slice(1);
    const projectionIndex = 10;
    const remaining_loans = [];
    const total_repayments = [];
    const equity_from_appreciation = [];
    const total_investment_calculation_pdf = [];
    const cashflow = [];
    const cashflow_without_repayments = [];
    const repayments = [];
    const equity_from_cashflow = [];
    const equity_from_appreciation_pdf = [];
    const total_repayments_pdf = [];

    if (final_list.length > 0 && final_second_list.length > 0) {
      first_interest_cost = final_list[1].interest_costs;
      first_annual_repayment = final_list[1].repayments;

      for (let i = 1; i <= projectionIndex; i++) {
        finalSecondListElement = final_second_list[i];
        finalListElement = final_list[i];

        total_investment_calculation_pdf.push(finalSecondListElement);
        cashflow.push(finalSecondListElement.cashflow);
        repayments.push(- finalSecondListElement.repayments);
        equity_from_cashflow.push(finalSecondListElement.equity_from_cashflow);
        equity_from_appreciation_pdf.push(finalSecondListElement.equity_from_appreciation);
        cashflow_without_repayments.push(
          this.roundingFunction(finalSecondListElement.cashflow - finalSecondListElement.repayments)
        );

        if (finalListElement) {
          total_repayments_pdf.push(finalListElement.total_repayments);
        }
      }

      for (let i = 0; i < final_list.length; i++) {
        remaining_loans.push(final_list[i].remaining_loans);
        total_repayments.push(final_list[i].total_repayments);

        if (i === projectionIndex) {
          total_repayments_projection = this.state.final_list[i].total_repayments;
        }
      }

      if (final_list.length <= final_second_list.length) {
        for (let i = 0; i < final_list.length; i++) {
          equity_from_appreciation.push(final_second_list[i].equity_from_appreciation);

          if (i === projectionIndex) {
            equity_from_appreciation_projection = this.state.final_second_list[i].equity_from_appreciation;
          }
        }
      }
    }

    const monthly_loan_payment = this.roundingFunction((this.roundingFunction(first_annual_repayment) + this.roundingFunction(first_interest_cost)) / 12);

    return (
      <div>
        <hr />
        { this.renderFactsAndFigures() }
        <ManageAssumptions
          numberToIntegerCurrency={this.numberToIntegerCurrency}
          numberToLocalizedInteger={this.numberToLocalizedInteger}
          currencyFormatPercentage={this.currencyFormatPercentage}
          show_edit_financing_plan={this.show_edit_financing_plan}
          handleOptionChangeRent={this.handleOptionChangeRent}
          rent_selected_option={this.state.rent_selected_option}
          mortgage={this.state.mortgage}
          cold_rent_15={this.state.cold_rent_15}
          error_message_manage_assumption={this.state.error_message_manage_assumption}
          error_message_manage_assumption_function={this.error_message_manage_assumption_function}
          updateColdRentSelectedOption={this.updateColdRentSelectedOption}
          updatepriceSelectedOption={this.updatepriceSelectedOption}
          handleOptionChangePrice={this.handleOptionChangePrice}
          price_selected_option={this.state.price_selected_option}
          direct_purchase_price={this.state.direct_purchase_price}
          const_purchase_price={this.state.const_purchase_price}
          user_purchase_price={this.state.user_purchase_price}
          const_real_cost_of_purchasing={this.state.const_real_cost_of_purchasing}
          real_cost_of_purchasing={this.state.real_cost_of_purchasing}
          new_real_cost_of_purchasing={this.state.new_real_cost_of_purchasing}
          const_cold_rent={this.state.const_cold_rent}
          default_cold_rent={this.state.default_cold_rent}
          cold_rent={this.state.cold_rent}
          custom_user_purchase_price={this.state.custom_user_purchase_price}
          custom_cold_rent={this.state.custom_cold_rent}
          const_interest_rate={this.state.const_interest_rate}
          interest_rate={this.state.interest_rate}
          updateRange={this.handleChange}
          market_price={this.state.market_price}
          comparable_cold_rent={this.state.comparable_cold_rent}
          const_repayment_rate={this.state.const_repayment_rate}
          repayment_rate={this.state.repayment_rate}
          living_space={this.state.living_space}
          equity={this.state.equity}
          default_mortgage={this.props.default_mortgage}
          default_interest_rate={this.props.default_interest_rate}
          default_repayment_rate={this.props.default_repayment_rate}
          resetForm={this.resetForm}
          rentedOut={this.state.rentedOut}
        />
        <hr />
        <PropertyResults
          formatNumber={this.formatNumber}
          currencyFormatPercentage={this.currencyFormatPercentage}
          cold_rent={this.state.cold_rent}
          not_allocable_operating_costs={this.state.not_allocable_operating_costs}
          monthly_reserve_payment={this.state.monthly_reserve_payment}
          special_property_management={this.state.special_property_management}
          extraordinary_expenses={this.state.extraordinary_expenses}
          loss_of_rental_income_const={this.state.loss_of_rental_income_const}
          lossOfRentalIncome={this.state.loss_of_rental_income}
          assumed_rental_income_first={this.state.assumed_rental_income_first}
          calculated_taxes={this.state.calculated_taxes}
          first_interest_cost={first_interest_cost}
          first_annual_repayment={first_annual_repayment}
          shareOfBuilding={this.state.share_of_building}
          perYearDepreciation={this.state.per_year_depreciation}
          interestRate={this.state.interest_rate}
          repaymentRate={this.state.repayment_rate}
          taxes={this.state.taxes}
        />
        <Graph
          numberToIntegerCurrency={this.numberToIntegerCurrency}
          remaining_loans={remaining_loans}
          total_repayments={total_repayments}
          equity_from_appreciation={equity_from_appreciation}
        />

        <Projection
          roundingFunction={this.roundingFunction}
          numberToIntegerCurrency={this.numberToIntegerCurrency}
          equity_from_appreciation={equity_from_appreciation_projection}
          total_repayments={total_repayments_projection}
          predicted_equity_from_cashflow={this.state.predicted_equity_from_cashflow}
          real_cost_of_purchasing={this.state.real_cost_of_purchasing}
          predicted_capital_appreciation_for_sale={this.state.predicted_capital_appreciation_for_sale}
          handle_complete_investment_calculation={this.handle_complete_investment_calculation}
        />

        <FinancePlan
          roundingFunction={this.roundingFunction}
          formatNumber={this.formatNumber}
          numberToIntegerCurrency={this.numberToIntegerCurrency}
          currencyFormatPercentage={this.currencyFormatPercentage}
          equity={this.state.equity}
          dept={this.state.mortgage_value}
          interest_rate={this.state.interest_rate}
          repayment_rate={this.state.repayment_rate}
          annual_repayment={this.state.annual_repayment}
          finance_list_modal={this.state.final_list}
          monthly_loan_payment={monthly_loan_payment}
        />
        <EditInvestmentCalculation
          roundingFunction={this.roundingFunction}
          backdrop={this.state.backdrop}
          keyboard={this.state.keyboard}
          formatNumber={this.formatNumber}
          formatNumberWithoutCurrency={this.formatNumberWithoutCurrency}
          numberToIntegerCurrency={this.numberToIntegerCurrency}
          currencyFormatPercentage={this.currencyFormatPercentage}
          annual_gross_income={this.state.annual_gross_income}
          total_taxable_not_allocable_operating_costs={this.state.total_taxable_not_allocable_operating_costs}
          assumed_rental_income_first={this.state.assumed_rental_income_first}
          not_allocable_operating_costs={this.state.not_allocable_operating_costs}
          show_edit_financing_plan={this.state.show_edit_financing_plan}
          handle_financing_plan_modal={this.handle_financing_plan_modal}
          EditPurchasingCostFormReset={this.EditPurchasingCostFormReset}
          EditIncomeStatementFormReset={this.EditIncomeStatementFormReset}
          EditFinancingFormReset={this.EditFinancingFormReset}
          EditGeneralAssumptionsFormReset={this.EditGeneralAssumptionsFormReset}
          renovation_cost={this.state.renovation_cost}
          rent_selected_option={this.state.rent_selected_option}
          cold_rent_15={this.state.cold_rent_15}
          default_cold_rent={this.state.default_cold_rent}
          defaultLossOfRentalIncome={this.props.default_loss_of_rental_income}
          defaultNotAllocableOperatingCosts={this.props.default_not_allocable_operating_costs}
          defaultMonthlyReservePayment={this.props.default_monthly_reserve_payment}
          defaultSpecialPropertyManagement={this.props.default_special_property_management}
          defaultExtraordinaryExpenses={this.props.default_extraordinary_expenses}
          commissionAmountValue={this.props.commission_amount_value}
          defaultCommissionValue={this.props.default_commission_value}
          defaultRenovationCost={this.props.default_renovation_cost}
          defaultRentIncreaseAfterYear={this.props.default_rent_increase_after_year}
          defaultRentIncrease={this.props.default_rent_increase}
          defaultBuildingAppreciation={this.props.default_building_appreciation}
          defaultBuildingAppreciationAfterYear={this.props.default_building_appreciation_after_year}
          defaultAnnualRepayment={this.props.default_annual_repayment}
          defaultShareOfBuilding={this.props.default_share_of_building}
          defaultAnnualGrossIncome={this.props.default_annual_gross_income}
          market_price={this.state.market_price}
          comparable_cold_rent={this.state.comparable_cold_rent}
          handleOptionChangeRent={this.handleOptionChangeRent}
          const_purchase_price={this.state.const_purchase_price}
          updateColdRentSelectedOption={this.updateColdRentSelectedOption}
          updatepriceSelectedOption={this.updatepriceSelectedOption}
          handleOptionChangePrice={this.handleOptionChangePrice}
          custom_cold_rent={this.state.custom_cold_rent}
          custom_user_purchase_price={this.state.custom_user_purchase_price}
          price_selected_option={this.state.price_selected_option}
          direct_purchase_price={this.state.direct_purchase_price}
          annual_repayment={this.state.annual_repayment}
          interest_rate={this.state.interest_rate}
          repayment_rate={this.state.repayment_rate}
          handleChange={this.handleChange}
          user_purchase_price={this.state.user_purchase_price}
          commission_percentage_value={this.state.commission_percentage_value}
          commission_value={this.state.commission_value}
          land_transfer_cost={this.state.land_transfer_cost}
          notary_cost={this.state.notary_cost}
          land_registry_cost={this.state.land_registry_cost}
          cold_rent={this.state.cold_rent}
          loss_of_rental_income={this.state.loss_of_rental_income}
          loss_of_rental_income_const={this.state.loss_of_rental_income_const}
          rent_increase={this.state.rent_increase}
          rent_increase_after_year={this.state.rent_increase_after_year}
          additional_cost={this.state.additional_cost}
          equity={!this.state.total_cost_of_purchasing ? 0 : parseFloat(this.state.total_cost_of_purchasing) - parseFloat(this.state.mortgage_value)}
          extraordinary_expenses={(this.state.extraordinary_expenses)}
          building_appreciation={this.state.building_appreciation}
          building_appreciation_after_year={this.state.building_appreciation_after_year}
          share_of_building={this.state.share_of_building}
          per_year_depreciation={this.state.per_year_depreciation}
          depreciation={this.state.depreciation}
          total_cost_of_purchasing={this.state.total_cost_of_purchasing}
          predicted_return_on_equity_after_sale_PA={this.state.predicted_return_on_equity_after_sale_PA}
          down_payment={this.state.down_payment}
          predicted_capital_appreciation_for_sale={this.state.predicted_capital_appreciation_for_sale}
          factor={this.state.factor}
          gross_yield={this.state.gross_yield}
          net_yield={this.state.net_yield}
          monthly_reserve_payment={this.state.monthly_reserve_payment}
          special_property_management={this.state.special_property_management}
          first_interest_cost={first_interest_cost}
          first_annual_repayment={first_annual_repayment}
          mortgage={this.state.mortgage}
          mortgage_value={this.state.mortgage_value}
          handleChangeCheckbox={this.handleChangeCheckbox}
          taxes={this.state.taxes}
          calculated_taxes={this.state.calculated_taxes}
          listing_id={this.state.listing_id}
          isChecked={this.state.isChecked}
          saveFormInfo={this.saveFormInfo}
          error_message_general_assumptions={this.state.error_message_general_assumptions}
          error_message_financing={this.state.error_message_financing}
          error_message_income_statement={this.state.error_message_income_statement}
          error_message_purchasing_cost={this.state.error_message_purchasing_cost}
          default_mortgage={this.props.default_mortgage}
          default_interest_rate={this.props.default_interest_rate}
          default_repayment_rate={this.props.default_repayment_rate}
          error_message_purchasing_cost_empty={this.state.error_message_purchasing_cost_empty}
          rentedOut={this.props.rented_out}
          candidate={this.props.candidate}
          showNetYield={this.props.show_net_yield}
          fullHeader={this.props.full_header}
        />
        <CompleteInvestmentCalculation
          formatNumber={this.formatNumber}
          currencyFormatPercentage={this.currencyFormatPercentage}
          total_investment_calculation={total_investment_calculation}
          handle_complete_investment_calculation={this.handle_complete_investment_calculation}
          show_complete_investment_calculation={this.state.show_complete_investment_calculation}
          taxes={this.state.taxes}
          share_of_building={this.state.share_of_building}
          per_year_depreciation={this.state.per_year_depreciation}
          loss_of_rental_income={this.state.loss_of_rental_income}
          interest_rate={this.state.interest_rate}
          repayment_rate={this.state.repayment_rate}
        />
        <DocumentTrial
          currencyFormatPercentage={this.currencyFormatPercentage}
          formatNumber={this.formatNumber}
          current_user_locale={this.props.current_user}
          numberToIntegerCurrency={this.numberToIntegerCurrency}
          locales={this.locales}
          canDownloadPDF={this.props.can_download_pdf}
          down_payment={this.state.down_payment}
          interest_rate={this.state.interest_rate}
          repayment_rate={this.state.repayment_rate}
          finance_list_modal={this.state.final_list}
          listing_info={this.props.lisiting_info}
          monthly_reserve_payment={this.state.monthly_reserve_payment}
          total_investment_calculation_pdf={total_investment_calculation_pdf}
          cashflow={cashflow}
          cashflow_without_repayments={cashflow_without_repayments}
          repayments={repayments}
          equity_from_cashflow={equity_from_cashflow}
          equity_from_appreciation_pdf={equity_from_appreciation_pdf}
          total_repayments_pdf={total_repayments_pdf}
          purchase_price = {this.props.user_purchase_price}
          commission = {this.state.commission_value}
          land_transfer_cost = {this.props.land_transfer_cost}
          notary_cost = {this.props.notary_cost}
        />
      </div>
    )
  }
}
