import React from 'react';
import styles from './CopyTip.module.scss';

const CopyTip = ({
  copied
}) => {
  const classes = `${styles.copyTip} ${ copied ? styles.visible : styles.hidden }`;

  return (
    <div className={ classes } >
      {'Copied link to clipboard'}
    </div>
  );
};

export default CopyTip;
