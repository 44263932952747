import Plyr from 'plyr'
import "plyr/dist/plyr.css"
import './PlyrPlayer.scss';

export default class PlyrPlayer {
  static setup() {
    const playerElements = Array.from(document.querySelectorAll('.js-plyr-player'))

    playerElements.forEach((playerElement) => {
      new Plyr(playerElement)
    })
  }
}
