import React, { Component } from 'react'
import UserAvatar from 'commonComponents/domain/Users/UserAvatar/UserAvatar'
import UserBadges from 'commonComponents/domain/Users/UserBadges/UserBadges'
import styles from './UserCard.module.scss'
import cn from 'classnames'

function FavoriteIcon(props) {
  const { className, ...restProps } = props

  const resultingClassName = cn(
    "icon-star user--badge-icon user-favorite-icon",
    className
  )

  return (
    <i {...restProps} className={resultingClassName} />
  )
}

export default class UserCard extends Component {
  render() {
    const {
      user, innerRef, className, isMoving, dragableProps,
      onUserEditBadgeClick, onUserEditSeachProfileBadgeClick
    } = this.props

    if (!user) return null

    const resultingClassName = cn(
      styles.userCardMain,
      className,
      { [styles.isMoving]: isMoving }
    )

    return (
      <div
        {...dragableProps}
        className={resultingClassName}
        ref={innerRef}
      >
        <a href={`/users/${user.slug}`}>
          <div className={styles.firstSection}>
            <div className={styles.userData}>
              <UserAvatar className={styles.avatar} imageUrl={user.avatar_url} />
              <div className={styles.userDetails}>
                <a className={styles.userFullName}>

                  {user.full_name}
                  {user.favorite && <FavoriteIcon className={styles.favoriteIcon} />}
                </a>
                <div className={styles.companyName}>
                  {user.company_name}
                </div>
                <div className={styles.phoneNumber}>
                  {user.phone}
                </div>
              </div>
            </div>
          </div>
          <UserBadges
            user={user}
            onUserEditBadgeClick={onUserEditBadgeClick}
            onUserEditSeachProfileBadgeClick={onUserEditSeachProfileBadgeClick}
          />
          <div className={styles.secondSection}>
            <div className={styles.callback}>
              {user.next_action}
            </div>
            <div className={styles.comment}>
              {user.next_action_note}
            </div>
          </div>
        </a>
      </div>
    )
  }
}
