import {
  importServicesIcons,
  importServicesIconsWithText,
  importServicesPhotos } from './utils/imagesUtils';

const services = ['invest', 'finance', 'manage', 'sales'];
const servicesIcons = importServicesIcons();
const servicesIconsWithText = importServicesIconsWithText();
const servicesPhotos = importServicesPhotos();

const onlyLettersPattern = /^[A-Za-zÄäÖöÜü]+$/;
const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

const commonPatterns = {
  email: emailPattern,
  name: onlyLettersPattern
}

export {
  services,
  servicesIcons,
  servicesPhotos,
  servicesIconsWithText,

  onlyLettersPattern,
  emailPattern,

  commonPatterns
};
