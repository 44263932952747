import React from 'react';
import PlyrVideoPlayer from 'containers/PlyrVideoPlayer/PlyrVideoPlayer';

const CommonVideo = ({ url }) => {
  const getProvider = url => url.match(/^https:\/\/(www\.)?([a-z]+)\.com/)[2];
  const getId = url => url.match(/\w+$/)[0];

  return (
    <PlyrVideoPlayer
      videoId={ getId(url) }
      videoProvider={ getProvider(url) }
      width={Math.min(540, document.body.clientWidth - 20)}
    />
  );
};

export default CommonVideo;

