/* eslint-disable no-magic-numbers */
import React, { PureComponent } from 'react';
import Chart from 'react-apexcharts';
import I18n from 'i18n-js/index.js.erb';

export default class Graph extends PureComponent {
  generateDayWiseTimeSeries = (baseval) => {
    const series = [];

    for (let i = 0; i < baseval.length; i++) {
      const x = i;
      const y = baseval[i];

      series.push([x, y]);
    }

    return series;
  }

  options = () => {
    const numberToIntegerCurrency = this.props.numberToIntegerCurrency;

    return {
      chart: {
        id: 'chart',
        type: 'line',
        animations: {
          enabled: true
        },
        stacked: true,
        zoom: {
          type: 'x',
          enabled: false
        },
        toolbar: {
          autoSelected: 'zoom',
          show: false
        },
        background: '#fff'
      },
      colors: ['#ffdd67', '#e26800', '#ff9134'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        type: 'fill',
        gradient: {
          opacityFrom: 1,
          opacityTo: 1,
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter(y) {
            if (typeof y !== 'undefined') {
              return numberToIntegerCurrency(Math.round(y));
            }
            return y;
          }
        },
        x: {
          enabled: false,
          show: false,
          formatter(x) {
            if (typeof x !== 'undefined') {
              return `Year ${Math.round(x)}`;
            }
            return Math.round(x);
          }
        },
        fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 2,
          offsetY: -20,
        }
      },
      xaxis: {
        tooltip: {
          enabled: false
        },
        title: {
          text: I18n.t('js.investment_calculations.detailed_financing_plan.years'),
          align: 'left',
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '11px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            cssClass: 'apexcharts-xaxis-title',
          }
        },
        type: '',
        text: 'Year',
        labels: {
          formatter(x) {
            return Math.round(x);
          }
        }
      },
      yaxis: {
        labels: {
          formatter(value) {
            return numberToIntegerCurrency(value / 1000);
          }
        },
        opposite: true,
        type: '',
        text: 'Year'
      }
    }
  }

  series() {
    return [
      {
        name: I18n.t('js.investment_calculations.detailed_financing_plan.remaining_loan'),
        data: this.generateDayWiseTimeSeries(this.props.remaining_loans)
      },
      {
        name: I18n.t('js.investment_calculations.complete_investment_table.total_repayment'),
        data: this.generateDayWiseTimeSeries(this.props.total_repayments)
      },
      {
        name: I18n.t('js.investment_calculations.complete_investment_table.equity_from_appreciation'),
        data: this.generateDayWiseTimeSeries(this.props.equity_from_appreciation)
      }
    ]
  }

  render() {
    return (
      <div id="chart">
        <p> {I18n.t('js.investment_calculations.complete_investment_table.headline')} </p>
        <Chart options={this.options()} series={this.series()}
          type="area" height="350"
        />
      </div>
    );
  }
}
