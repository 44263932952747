import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  summaryDiv: {
    marginTop: 15,
    width: '100%',
    backgroundColor: '#ececec',
    flexDirection: "row",
    fontSize: 10,
    padding: 10
  },
  summaryLeft: {
    width: '20%',
  },
  summaryRight: {
    width: '70%'

  },
  summaryDivBelow:{
    marginTop: 15,
    width: '100%',
    flexDirection: "row",
    fontSize: 10,
    padding: 10
  },
  summaryLeftBelow:{
    width: '10%',
  },
  summaryRightBelow: {
    width: '80%'

  },
});

const ObjectInfoTableSummary = () => {
  return (
    <View>
      <View style={styles.summaryDiv}>
        <Text style={styles.summaryLeft}>
          EK-Rendite und Cashflow
        </Text>
        <View style={styles.summaryRight}>
          <Text>
            -&gt; Ø EK-Rendite 18 % p.a.
          </Text>
          <Text>
            -&gt; Anfangsinvestition: 29,704.00 €
          </Text>
          <Text>
            -&gt; 56,000.00 € (+176 %) Vermögenszuwachs bei einem Verkauf nach 10 Jahren
          </Text>
          <Text>
            -&gt; Ø -16.00 € monatliche Sparrate (-1,915.37 € über 10 Jahre)
          </Text>
          <Text>
            -&gt; Ergebnis nach Steuern: -107 €
          </Text>
        </View>
      </View>
      <View  style={styles.summaryDivBelow}>
        <Text style={styles.summaryLeftBelow}>
          Annahmen
        </Text>
        <View style={styles.summaryRightBelow}>
          <Text>
            Haltedauer: 10 Jahre; Einkommensteuersatz: 32.73%; Gebäudeabschreibung: 2%; 
            Mietausfallwagnis: 5%; Wertsteigerung P.A.: Ø +1.5 % Pro Jahr;
            Mietsteigerung: Ø +1.2 % Pro Jahr; Betriebskostensteigerung: 0 % Pro Jahr.
          </Text>
        </View>
      </View>
    </View>
  )
}
export default ObjectInfoTableSummary;
