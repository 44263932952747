import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class CashflowBarChartStacked extends Component {
  constructor(props) {
    super(props);

    const { numberToIntegerCurrency, locales } = props;

    this.state = {

      series: [{
        name: 'Total Net-Cash Flow',
        data: this.props.equity_from_cashflow
      }, {
        name: 'Appreciation Potential (1.5% p.a.)',
        data: this.props.equity_from_appreciation_pdf
      }, {
        name: 'Repayment',
        data: this.props.total_repayments_pdf
      }],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          zoom: {
            type: 'x',
            enabled: false
          },
          toolbar: {
            autoSelected: 'zoom',
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            barHeight: '100%',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter (val) {
            return `${numberToIntegerCurrency(val, { locale: locales.en })}`
          },
          style: {
            fontSize: "10px",
            colors: ['#333333', '#333333', '#333333']
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        fill: {
          opacity: 1,
          colors: ['#095394', '#de3715', '#ff9800']
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          min: -80000,
          max: 1000000,
          labels: {
            formatter(value) {
              return `${numberToIntegerCurrency(value, { locale: locales.en })}`;
            }
          },
        },
        colors: ['#095394', '#de3715', '#ff9800']
      },
    };
  }

  render() {
    return (
      <div id="CashflowBarChartStacked">
        <p className="chartHeading">Vermögenszuwachs nach Verkauf (nach 10 Jahren)</p>
        <div className='stackedChart'>
          <Chart options={this.state.options} series={this.state.series}
            type="bar" width={800}
            height={280}
          />
        </div>
      </div>
    )
  }
}

export default CashflowBarChartStacked;
