/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import I18n from 'i18n-js/index.js.erb';

export default class FinancePlan extends PureComponent {
  state = { show: false }

  showModal = () => {
    this.setState({ show: true });
  }

  hideModal = () => {
    this.setState({ show: false });
  }

  prepareValue(value) {
    return !value ? 0 : this.props.roundingFunction(value);
  }

  renderRows() {
    return [
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.equity'),
        name: "Equity",
        value: this.prepareValue(this.props.equity)
      },
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.dept'),
        name: "Dept",
        value: this.prepareValue(this.props.dept)
      },
      {
        lable: I18n.t('js.investment_calculations.complete_investment_table.interest_rate'),
        name: "interest_rate",
        value: this.prepareValue(this.props.interest_rate)
      },
      {
        lable: I18n.t('js.investment_calculations.complete_investment_table.repayment_rate'),
        name: "repayment_rate",
        value: this.prepareValue(this.props.repayment_rate)
      },
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.monthly_loan_payment'),
        name: "monthly_loan_payment",
        value: this.prepareValue(this.props.monthly_loan_payment)
      },
      {
        lable: I18n.t('js.investment_calculations.detailed_financing_plan.additional_annual_repayment'),
        name: "annual_repayment",
        value: this.prepareValue(this.props.annual_repayment)
      }
    ];
  }

  renderRowsModal() {
    const { formatNumber } = this.props;

    return this.props.finance_list_modal.map(function (d, key) {
      return (
        <tr key={key}>
          <td>
            {d.Year}
          </td>
          <td>
            {formatNumber(d.annuity)}
          </td>
          <td>
            {formatNumber(d.interest_costs)}
          </td>
          <td>
            {formatNumber(d.repayments)}
          </td>
          <td>
            {formatNumber(d.annual_repayment)}
          </td>
          <td>
            {formatNumber(d.remaining_loans)}
          </td>
          <td>
            {formatNumber(d.total_repayments)}
          </td>
        </tr>
      )
    });
  }

  formatValue = (name, value) => {
    return ['repayment_rate', 'interest_rate'].includes(name) ? this.props.currencyFormatPercentage(value) : this.props.numberToIntegerCurrency(value);
  }

  render() {
    const AvatarListArray = this.renderRows()

    return (
      <div className='Finanace_Plan'>
        <p> {I18n.t('js.investment_calculations.detailed_financing_plan.detailed_financing_plan')}</p>
        {AvatarListArray.map((data, key) => {
          return (
            <dl className="box-primary--row" key={key}>
              <dd>
                {data.lable}
              </dd>
              <dd className=" calculations-table--title -tr- -hsd-">
                {this.formatValue(data.name, data.value)}
              </dd>
            </dl>
          )
        })}

        <p>
          <span onClick={this.showModal} className="button -secondary">{I18n.t('js.investment_calculations.detailed_financing_plan.amortization_table')}</span>
        </p>

        <Modal className='customModal financePlanModal' show={this.state.show}
          onHide={this.hideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('js.investment_calculations.detailed_financing_plan.detailed_financing_plan')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <thead>
                <tr>
                  <td> {I18n.t('js.investment_calculations.complete_investment_table.year')} </td>
                  <td> {I18n.t('js.investment_calculations.complete_investment_table.annuity')} </td>
                  <td> {I18n.t('js.investment_calculations.complete_investment_table.interest')} </td>
                  <td> {I18n.t('js.investment_calculations.complete_investment_table.repayments')} </td>
                  <td style={{ width: '15%' }}> {I18n.t('js.investment_calculations.detailed_financing_plan.additional_annual_repayment')} </td>
                  <td> {I18n.t('js.investment_calculations.detailed_financing_plan.remaining_loan')} </td>
                  <td> {I18n.t('js.investment_calculations.complete_investment_table.total_repayment')} </td>
                </tr>
              </thead>
              <tbody>
                {this.props.finance_list_modal.length && this.renderRowsModal()}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <span onClick={this.hideModal} className="button -secondary amortization_table"> {I18n.t('js.investment_calculations.detailed_financing_plan.close')} </span>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
