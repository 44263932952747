import React, { useCallback, useMemo,  useState } from 'react';
import PasswordToggle from '../Login/PasswordToggle/PasswordToggle';
import styles from './FormField.module.scss';

/*eslint max-lines-per-function: ["error", 200]*/
const FormField = ({ type, label, id, classes, value, onChange, showTitle, hideTitle }) => {
  const [focused, setFocused] = useState(false);
  const [inputType, setInputType] = useState(type);
  const passwordToggleProps = { showTitle, hideTitle };

  const onFocus = useCallback(() => {
    setFocused(true);
  }, [setFocused]);

  const onChangeType = useCallback(value => {
    setInputType(value);
  }, [setInputType]);

  const onBlur = useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  const afterIcon = useMemo(() => {
    if (inputType === 'email') {
      return <span className='fa fa-envelope'></span>;
    } else {
      return <PasswordToggle { ...passwordToggleProps } changeType={ onChangeType } />;
    }
  }, [inputType, onChangeType, passwordToggleProps]);

  return (
    <div className={ styles.formField } id="login_email">
      <label htmlFor={ id } className={ focused ? styles.visible : styles.hidden }>
        { label }
      </label>
      <input
        id={ id }
        type={ inputType }
        className={ classes || '' }
        placeholder={ focused ? '' : label }
        label="false"
        name={ `user[${inputType}]` }
        defaultValue={ value || '' }
        onFocus={ onFocus }
        onBlur={ onBlur }
        onChange={ e => onChange(e) }
      />
      <span className={ styles.icon }>
        { afterIcon }
      </span>
    </div>
  );
};

export default FormField;
