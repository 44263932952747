import React, { useState } from 'react';
import Form from './Form/Form';
import Header from '../Login/Header/Header';
import styles from './ResetPassword.module.scss';
import ResetPasswordSuccess from './ResetPasswordSuccess/ResetPasswordSuccess';

const ResetPassword = ({
  header,
  subHeader,
  ...formProps
}) => {
  const headerProps = { header, subHeader };
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <div className={ `-bg-forgot-password ${ styles.ResetPasswordWrapper }` }>
      <div className={ styles.ResetPassword }>
        {!isSuccess ? (
          <>
            <Header { ...headerProps } />
            <Form { ...formProps } onSuccess={value => setIsSuccess(value)} />
          </>
        ) : (
          <ResetPasswordSuccess />
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
