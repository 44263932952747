import React, { useEffect, useState } from 'react';
import LabelBetaTooltip from '../Tooltips/LabelBetaTooltip/LabelBetaTooltip';
import {
  pdf,
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import mainImage from '../InvestmentCalculationPDF/Images/SmartInvest.png';
import footer from '../InvestmentCalculationPDF/Images/Footer';
import headerLogo from '../InvestmentCalculationPDF/Images/wunderAgentLogo';
import html2canvas from 'html2canvas';
import ObjectInfoTable1Left from '../InvestmentCalculationPDF/ObjectInfoTable/ObjectInfoTable1Left';
import ObjectInfoTable1Right from '../InvestmentCalculationPDF/ObjectInfoTable/ObjectInfoTable1Right';
import ObjectInfoPieChart from '../InvestmentCalculationPDF/ObjectInfoTable/ObjectInfoPieChart';
import ObjectInfoTable2Left from '../InvestmentCalculationPDF/ObjectInfoTable/ObjectInfoTable2Left';
import ObjectInfoTableSummary from '../InvestmentCalculationPDF/ObjectInfoTable/ObjectInfoTableSummary';
import CompleteInvestmentCalculationPdf from '../InvestmentCalculationPDF/CompleteInvestmentCalculationPDF/CompleteInvestmentCalculationPdf';
import CashflowBarChart from '../InvestmentCalculationPDF/BarCharts/CashflowBarChart';
import CashflowBarChartStacked from '../InvestmentCalculationPDF/BarCharts/CashflowBarChartStacked';
import FooterPdf from '../InvestmentCalculationPDF/Footer/FooterPdf';
import I18n from 'i18n-js/index.js.erb';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  body1: {
    paddingTop: 35,
    paddingBottom: 15,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    // marginVertical: 15,
    marginHorizontal: 130,
    width: '70%',
    marginTop: -70
  },
  imagePie: {
    width: '30%',
    position: 'absolute',
    right: 0,
    top: 0
  },
  imageBarChart: {
    marginTop: 15,
    width: 'auto'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    right: 50,
    color: 'grey',
  },
  footer: {
    width: '100%',
    position: 'absolute',
    fontSize: 12,
    bottom: 0,
    right: 0,
    left: 20,
    height: 65
  },
  viewHeader: {
    display: 'block',
    position: 'relative',
    padding: 40
  },
  headerLogo: {
    width: '30%',
    position: 'absolute',
    right: 0,
    top: -30
  },
  headerLogoEnd: {
    textAlign: 'center'
  },
  headerLogoEndImage: {
    width: '40%',
    marginVertical: 30,
    marginHorizontal: 230,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row"
  },
  tableRowDarkBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#93989f",
    color: "#fff",
    fontWeight: "bold"
  },
  tableRowLightBG: {
    margin: "auto",
    flexDirection: "row",
    display: "table-row",
    backgroundColor: "#b4b7bc",
    color: "#fff"
  },
  tableCol: {
    width: "33.3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "10px"
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10
  },
  tableCellLargeFont: {
    margin: "auto",
    marginTop: 5,
    fontSize: 14
  },
  mainPageText: {
    marginTop: 20,
    textAlign: 'center',
    fontFamily: 'Open Sans'
  },
  mainPageText1: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 800,
    marginTop: 10
  },
  mainPageText2: {
    fontSize: 20,
    fontWeight: 800
  },
  mainPageText3: {
    fontSize: 13,
    fontWeight: 800,
    marginTop: 5,
    textAlign: 'center'
  },
  mainPageText4: {
    fontSize: 11,
    color: '#a4aabd'
  },
  mainPageText5: {
    fontSize: 10,
    marginTop: 10
  },
  mainPageText7: {
    fontSize: 15,
    fontWeight: 800,
    marginTop: 10,
  },
  mainPageText8: {
    fontSize: 10,
    marginTop: 15,
    fontFamily: 'Open Sans',
    fontWeight: 800
  },
  mainPageText8Sub1: {
    marginTop: 15,
    fontSize: 12
  },
  mainPageText6: {
    fontSize: 15,
    fontFamily: 'Open Sans',
    fontWeight: 800,

  },
  mainPageText7Sub1: {
    marginTop: 15,
    fontSize: 12
  },
  mainPageText7Sub2: {
    marginTop: 15,
    fontSize: 12
  },
  tableColHeading: {
    display: 'table-cell',
    width: '100%',
    textAlign: 'left',
    padding: "10px"
  },
  tableCellHeading: {
    fontSize: 20
  },
  tableCol25: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    display: "table-cell",
    borderTopWidth: 0,
    padding: "10px"
  },
  marginTop10: {
    marginTop: 10
  },
  padding10: {
    padding: 10
  },
  fontSize12: {
    fontSize: 12
  },
  footerOrange: {
    fontSize: 25,
    color: '#FF9800',
    marginTop: 25,
    padding: 10
  },
  fontSize20: {
    fontSize: 20,
    marginTop: 5
  },
  downloadButtonContainer: {
    position: 'relative'
  }
});

let dataURL;
let dataURL2;
let dataURL3;

const DocumentPdf = (props) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape  "
        style={styles.body1}
      >
        <View style={styles.viewHeader}>
          <Image style={styles.headerLogo} src={headerLogo}
            fixed
          />
        </View>
        <View style={styles.mainPageText}>
          <Image style={styles.image} src={mainImage}
            alt="images"
          />
          <Text style={styles.mainPageText1}>
            Ihre kostenlose & unverbindliche
          </Text>
          <Text style={styles.mainPageText2}>
            Investitions- und Renditeberechnung
          </Text>
          <Text style={styles.mainPageText3}>
            {props.listing_info.number_of_rooms} Zimmer Wohnung mit {props.down_payment} € Anfangsinvestment
          </Text>
          <Text style={styles.mainPageText4}>
            {props.listing_info.full_address}
          </Text>
          <Text style={styles.mainPageText5}>Angefertigt für:</Text>
          <Text style={styles.mainPageText6}>{props.listing_info.owner_full_name}</Text>
        </View>
      </Page>
      <Page size="A4" orientation="landscape  "
        style={styles.body}
      >
        <View>
          <Text style={styles.mainPageText3}>
            {props.listing_info.number_of_rooms} Zimmer Wohnung - {props.listing_info.full_address}
          </Text>
          <View style={styles.marginTop10}>
            <ObjectInfoTable1Left listing_info={props.listing_info} />
            <ObjectInfoTable1Right listing_info={props.listing_info} interest_rate={props.interest_rate}
              repayment_rate={props.repayment_rate} finance_list_modal={props.finance_list_modal.length}
              down_payment={props.down_payment}
            />
          </View>
          <View style={styles.marginTop10}>
            <ObjectInfoTable2Left listing_info={props.listing_info}
              monthly_reserve_payment={props.monthly_reserve_payment}
            />
            <Image style={styles.imagePie} src={dataURL} />
          </View>
          <View style={styles.marginTop10}>
            <ObjectInfoTableSummary listing_info={props.listing_info}
              monthly_reserve_payment={props.monthly_reserve_payment}
            />
          </View>
        </View>

        <View break>
          <Text style={styles.padding10}>
            Renditebetrachtung über 10 Jahre
          </Text>
          <CompleteInvestmentCalculationPdf
            currencyFormatPercentage={props.currencyFormatPercentage}
            formatNumber={props.formatNumber}
            total_investment_calculation_pdf={props.total_investment_calculation_pdf}
          />
        </View>

        <View break>
          <Image style={styles.imageBarChart} src={dataURL2} />
          <Image style={styles.imageBarChart} src={dataURL3} />
          <Text style={styles.fontSize12}>
            * Nach dem 10. Jahr wird eine jährliche Wertsteigerung von 1% angenommen
          </Text>
        </View>

        <View break>
          <View style={styles.headerLogoEnd}>
            <Image style={styles.headerLogoEndImage} src={headerLogo} />
            <Text style={styles.mainPageText1}>
              Wir beraten Sie gern.
            </Text>
            <Text style={styles.fontSize20}>
              Unser Team freut sich auf Ihren Anruf oder Ihre E-Mail.
            </Text>
            <View style={styles.footerOrange}>
              <Text>
                0800 / 673 82 23
              </Text>
              <Text>
                beratung@wunderagent.de
              </Text>
            </View>
          </View>
          <FooterPdf />
        </View>

        <Image style={styles.footer} src={footer}
          fixed
        />
        <Text style={styles.pageNumber}
          render={({ pageNumber }) => (
            props.current_user_locale == 'en' ? `Page ${pageNumber}` : `Seite ${pageNumber}`
          )}
          fixed
        />
      </Page>
    </Document>
  )
};

const DocumentTrial = (props) => {
  const [visibility, changeVisibility] = useState('hidden');

  useEffect(() => {
    const timer = setTimeout(() => {
      changeVisibility('visible');

      html2canvas(document.querySelector(`#donutChart`), {
        scrollX: 0,
        scrollY: -window.scrollY
      }).then(canvas => {
        dataURL = canvas.toDataURL('image/png');
        changeVisibility('hidden');
      });

      html2canvas(document.querySelector(`#CashflowBarchart`), {
        scrollX: 0,
        scrollY: -window.scrollY
      }).then(canvas => {
        dataURL2 = canvas.toDataURL('image/png');
        changeVisibility('hidden')
      });

      html2canvas(document.querySelector(`#CashflowBarChartStacked`), {
        scrollX: 0,
        scrollY: -window.scrollY
      }).then(canvas => {
        dataURL3 = canvas.toDataURL('image/png');
        changeVisibility('hidden')
      });

    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='pdfButton'>
      <ObjectInfoPieChart
        purchase_price={props.purchase_price}
        commission={props.commission}
        land_transfer_cost={props.land_transfer_cost}
        notary_cost={props.notary_cost}
        visibility={visibility}
      />
      {props.cashflow.length > 0 &&
        <CashflowBarChart cashflow={props.cashflow}
          cashflow_without_repayments={props.cashflow_without_repayments}
          repayments={props.repayments}
          visibility={visibility}
          numberToIntegerCurrency={props.numberToIntegerCurrency}
          locales={props.locales}
        />}
      {props.equity_from_cashflow.length > 0 &&
      <CashflowBarChartStacked equity_from_cashflow={props.equity_from_cashflow}
        equity_from_appreciation_pdf={props.equity_from_appreciation_pdf}
        total_repayments_pdf={props.total_repayments_pdf}
        visibility={visibility}
        numberToIntegerCurrency={props.numberToIntegerCurrency}
        locales={props.locales}
      /> }
      { props.canDownloadPDF &&
        <div style={styles.downloadButtonContainer}>
          <button
            onClick={async () => {
              //   const propss = await delay(1000);
              const doc =
                <DocumentPdf
                  current_user_locale={props.current_user_locale}
                  formatNumber={props.formatNumber}
                  currencyFormatPercentage={props.currencyFormatPercentage}
                  down_payment={props.down_payment}
                  interest_rate={props.interest_rate}
                  repayment_rate={props.repayment_rate}
                  finance_list_modal={props.finance_list_modal}
                  listing_info={props.listing_info}
                  monthly_reserve_payment={props.monthly_reserve_payment}
                  total_investment_calculation_pdf={props.total_investment_calculation_pdf}
                />
              const asPdf = pdf([]); // {} is important, throws without an argument
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              saveAs(blob, 'investment_calculation.pdf');
            }}
          >
            Download PDF
          </button>
          <LabelBetaTooltip
            tip={I18n.t('js.investment_calculations.document_trial.tip')}
            labelAdditionalClasses={'rightCornerPosition'}
          />
        </div>
      }
    </div>
  )
}
export default DocumentTrial
