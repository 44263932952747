import React, { Component } from 'react'
import { generateSequentialID } from 'lib/idGenerators'
import VimeoIframeApiLoader from 'lib/VimeoIframeApiLoader'
import styles from './VimeoPlayer.module.css'


export default class VimeoPlayer extends Component {
  static defaultProps = {
    width: 640,
    videoId: null,
    overlayHideEnabled: false,
    renderVideoEndOverlay: null
  }

  player = null
  playerId = generateSequentialID('vimeo-player')

  state = {
    showOverlay: false
  }

  componentDidMount() {
    VimeoIframeApiLoader
      .load()
      .onLoad(this.onVimeoApiReady)
  }

  onVimeoApiReady = () => {
    const vimeoVideoIframe = document.getElementById(this.playerId)
    const { width, videoId } = this.props

    this.player = new Vimeo.Player(vimeoVideoIframe, {
      id: videoId,
      byline: false,
      portrait: false,
      title: false,
      width
    })

    this.player.on('finish', this.handleVideoEnded)
    this.player.on('ended', this.handleVideoEnded)
  }

  handleVideoEnded = () => {
    const { renderVideoEndOverlay } = this.props

    if (renderVideoEndOverlay)  {
      this.setState({ showOverlay: true })
    }
  }

  hideOverlay = () => {
    if (this.props.overlayHideEnabled) {
      this.setState({ showOverlay: false })
    }
  }

  replayVideo = () => {
    this.player.play()
    this.setState({ showOverlay: false })
  }

  renderOverlay() {
    const { renderVideoEndOverlay } = this.props

    return (
      <div className={styles.overlayWrapper} >
        <div className={styles.overlay} onClick={this.hideOverlay}></div>
        <div className={styles.buttonOverlay}>
          {renderVideoEndOverlay(this.replayVideo)}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        width={this.props.width}
        className={styles.main}
      >
        <div
          id={this.playerId}
        />
        {this.state.showOverlay && this.renderOverlay()}
      </div>
    )
  }
}
