import React, { useState, useEffect, memo } from "react";
import cn from 'classnames'

function LoadingSpinner(props) {
  const {
    className,
    delayInMiliseconds
  } = props

  const [showSpinner, setShowSpinner] = useState(!delayInMiliseconds)

  if (delayInMiliseconds) {
    const timeoutDescriptor = setTimeout(
      () => setShowSpinner(true),
      delayInMiliseconds
    )

    // Clear timer on unmount
    useEffect(() => {
      return function cleanup() {
        clearTimeout(timeoutDescriptor)
      }
    })
  }

  if (showSpinner) {
    const resultingClassName = cn('spinner', className)

    return (
      <div className={resultingClassName}>
        <div className="spinner--icon"></div>
        <div className="spinner--counter js-counter"></div>
      </div>
    )
  } else {
    return null
  }
}

export default memo(LoadingSpinner)
