import React, { useCallback, useState } from 'react';
import FormField from '../../FormField/FormField';
import { keyEnterPressed } from '../../../lib/utils/hotKeysUtil';
import classNames from 'classnames';
import { emailRegex } from '../../../commonComponents/common.jsx';

/*eslint max-lines-per-function: ["error", 200]*/
const ResetPasswordForm = ({
  email,
  token,
  url,
  resetLabel,
  onSuccess,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');

  const handleValidateEmail = (email) => {
    if (emailRegex.test(email)) {
      return true;
    }
    return false;
  };

  const data = {
    'utf8': '✓',
    'authenticity_token': token,
    'user': {
      'email': inputValue,
    },
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };

  // POST request using fetch with error handling
  const forgotPassword = () => fetch(`${url  }users/password`, requestOptions)
    .then(async () => {
      setError('');
      return onSuccess(true);
    })
    .catch(error => {
      if (error === 400) {
        setError('Bad request');
      } else if (error === 404) {
        setError('Not found');
      } else if (error === 500) {
        setError('Internal server error');
      } else {
        setError('Unknown error');
      }
    });

  const onChangeEmail = useCallback((e) => {
    setInputValue(e.target.value);
    if (e.target.value.length !== 0 && handleValidateEmail(e.target.value)) {
      setDisabled(false);
      setError('');
    } else if (e.target.value.length === 0) {
      setDisabled(true);
      setError('');
    } else {
      setDisabled(true);
      setError('Please enter a valid email');
    }
  }, [handleValidateEmail, setInputValue, setError, setDisabled]);

  const onKeyDown = (event) => {
    if (keyEnterPressed(event) && !disabled) {
      forgotPassword();
    }
  };

  return (
    <div
      id="new_user"
      className="form -new"
      onKeyDown={ e => onKeyDown(e) }
    >
      <FormField
        { ...email }
        type="email"
        autofocus="true"
        id="user_email"
        onChange={ e => onChangeEmail(e) }
        classes={classNames('field', error && ' -error')}
      />
      {error && <div className="form--error">{ error }</div>}
      <br />
      <button
        onClick={ forgotPassword }
        name="button"
        type="button"
        disabled={ disabled }
        className="button full-width -secondary -big -tc-"
      >
        { resetLabel }
      </button>
    </div>
  );
};

export default ResetPasswordForm;
