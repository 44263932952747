import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';

export default class Assumptions extends PureComponent {
  translation = (key) => {
    return I18n.t(`js.investment_calculations.complete_investment_table.${key}`);
  }

  renderAssumptionsText = () => {
    const props = this.props;
    const currencyFormatPercentage = props.currencyFormatPercentage;
    const taxes = currencyFormatPercentage(props.taxes);
    const shareOfBuilding = currencyFormatPercentage(props.shareOfBuilding);
    const perYearDepreciation = currencyFormatPercentage(props.perYearDepreciation);
    const interestRate = currencyFormatPercentage(props.interestRate);
    const repaymentRate = currencyFormatPercentage(props.repaymentRate);
    const lossOfRentalIncome = currencyFormatPercentage(props.lossOfRentalIncome);

    return `${this.translation('tax')}: ${taxes} p.a.;
            ${this.translation('building_share')}: ${shareOfBuilding};
            ${this.translation('building_depreciation')}: ${perYearDepreciation} p.a.;
            ${this.translation('loss_of_rental_income')}: ${lossOfRentalIncome} p.a.;
            ${this.translation('interest_rate')}: ${interestRate} p.a.;
            ${this.translation('repayment_rate')}: ${repaymentRate} p.a.`;
  }

  render() {
    return (
      <div className='Assumptions'>
        <strong>{this.translation('assumptions')}:</strong>
        <span>{this.renderAssumptionsText()}</span>
      </div>
    );
  }
}
