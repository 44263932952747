
import React, { PureComponent } from 'react'
// import Cleave from 'cleave.js/react';

export default class FinanceCalcualte extends PureComponent {

  handleChange = (event) => {
    const { name, value } = event.target;
    this.props.handleChange(name, value)
  }
  renderRows() {
    if (this.props.final_list.length === 0) {
      return null
    }
    return this.props.final_list.map(function (d, key) {

      return (
        <tr key={key}>
          <td>
            {d.Year}
          </td>
          <td>
            {d.annuity}
          </td>
          <td>
            {d.interest_costs}
          </td>
          <td>
            {d.repayments}
          </td>
          <td>
            {d.annual_repayment}
          </td>
          <td>
            {d.remaining_loans}
          </td>
          <td>
            {d.total_repayments}
          </td>
        </tr>
      )
    })
  }

  render() {
    const listItems = this.renderRows()
    return (
      <div>
        <form className="Financing_Plan_form">
          <table>
            <tbody>
              <tr>
                <td>
                  <label>Loan Amount</label>
                </td>
                <td>
                  <input className="field js-closing-costs-field" type="text"
                    name="loan_amount"
                    value={this.props.loan_amount}
                    onChange={this.handleChange}
                  />
                  <span className="field symbol">€</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <label>User Purchase Price</label>
                </td>
                <td>
                  <input className="field js-closing-costs-field" type="text"
                    name="user_purchase_price"
                    value={this.props.user_purchase_price}
                    onChange={this.handleChange}
                  />
                  <span className="field symbol">€</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Annual Repayment</label>
                </td>
                <td>
                  <input className="field js-closing-costs-field" type="text"
                    name="annual_repayment"
                    value={this.props.annual_repayment} onChange={this.handleChange}
                  />
                  <span className="field symbol">€</span>
                </td>
              </tr>
            </tbody>
          </table>
        </form>

        <table style={{ 'display': 'none' }}>
          <thead>
            <tr>
              <td>
                Year
              </td>
              <td>
                Annuity
              </td>
              <td>
                Interest Cost
              </td>
              <td>
                Repayment
              </td>
              <td>
                Additional Annual Repayment
              </td>
              <td>
                Remaining Loan
              </td>
              <td>
                Total Repayment
              </td>
            </tr>
          </thead>
          <tbody>
            {listItems}
          </tbody>
        </table>
        <hr />
      </div>
    )
  }
}
