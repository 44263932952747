import React, { useContext } from 'react';
import { FormContext } from 'hocHelpers/withContactForm';

const HiddenFields = ({ fields }) => {
  const { register } = useContext(FormContext);

  return (
    <>
      { fields.map(field =>
        <input
          key={ field.name }
          name={ field.name }
          value={ field.value }
          { ...register(field.name, {}) }
          hidden
        />
      ) }
    </>
  );
};

export default HiddenFields;
