import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import CommonFields from 'commonComponents/forms/CommonFields/CommonFields';
import styles from './SmartInvestForm.module.scss';

const SmartInvestForm = ({
  form
}) => {
  const fields = cloneDeep(form.fields);
  const { first_name, last_name, email, phone } = fields;

  first_name.size = '20';
  last_name.size = '20';
  email.size = '40';
  phone.size = '40';

  const leadFields = {};
  
  Object.keys(fields)
    .forEach(fieldName => {
      leadFields[`lead[${fieldName}]`] = fields[fieldName];
    });

  return (
    <div className={ styles.SmartInvestForm }>
      <CommonFields fields={ leadFields } />
    </div>
  );
};
 
export default SmartInvestForm;
