import React from 'react';
import Checkbox from 'commonComponents/forms/Checkbox/Checkbox';
import styles from './InterestedIn.module.scss';

/*eslint max-lines-per-function: ["error", 200]*/
const InterestedIn = ({ list, label }) => {
  const keys = Object.keys(list);

  const url = window.location.search;
  const searchParams = new URLSearchParams(url);
  const checked = {};

  keys.forEach(key => {
    checked[key] = searchParams.get(key) === 'true';
  });

  const generateLabel = (label) => {
    if(typeof label === 'string')
      return label;
    
    return (
      <div className={ styles.text }>
        { label.title && <span className={ styles.title }>{ label.title }</span> }
        { label.description && <span className={ styles.description }>{ label.description }</span> }
      </div>
    );
  };

  return (
    <div className={ styles.InterestedIn }>
      <p className={ styles.label }>{ label }</p>
      {
        keys.map(key => (
          <Checkbox
            key={ key } name={ key }
            isChecked={ checked[key] }
          >
            { generateLabel(list[key]) }
          </Checkbox>
        ))
      }
    </div>
  );
};

export default InterestedIn;
