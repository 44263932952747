/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import I18n from 'i18n-js/index.js.erb';
import Assumptions from '../InvestmentCalculationComponents/Assumptions';

export default class CompleteInvestmentCalculation extends PureComponent {

  handleCompleteInvestmentCalculation = () => {
    this.props.handle_complete_investment_calculation(this.state);
  }

  hideModal = () => {
    this.setState({ show: false });
  }

  renderFieldCurrency(objectKey) {
    return this.props.total_investment_calculation.map((obj, key) => (
      <td key={key} colSpan="2">
        { this.props.formatNumber(obj[objectKey]) }
      </td>
    ));
  }

  renderFieldCurrencyTaxNegative(objectKey) {
    return this.props.total_investment_calculation.map((obj, key) => (
      <td key={key} colSpan="2">
        { this.props.formatNumber(- obj[objectKey]) }
      </td>
    ));
  }

  renderFieldPercentage(objectKey) {
    return this.props.total_investment_calculation.map((obj, key) => (
      <td key={key} colSpan="2">
        { this.props.currencyFormatPercentage(obj[objectKey]) }
      </td>
    ));
  }

  renderField(objectKey) {
    return this.props.total_investment_calculation.map((obj, key) => (
      <td key={key} colSpan="2">
        {obj[objectKey]}
      </td>
    ));
  }

  blankBlocks() {
    return this.props.total_investment_calculation.map((d, key) => (
      <td key={key} colSpan="2">
        &nbsp;
      </td>
    ));
  }

  render() {
    const {
      currencyFormatPercentage,
      interest_rate,
      loss_of_rental_income,
      per_year_depreciation,
      repayment_rate,
      share_of_building,
      show_complete_investment_calculation,
      taxes,
      total_investment_calculation
    } = this.props;

    const blankBlocks = this.blankBlocks();

    return (
      <div className="customModalPureComponent">
        <Modal className='customModal complete_investment_calculation' show={show_complete_investment_calculation}
          onHide={this.handleCompleteInvestmentCalculation}
        >
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('js.investment_calculations.complete_investment_table.complete_investment_calculation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { !!total_investment_calculation.length &&
              <table>
                <tbody>
                  <tr className='year'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.complete_investment_calculation')}</td>
                    {this.renderField('Year')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.rent_increase')}</td>
                    {this.renderFieldPercentage('rent_increase')}
                  </tr>
                  <tr className='italic_styling'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.monthly_rent_per_m2')}</td>
                    {this.renderFieldCurrency('monthly_rent_per_m2')}
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>{I18n.t('js.investment_calculations.complete_investment_table.net_annual_rent')}</td>
                    {this.renderFieldCurrency('net_annual_rent')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.loss_of_rental_income')}</td>
                    {this.renderFieldCurrency('loss_of_rental_income')}
                  </tr>
                  <tr className='rental_income'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.assumed_rental_income')}</td>
                    {this.renderFieldCurrency('rental_income')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.total_taxable_not_allocable_operating_costs')}</td>
                    {this.renderFieldCurrency('total_taxable_not_allocable_operating_costs')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.depreciation')}</td>
                    {this.renderFieldCurrency('depreciation_form')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.interest_costs')}</td>
                    {this.renderFieldCurrency('interest_costs')}
                  </tr>
                  <tr className='taxable_income'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.taxable_income')}: {I18n.t('js.investment_calculations.complete_investment_table.income_after_interest')}</td>
                    {this.renderFieldCurrency('taxable_income')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.tax')}</td>
                    {this.renderFieldCurrency('tax')}
                  </tr>

                  <tr className='white_bg'>
                    <td></td>
                    {blankBlocks}
                  </tr>

                  <tr className='rental_income'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.assumed_rental_income')}</td>
                    {this.renderFieldCurrency('rental_income')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.total_not_applicable_operation_cost')}</td>
                    {this.renderFieldCurrency('not_applicable_operation_cost')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.tax')}</td>
                    {this.renderFieldCurrencyTaxNegative('tax')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.interest_costs')}</td>
                    {this.renderFieldCurrency('interest_costs')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.repayments')}</td>
                    {this.renderFieldCurrency('repayments')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.extraordinary_expenses')}</td>
                    {this.renderFieldCurrency('extraordinary_expenses')}
                  </tr>
                  <tr className='cashflow'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.cashflow')}</td>
                    {this.renderFieldCurrency('cashflow')}
                  </tr>
                  <tr className='light_green'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.cashflow_per_month')}</td>
                    {this.renderFieldCurrency('cashflow_per_month')}
                  </tr>
                  <tr className='light_green'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.return_on_equity_after_tax')}</td>
                    {this.renderFieldPercentage('return_on_equity_after_tax')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.return_on_equity_before_tax')}</td>
                    {this.renderFieldPercentage('return_on_equity_before_tax')}
                  </tr>

                  <tr className='white_bg'>
                    <td></td>
                    {blankBlocks}
                  </tr>

                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.appreciation_per_annum')}</td>
                    {this.renderFieldPercentage('appreciation_per_annum')}
                  </tr>
                  <tr className='resale_price'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.resale_price')}</td>
                    {this.renderFieldCurrency('resale_price')}
                  </tr>
                  <tr className='italic_styling'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.resale_price_per_m2')}</td>
                    {this.renderFieldCurrency('resale_price_per_m2')}
                  </tr>

                  <tr className='white_bg line_height'>
                    <td></td>
                    {blankBlocks}
                  </tr>

                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.equity_from_appreciation')}</td>
                    {this.renderFieldCurrency('equity_from_appreciation')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.equity_from_repayments')}</td>
                    {this.renderFieldCurrency('total_repayments')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.equity_from_cashflow')}</td>
                    {this.renderFieldCurrency('equity_from_cashflow')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.real_cost_of_purchasing')}</td>
                    {this.renderFieldCurrency('real_cost_of_purchasing')}
                  </tr>
                  <tr className='cashflow'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.capital_appreciation_for_sale')}</td>
                    {this.renderFieldCurrency('capital_appreciation_for_sale')}
                  </tr>

                  <tr className='white_bg'>
                    <td></td>
                    {blankBlocks}
                  </tr>

                  <tr className='grey_bg_bold'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.capital_appreciation_for_sale')}</td>
                    {this.renderFieldCurrency('capital_appreciation_for_sale')}
                  </tr>
                  <tr className='capital_appreciation_for_sale_without_equity_appreciation'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.capital_appreciation_for_sale_without_equity_appreciation')}</td>
                    {this.renderFieldCurrency('capital_appreciation_for_sale_without_equity_appreciation')}
                  </tr>
                  <tr>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.capital_employed')}</td>
                    {this.renderFieldCurrency('capital_employed')}
                  </tr>
                  <tr className='italic_styling'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.equity_to_finance_the_purchase_price')}</td>
                    {this.renderFieldCurrency('equity_to_finance_the_purchase_price')}
                  </tr>
                  <tr className='cashflow'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.roe_after_sale')}</td>
                    {this.renderFieldPercentage('roe_for_sale_incl_betterment')}
                  </tr>
                  <tr>
                    <td>Ø {I18n.t('js.investment_calculations.complete_investment_table.return_on_equity_after_sale_PA')}</td>
                    {this.renderFieldPercentage('return_on_equity_after_sale_PA')}
                  </tr>
                  <tr className='light_green'>
                    <td>{I18n.t('js.investment_calculations.complete_investment_table.roe_after_sale_without_equity_appreciation')}</td>
                    {this.renderFieldPercentage('roe_after_sale_without_equity_appreciation')}
                  </tr>
                  <tr>
                    <td>Ø {I18n.t('js.investment_calculations.complete_investment_table.roe_after_sale_without_equity_appreciation_pa')}</td>
                    {this.renderFieldPercentage('roe_after_sale_without_equity_appreciation_pa')}
                  </tr>
                </tbody>
              </table>
            }
          </Modal.Body>
          <Assumptions
            currencyFormatPercentage={currencyFormatPercentage}
            taxes={taxes}
            shareOfBuilding={share_of_building}
            perYearDepreciation={per_year_depreciation}
            interestRate={interest_rate}
            repaymentRate={repayment_rate}
            lossOfRentalIncome={loss_of_rental_income}
          />
          <Modal.Footer>
            <span onClick={this.handleCompleteInvestmentCalculation} className="button -secondary amortization_table">{I18n.t('js.investment_calculations.detailed_financing_plan.close')}</span>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
