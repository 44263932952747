/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PriceRadio from '../../../InvestmentCalculationContainer/PriceRadio';
import DebounceInput from 'uiKit/inputs/DebounceInput/DebounceInput';
import I18n from 'i18n-js/index.js.erb';
import InfoTooltip from '../../../Tooltips/InfoTooltip/InfoTooltip';

export default class EditPurchasingCostForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error_message_purchasing_cost_empty: false
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    if (!value) {
      return false;
    } else {
      this.props.handleChange(name, value);
    }
  }

  updatepriceSelectedOption = (event) => {
    const { name, value } = event.target;

    if (!value) {
      this.setState({ error_message_purchasing_cost_empty: true });
    } else if (parseFloat(value) == 0) {
      this.props.handleChange(name, value);
      this.setState({ error_message_purchasing_cost_empty: true });
    } else {
      this.props.handleChange(name, value);
      this.setState({ error_message_purchasing_cost_empty: false });
    }
  }

  renderDisabledField = (userPurchasePrice, cost) => {
    const value = !userPurchasePrice ? 0 : this.props.formatNumberWithoutCurrency(userPurchasePrice * parseFloat(cost) / 100);

    return (
      <td>
        <input className="field js-closing-costs-field"
          type="text"
          value={value}
          readOnly
        />
        <span className="field symbol">€</span>
      </td>
    );
  }

  render() {
    const {
      formatNumber,
      formatNumberWithoutCurrency,
      renderMadeAssumptionTip
    } = this.props;

    const userPurchasePrice = parseFloat(this.props.user_purchase_price);
    const constPurchasePrice = parseFloat(this.props.const_purchase_price);
    const commissionValue = parseFloat(this.props.commission_value);
    const commissionPercentageValue = parseFloat(this.props.commission_percentage_value);
    const defaultCommissionValue = parseFloat(this.props.defaultCommissionValue);
    const commissionAmountValue = parseFloat(this.props.commissionAmountValue);
    const defaultRenovationCost = parseFloat(this.props.defaultRenovationCost);
    const renovationCost = parseFloat(this.props.renovation_cost);

    return (
      <tbody>
        <tr>
          <td>{I18n.t('js.investment_calculations.facts_figures.purchase_price')}</td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="user_purchase_price"
              value={formatNumberWithoutCurrency(userPurchasePrice)}
              onChange={this.updatepriceSelectedOption}
              placeholder={0}
            />
            <span className="field symbol">€</span>
            <div className='error_message'>
              { this.state.error_message_purchasing_cost_empty &&
                <div className="calculations-table--alert js-custom-assumptions-alert error_message_purchasing_cost_empty">
                  <span className="text-danger"> {I18n.t('js.investment_calculations.complete_investment_table.purchase_price_not_empty')} </span>
                </div>
              }
            </div>
          </td>
          <td className='modal_radio'>
            <PriceRadio
              handleOptionChangePrice={this.props.handleOptionChangePrice}
              price_selected_option={this.props.price_selected_option}
              direct_purchase_price={this.props.direct_purchase_price}
              user_purchase_price={constPurchasePrice}
              custom_user_purchase_price={this.props.custom_user_purchase_price}
            />
          </td>
          {
            (userPurchasePrice != constPurchasePrice) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.facts_figures.purchase_price'),
                      value: formatNumber(constPurchasePrice)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr>
          <td className='plusSign'> {I18n.t('js.investment_calculations.complete_investment_table.commission_value')} </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="commission_value"
              value={formatNumberWithoutCurrency(commissionValue)}
              onChange={this.handleChange}
              placeholder={0}
            />
            <span className="field symbol">€</span>
          </td>
          <td className="smallWidthInput">
            <DebounceInput
              className="field js-closing-costs-field"
              type="text" name="commission_percentage_value"
              value={formatNumberWithoutCurrency(commissionPercentageValue)}
              onChange={this.handleChange}
              placeholder={0}
            />
            <span className="field symbol">%</span>
          </td>
          {
            (commissionPercentageValue != defaultCommissionValue ||
             commissionValue != commissionAmountValue) &&
             <td className='tooltipMadeAssumption'>
               <InfoTooltip iconType='danger'>
                 {
                   renderMadeAssumptionTip([
                     {
                       name: I18n.t('js.investment_calculations.complete_investment_table.commission_value'),
                       value: this.props.currencyFormatPercentage(defaultCommissionValue)
                     },
                     {
                       name: I18n.t('js.investment_calculations.complete_investment_table.commission_value'),
                       value: formatNumber(commissionAmountValue)
                     }
                   ])
                 }
               </InfoTooltip>
             </td>
          }
        </tr>
        <tr>
          <td className='plusSign'> {I18n.t('js.investment_calculations.complete_investment_table.land_transfer_cost')} </td>
          { this.renderDisabledField(userPurchasePrice, this.props.land_transfer_cost) }
          <td></td>
        </tr>
        <tr>
          <td className='plusSign'> {I18n.t('js.investment_calculations.complete_investment_table.notary_cost')} </td>
          { this.renderDisabledField(userPurchasePrice, this.props.notary_cost) }
          <td></td>
        </tr>
        <tr>
          <td className='plusSign'> {I18n.t('js.investment_calculations.complete_investment_table.land_registry_cost')} </td>
          { this.renderDisabledField(userPurchasePrice, this.props.land_registry_cost) }
          <td></td>
        </tr>
        <tr>
          <td className='plusSign'> {I18n.t('js.investment_calculations.complete_investment_table.renovation_costs')} </td>
          <td>
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="renovation_cost"
              value={formatNumberWithoutCurrency(renovationCost)}
              placeholder={0}
              onChange={this.handleChange}
            />
            <span className="field symbol">€</span>
          </td>
          {
            (renovationCost != defaultRenovationCost) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.complete_investment_table.renovation_costs'),
                      value: formatNumber(defaultRenovationCost)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
      </tbody>
    )
  }
}
