import DebounceSliderInput from 'uiKit/inputs/DebounceSliderInput/DebounceSliderInput'
import React, { memo } from 'react'

function InvestmentCalculationsSlider(props) {
  return (
    <div className="calculations-table--slider">
      <div className="slider -additional">
        <DebounceSliderInput {...props} />
      </div>
    </div>
  )
}

export default memo(InvestmentCalculationsSlider)
