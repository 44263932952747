import React from 'react';
import styles from './SocialButtons.module.scss';
import Button from './Button';
import Separator from './Separator';

const SocialButtons = ({ label, facebookUrl, googlePlusUrl, separatorLabel }) => {
  return (
    <div className="social-buttons">
      <Separator label={ separatorLabel } />
      <div className="social-buttons-block -mtx2- -tc-">
        <div className={ styles.socialButtons }>
          <Button
            type="Facebook"
            label={ label }
            url={ facebookUrl }
          />
          <Button
            type="Google"
            label={ label }
            url={ googlePlusUrl }
          />
        </div>
      </div>
    </div>
  );
};

export default SocialButtons;
