/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';

export default class EditTaxableProfitForm extends PureComponent {
  render() {
    const {
      assumed_rental_income_first,
      depreciation,
      formatNumber
    } = this.props;

    return (
      <tbody>
        <tr>
          <td></td>
          <td>
            <p className='global-heading'>{I18n.t('js.investment_calculations.property_results.per_year')}</p>
          </td>
        </tr>
        <tr>
          <td>{I18n.t('js.investment_calculations.property_results.assumed_rental_income_first')}</td>
          <td>
            <p>{formatNumber(!assumed_rental_income_first ? 0 : assumed_rental_income_first)}</p>
          </td>
        </tr>
        <tr>
          <td className='minusSign'>{I18n.t('js.investment_calculations.complete_investment_table.total_taxable_not_allocable_operating_costs')}</td>
          <td>
            <p>{formatNumber(this.props.total_taxable_not_allocable_operating_costs)}</p>
          </td>
        </tr>
        <tr>
          <td className='minusSign'>{I18n.t('js.investment_calculations.complete_investment_table.depreciation')}</td>
          <td>
            <p>{!depreciation ? 0 : formatNumber(depreciation)}</p>
          </td>
          <td>
            <p>{this.props.currencyFormatPercentage(parseFloat(this.props.per_year_depreciation))}</p>
          </td>
        </tr>
        <tr>
          <td className='minusSign'>{I18n.t('js.investment_calculations.complete_investment_table.interest_costs')}</td>
          <td>
            <p>{formatNumber(- this.props.interestCosts)}</p>
          </td>
        </tr>
      </tbody>
    );
  }
}
