import React from 'react';
import DefaultPhoto from 'oldAssets/images/missing.png';
import styles from './CustomerFeedbackBox.module.scss';

const CustomerFeedbackBox = ({ photo = DefaultPhoto, name, header, text }) => {
  return (
    <div className={ styles.CustomerFeedbackBox }>
      <div className={ styles.photoAndName }>
        <img src={ photo } width='60'
          height='60'
        />
        <div className={ styles.nameAndRating }>
          <h5 className={ styles.name }>{ name }</h5>
          <Rating />
        </div>
      </div>
      <h6 className={ styles.title }>{ header }</h6>
      <p className={ styles.text }>{ text }</p>
    </div>
  );
};

const Rating = ({ number = 5 }) => {
  const stars = Array(number).fill(null)
    .map((_, i) => <span key={ i } className={ styles.star }></span>);

  return (
    <div className={ styles.Rating }>
      { stars }
    </div>
  );
};

export default CustomerFeedbackBox;
