// Contains String "namespace" : Number : "index" pairs
const sequentialIdsCounterMap = {}

export function generateSequentialID(namespace = 'id') {
  sequentialIdsCounterMap[namespace] = sequentialIdsCounterMap[namespace] ||  0

  const sequentialID = `${namespace}-${sequentialIdsCounterMap[namespace]}`

  ++sequentialIdsCounterMap[namespace]

  return sequentialID
}


