import React, { useState, useContext } from 'react';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';
import { FormContext } from 'hocHelpers/withContactForm';
import classNames from 'classnames';
import styles from './Field.module.scss';

const defaultInputArgs = {
  maxLength: '40',
  size: '25',
  type: 'text'
};

/*eslint max-lines-per-function: ["error", 200]*/
const Field = ({
  name,
  label,
  validation,
  pattern,
  isRequired,
  textarea = false,
  defaultValue,
  ...other
}) => {
  const formContext = useContext(FormContext);
  const { register, errors } = formContext || {};
  const [focus, setFocus] = useState(false);
  const [filled, getFilled] = useState(false);

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = ({ target }) => {
    if (!target.value) {
      setFocus(false);
    }
  };

  const validationSettings = {};

  if(isRequired) {
    validationSettings.required = validation.required || '';
  }

  if(pattern) {
    validationSettings.pattern = {
      value: pattern,
      message: (validation && validation.pattern) || ''
    };
  }

  const registered = register ? register(name, validationSettings) : {};
  const args = {
    ...defaultInputArgs,
    ...other,
    ...registered,
    defaultValue,
    id: name,
    name,
    placeholder: '',
    type: name === 'email' ? name : defaultInputArgs.type,
    onFocus,
    onBlur,
    onChange: (evt) => {
      getFilled(!!evt.target.value);
      registered && registered.onChange && registered.onChange(evt);
    }
  };

  const classes = classNames({
    [styles.Field]: true,
    [styles.error]: errors && errors[name]
  });
  
  const labelClasses = classNames({
    [styles.focus]: focus,
    [styles.filled]: !!defaultValue,
    [styles.nonEmpty]: filled
  });

  return (
    <div className={ classes }>
      <label
        htmlFor={ name }
        className={ labelClasses }
      >{ label }</label>
      { textarea && <textarea { ...args } /> }
      { !textarea && <input { ...args } /> }
      { errors && <ValidationErrorMessage error={ errors[name] } /> }
    </div>
  );
};

export default Field;
