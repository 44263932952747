import React, { memo } from 'react'

function EmptyLink(props) {
  return (
    <div
      className="link users-list--empty-link "
      {...props}
    >
      {props.children}
    </div>
  )
}

function NoUsers(props) {
  const {
    showAllUsersButtonEnabled = false,
    onResetButtonClick, onShowAllUsersButtonClick
  } = props

  return (
    <div className="users-list--empty">
      <div className="users-list--empty-img icon-people-light-gray"></div>
      <div className="users-list--empty-text">
        No users found
      </div>
      {!showAllUsersButtonEnabled && (
        <EmptyLink onClick={onResetButtonClick}>
          Reset filter
        </EmptyLink>
      )}
      {showAllUsersButtonEnabled  && (
        <EmptyLink onClick={onShowAllUsersButtonClick}>
          Show all users
        </EmptyLink>
      )}
    </div>
  )
}

export default memo(NoUsers)
