import React from 'react';
import CustomerFeedbackBox from 'commonComponents/tiles/CustomerFeedbackBox/CustomerFeedbackBox';
import SectionWithBoxes from 'commonComponents/sections/SectionWithBoxes/SectionWithBoxes';

const CustomerFeedback = ({ headers, tiles, button, link, headerType = 'simple' }) => {
  const sectionProps = {
    headers,
    headerType,
    boxRender: (box, number) => 
      <CustomerFeedbackBox
        { ...box }
        key={ number }
      />
  };

  if(button && link) {
    sectionProps.button = {
      ...button,
      link
    };
  }

  return (
    <SectionWithBoxes
      { ...sectionProps }
      boxes={ tiles }
      anchor='customer-feedback'
    />
  );
};

export default CustomerFeedback;
