import React, { useMemo } from 'react';
import { importServicesIcons } from 'lib/utils/imagesUtils';
import { capitalize } from 'lib/utils/stringUtil';
import styles from './ServicesBar.module.scss';

const ServicesBar = ({ subtitles }) => {
  const icons = useMemo(() => {
    const context = require.context('oldAssets/images/icons/services', false, /.+smart-[a-z]+-icon\.svg$/);
    return importServicesIcons(context);
  });

  const services = ['invest', 'finance', 'manage', 'sales'];

  return ( 
    <div className={ styles.ServicesBar }>
      <div className={ styles.content }>
        { services && services.map(service => (
          <div key={ service } className={ styles.service }>
            <a href={`services/smart-${service}`} className={ styles.serviceLink }
              data-turbolinks="false"
            >
              <img src={ icons[service] } className={ styles.icon } />
              <div className={ styles.linkHeaders }>
                <h3 className={ styles.title }>SMART { capitalize(service) }</h3>
                <h6 className={ styles.subtitle }>{ subtitles[service] }</h6>                
              </div>
            </a>
          </div>
        )) }
      </div>
    </div>
  );
};

export default ServicesBar;
