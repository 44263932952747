import UserCard from '../UserCard/UserCard'
import cn from 'classnames'
import styles from './CardList.module.scss'
import React, { Component } from "react";
import { FixedSizeList, areEqual } from "react-window";
import {  Droppable, Draggable } from "react-beautiful-dnd";


class UserCardDndWrapper extends React.Component {
  getStyle({ provided, style }) {
    const combined = {
      ...style,
      ...provided.draggableProps.style
    }

    return combined
  }

  render() {
    const { provided, item, style, isDragging } = this.props
    const { onUserEditBadgeClick, onUserEditSeachProfileBadgeClick } = item

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={this.getStyle({ provided, style, isDragging })}
        className={`item ${isDragging ? "is-dragging" : ""}`}
      >
        <UserCard
          user={item}
          onUserEditBadgeClick={onUserEditBadgeClick}
          onUserEditSeachProfileBadgeClick={onUserEditSeachProfileBadgeClick}
        />
      </div>
    )
  }
}

const Row = React.memo(function Row(props) {
  const { data: items, index, style } = props;
  const item = items[index];

  return (
    <Draggable
      draggableId={item.id.toString()}
      index={index}
      key={item.id}
    >
      {provided => (
        <UserCardDndWrapper
          provided={provided}
          item={item}
          style={style}
        />
      )}
    </Draggable>
  )
}, areEqual)


export default class CardList extends Component {
  renderList(provided) {
    const {
      items, boardHeight,
      onUserEditBadgeClick, onUserEditSeachProfileBadgeClick
    } = this.props

    // const isDraggingOver = false

    // const resultingClassName = cn(
    //   styles.cardListMain,
    //   className,
    //   { [styles.colorBackground]: (items.length < 1) || isDraggingOver }
    // )
    const itemsWithEvents = items.map((item) => (
      { ...item, onUserEditBadgeClick, onUserEditSeachProfileBadgeClick }
    ))

    if (items.length === 0) {
      return (
        <div ref={provided.innerRef} className={styles.noItems}>
        </div>
      )
    }

    return (
      <FixedSizeList
        height={boardHeight || 0}
        itemCount={items.length}
        itemSize={175}
        width={293}
        className={styles.virtualList}
        outerRef={provided.innerRef}
        itemData={itemsWithEvents}
      >
        {Row}
      </FixedSizeList>
    )
  }
  render() {
    const {
      index, items, className,
    } = this.props
    // onUserEditBadgeClick, onUserEditSeachProfileBadgeClick

    const isDraggingOver = false

    const resultingClassName = cn(
      styles.cardListMain,
      className,
      { [styles.colorBackground]: (items.length < 1) || isDraggingOver }
    )
    // const itemsWithEvents = items.map((item) => (
    //   { ...item, onUserEditBadgeClick, onUserEditSeachProfileBadgeClick }
    // ))

    return (
      <Droppable
        mode="virtual"
        droppableId={index.toString()}
        isDropDisabled={index === 0}
        renderClone={(provided, snapshot, rubric) => (
          <UserCardDndWrapper
            provided={provided}
            isDragging={snapshot.isDragging}
            item={items[rubric.source.index]}
          />
        )}
      >
        {provided => (
          <div className={resultingClassName}>
            {this.renderList(provided)}
          </div>
        )}
      </Droppable>
    );
  }
}
