import React, { useMemo } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './InfoTooltip.module.scss';

/*eslint max-lines-per-function: ["error", 200]*/
function InfoTooltip ({
  tip,
  place = 'end',
  verticalPlace = 'top',
  specialClass,
  iconType,
  button,
  children
}) {
  const classes = useMemo(() => {
    return (
      classNames({
        [styles.InfoTooltip]: true,
        [styles[place]]: !!place,
        [styles[verticalPlace]]: !!verticalPlace,
        [specialClass]: !!specialClass,
      }));
  }, [styles, place, verticalPlace, specialClass]);

  const iconClasses = useMemo(() => {
    return (
      classNames({
        [styles.info]: true,
        [styles[iconType]]: !!iconType,
      }));
  }, [styles, iconType]);

  function renderTooltip(props) {
    return (
      <Tooltip
        id="button-tooltip"
        { ...props }
        className={ classes }
      >
        { tip || children }
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      placement={ `${verticalPlace}-${place}` }
      delay={{ show: 100, hide: 100 }}
      overlay={ renderTooltip }
    >
      { button || <Button className={ iconClasses } /> }
    </OverlayTrigger>
  );
}

export default InfoTooltip;
