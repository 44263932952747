/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */

import roundingFunction from '../subcontainers/roundingFunction';

export default function calculateInvestment(fincanceCalulateProps, financeCalculateState) {
  const second_list = [];

  const {
    building_appreciation_after_year,
    building_appreciation,
    cold_rent,
    const_purchase_price,
    default_cold_rent,
    final_list,
    land_registry_cost,
    land_transfer_cost,
    living_space,
    mortgage_value,
    monthly_reserve_payment,
    not_allocable_operating_costs,
    rent_increase_after_year,
    share_of_building,
    special_property_management,
    user_purchase_price
  } = financeCalculateState;

  const {
    default_renovation_cost,
    building_year
  } = fincanceCalulateProps;

  const defaultColdRent = !default_cold_rent ? 0 : parseFloat(default_cold_rent);
  const rentIncrease = !financeCalculateState.rent_increase ? 0 : roundingFunction(financeCalculateState.rent_increase);
  const coldRent = !cold_rent ? 0 : parseFloat(cold_rent);
  const lossOfRentalIncome = !financeCalculateState.loss_of_rental_income ? 0 : parseFloat(financeCalculateState.loss_of_rental_income);
  const buildingAppreciation = !building_appreciation ? 0 : roundingFunction(building_appreciation);
  const userPurchasePrice = !user_purchase_price ? 0 : roundingFunction(user_purchase_price);
  const notaryCost = !financeCalculateState.notary_cost ? 0 : parseFloat(financeCalculateState.notary_cost);
  const commissionPercentageValue = !financeCalculateState.commission_percentage_value ? 0 : parseFloat(financeCalculateState.commission_percentage_value);
  const landTransferCost = !land_transfer_cost ? 0 : parseFloat(land_transfer_cost);
  const landRegistryCost = !land_registry_cost ? 0 : parseFloat(land_registry_cost);
  const renovationCost = !financeCalculateState.renovation_cost ? 0 : roundingFunction(financeCalculateState.renovation_cost);
  const realCostOfPurchasing = parseFloat(financeCalculateState.real_cost_of_purchasing);
  const constRealCostOfPurchasing = parseFloat(financeCalculateState.const_real_cost_of_purchasing);
  const constPurchasePrice = !const_purchase_price ? 0 : parseFloat(const_purchase_price);
  const commissionPercentageValueProp = !fincanceCalulateProps.commission_percentage_value ? 0 : parseFloat(fincanceCalulateProps.commission_percentage_value);
  const defaultRenovationCostProp = !default_renovation_cost ? 0 : parseFloat(default_renovation_cost);
  const perYearDepreciation = !financeCalculateState.per_year_depreciation ? 0 : roundingFunction(financeCalculateState.per_year_depreciation);
  const shareOfBuilding = !share_of_building ? 0 : parseFloat(share_of_building);
  const notAllocableOperatingCosts = !not_allocable_operating_costs ? 0 : roundingFunction(not_allocable_operating_costs);
  const monthlyReservePayment = !monthly_reserve_payment ? 0 : roundingFunction(monthly_reserve_payment);
  const specialPropertyManagement = !special_property_management ? 0 : roundingFunction(special_property_management);
  const extraordinaryExpenses = !financeCalculateState.extraordinary_expenses ? 0 : roundingFunction(financeCalculateState.extraordinary_expenses);
  const mortgageValue = !mortgage_value ? 0 : roundingFunction(mortgage_value);
  const livingSpace = !living_space ? 0 : parseFloat(living_space);
  const taxes = !financeCalculateState.taxes ? 0 : parseFloat(financeCalculateState.taxes);
  const rentIncreaseAfterYear = !rent_increase_after_year ? 0 : roundingFunction(rent_increase_after_year);
  const buildingAppreciationAfterYear = !building_appreciation_after_year ? 0 : roundingFunction(building_appreciation_after_year);

  let netMonthlyRent = coldRent;
  let extended_final_list = final_list;
  let repayment = 0;
  let equity_from_cashflow = 0;
  let capital_employed = 0;
  let rent_increase;
  let tax;
  let predicted_return_on_equity_after_sale_PA;
  let predicted_capital_appreciation_for_sale;
  let predicted_equity_from_cashflow;
  let calculated_taxes;
  let taxable_income;
  let assumed_rental_income_first;
  let interestCosts;
  let repayments;
  let totalRepayments

  if (final_list.length <= 20) {
    const final_list_extension = [];

    const obj = {
      Year:             0,
      annuity:          0,
      interest_costs:   0,
      repayments:       0,
      annual_repayment: 0,
      remaining_loans:  0,
      total_repayments: final_list[final_list.length - 1].total_repayments
    }

    for (let i = 0; i <= 20 - final_list.length; i++) {
      final_list_extension.push(obj);
    }

    extended_final_list = final_list.concat(final_list_extension);
  }

  if (default_cold_rent == cold_rent) {
    rent_increase = rentIncrease;
  } else {
    rent_increase = roundingFunction((coldRent - defaultColdRent) / defaultColdRent * 100);
  }

  if (default_cold_rent == cold_rent) {
    netMonthlyRent += netMonthlyRent * rent_increase / 100;
  }

  let net_annual_rent = roundingFunction(netMonthlyRent * 12);
  const loss_of_rental_income_const = roundingFunction(lossOfRentalIncome * net_annual_rent / 100);
  let loss_of_rental_income = roundingFunction(loss_of_rental_income_const / 12);
  let appreciation_per_annum = buildingAppreciation;
  let equity_from_appreciation = roundingFunction(userPurchasePrice * appreciation_per_annum / 100);
  let resale_price = roundingFunction(userPurchasePrice + equity_from_appreciation);

  const commission_percentage_value = roundingFunction((!userPurchasePrice || !commissionPercentageValue) ? 0 : userPurchasePrice * commissionPercentageValue / 100);
  const transfer_cost = roundingFunction(userPurchasePrice * landTransferCost / 100);
  const notary_cost = roundingFunction(userPurchasePrice * notaryCost / 100);
  const registry_cost = roundingFunction(userPurchasePrice * landRegistryCost / 100);
  let real_cost_of_purchasing = - roundingFunction(commission_percentage_value + transfer_cost + notary_cost + registry_cost + renovationCost);

  if (realCostOfPurchasing != real_cost_of_purchasing && realCostOfPurchasing == constRealCostOfPurchasing) {
    real_cost_of_purchasing = - realCostOfPurchasing;
  }

  const const_real_cost_of_purchasing = roundingFunction(constPurchasePrice * (commissionPercentageValueProp + landTransferCost + notaryCost + landRegistryCost) / 100 + defaultRenovationCostProp);
  const real_cost_of_purchasing_slider = - real_cost_of_purchasing;
  let per_year_depreciation = perYearDepreciation;

  if (building_year && (building_year < 1925)) {
    per_year_depreciation = 2.5;
  }

  const depreciation_form = - roundingFunction((userPurchasePrice - real_cost_of_purchasing) * shareOfBuilding * per_year_depreciation / 10000);
  const total_not_allocable_operating_cost = - roundingFunction(notAllocableOperatingCosts + monthlyReservePayment + specialPropertyManagement + extraordinaryExpenses);
  const total_taxable_not_allocable_operating_costs = roundingFunction((total_not_allocable_operating_cost + monthlyReservePayment) * 12);
  let extraordinary_expenses = - roundingFunction(renovationCost);
  const equity = !userPurchasePrice ? 0 : roundingFunction((userPurchasePrice + commission_percentage_value + transfer_cost + notary_cost + registry_cost + renovationCost) - mortgageValue);

  for (let i = 0; i < extended_final_list.length; i++) {
    interestCosts = parseFloat(extended_final_list[i].interest_costs);
    repayments = parseFloat(extended_final_list[i].repayments);
    totalRepayments = parseFloat(extended_final_list[i].total_repayments);

    if (i == 0) {
      const obj = {
        Year:                                                       i,
        rent_increase:                                              0,
        monthly_rent_per_m2:                                        0,
        net_annual_rent:                                            0,
        loss_of_rental_income:                                      0,
        rental_income:                                              0,
        total_taxable_not_allocable_operating_costs:                0,
        depreciation_form:                                          0,
        interest_costs:                                             interestCosts,
        taxable_income:                                             0,
        tax:                                                        0,
        not_applicable_operation_cost:                              0,
        repayments:                                                 totalRepayments,
        extraordinary_expenses:                                     0,
        cashflow:                                                   0,
        cashflow_per_month:                                         0,
        return_on_equity_after_tax:                                 0,
        return_on_equity_before_tax:                                0,
        appreciation_per_annum:                                     0,
        resale_price:                                               0,
        resale_price_per_m2:                                        0,
        equity_from_appreciation:                                   0,
        total_repayments:                                           totalRepayments,
        equity_from_cashflow:                                       0,
        real_cost_of_purchasing:                                    0,
        capital_appreciation_for_sale:                              0,
        capital_appreciation_for_sale_without_equity_appreciation:  0,
        capital_employed:                                           0,
        equity_to_finance_the_purchase_price:                       0,
        roe_for_sale_incl_betterment:                               0,
        return_on_equity_after_sale_PA:                             0,
        roe_after_sale_without_equity_appreciation:                 0
      };

      second_list.push(obj);
    } else {
      if (i > 1) {
        real_cost_of_purchasing = roundingFunction(real_cost_of_purchasing + renovationCost);
        extraordinary_expenses = 0;
      }

      const monthly_rent_per_m2 = roundingFunction(net_annual_rent / 12 / livingSpace);
      loss_of_rental_income = - roundingFunction(lossOfRentalIncome * net_annual_rent / 100);
      const rental_income = roundingFunction(net_annual_rent + loss_of_rental_income);

      if (i == 1) assumed_rental_income_first = rental_income;

      taxable_income = roundingFunction(rental_income + depreciation_form - interestCosts + total_taxable_not_allocable_operating_costs);

      if (taxable_income < 0) {
        tax = 0;
      } else {
        tax = - roundingFunction(taxes * taxable_income / 100);
      }

      if (i == 1) {
        calculated_taxes = - tax;
      }

      const cashflow = roundingFunction(rental_income + tax + extraordinary_expenses + 12 * total_not_allocable_operating_cost - interestCosts - repayments);
      const cashflow_per_month = roundingFunction(cashflow / 12);
      const resale_price_per_m2 = roundingFunction(resale_price / livingSpace);
      repayment = roundingFunction(repayment - repayments);
      equity_from_cashflow = roundingFunction(cashflow + equity_from_cashflow);
      const capital_appreciation_for_sale = roundingFunction(equity_from_appreciation + totalRepayments + equity_from_cashflow + real_cost_of_purchasing);
      const capital_appreciation_for_sale_without_equity_appreciation = roundingFunction(totalRepayments + equity_from_cashflow + real_cost_of_purchasing);

      if (equity > 0) {
        if (i == 1) {
          if (cashflow < 0) {
            capital_employed = roundingFunction(capital_employed + equity - cashflow);
          } else {
            capital_employed = equity;
          }
        } else {
          if (cashflow < 0) {
            capital_employed = roundingFunction(capital_employed - cashflow);
          } else {
            capital_employed = equity;
          }
        }
      } else {
        capital_employed = 0;
      }

      const return_on_equity_after_tax = roundingFunction((cashflow + totalRepayments) / capital_employed * 100);
      const return_on_equity_before_tax = roundingFunction((cashflow + totalRepayments - tax) / capital_employed * 100);
      let equity_to_finance_the_purchase_price;

      if (equity == 0) {
        equity_to_finance_the_purchase_price = 0;
      } else {
        equity_to_finance_the_purchase_price = roundingFunction(equity - (commission_percentage_value + transfer_cost + notary_cost + registry_cost + renovationCost));
      }

      const roe_for_sale_incl_betterment = roundingFunction(capital_appreciation_for_sale / capital_employed * 100);
      const return_on_equity_after_sale_PA = roundingFunction(roe_for_sale_incl_betterment / i);
      const roe_after_sale_without_equity_appreciation = roundingFunction(capital_appreciation_for_sale_without_equity_appreciation / capital_employed * 100);
      const roe_after_sale_without_equity_appreciation_pa = roundingFunction(roe_after_sale_without_equity_appreciation / i);

      if (i == 10) {
        predicted_return_on_equity_after_sale_PA = return_on_equity_after_sale_PA;
        predicted_capital_appreciation_for_sale = capital_appreciation_for_sale;
        predicted_equity_from_cashflow = equity_from_cashflow;
      }

      const obj = {
        Year:                           i,
        rent_increase,
        monthly_rent_per_m2,
        net_annual_rent,
        loss_of_rental_income,
        rental_income,
        total_taxable_not_allocable_operating_costs,
        depreciation_form,
        interest_costs: - interestCosts,
        taxable_income,
        tax: - tax,
        not_applicable_operation_cost: roundingFunction(total_not_allocable_operating_cost * 12),
        repayments: - repayments,
        extraordinary_expenses,
        cashflow,
        cashflow_per_month,
        return_on_equity_after_tax,
        return_on_equity_before_tax,
        appreciation_per_annum,
        resale_price,
        resale_price_per_m2,
        equity_from_appreciation,
        total_repayments: totalRepayments,
        equity_from_cashflow,
        real_cost_of_purchasing,
        capital_appreciation_for_sale,
        capital_appreciation_for_sale_without_equity_appreciation,
        capital_employed,
        equity_to_finance_the_purchase_price,
        roe_for_sale_incl_betterment,
        return_on_equity_after_sale_PA,
        roe_after_sale_without_equity_appreciation,
        roe_after_sale_without_equity_appreciation_pa
      };

      rent_increase = rentIncreaseAfterYear;
      appreciation_per_annum = buildingAppreciationAfterYear;
      net_annual_rent = roundingFunction(net_annual_rent + net_annual_rent * rent_increase / 100);
      equity_from_appreciation = roundingFunction(equity_from_appreciation + resale_price * appreciation_per_annum / 100);
      resale_price = resale_price + resale_price * appreciation_per_annum / 100;

      second_list.push(obj);
    }
  }

  real_cost_of_purchasing = roundingFunction(commission_percentage_value + transfer_cost + notary_cost + registry_cost + renovationCost);

  if (default_cold_rent == cold_rent) {
    rent_increase = rentIncrease;
  } else {
    rent_increase = roundingFunction((coldRent - defaultColdRent) / defaultColdRent * 100);
  }

  if (realCostOfPurchasing != real_cost_of_purchasing && realCostOfPurchasing == constRealCostOfPurchasing) {
    real_cost_of_purchasing = realCostOfPurchasing;
  }

  let error_message_manage_assumption;

  if (real_cost_of_purchasing != const_real_cost_of_purchasing ||
    userPurchasePrice != +fincanceCalulateProps.const_purchase_price ||
    coldRent != +fincanceCalulateProps.default_cold_rent ||
    +financeCalculateState.mortgage != +fincanceCalulateProps.default_mortgage ||
    +financeCalculateState.interest_rate != +fincanceCalulateProps.default_interest_rate ||
    +financeCalculateState.repayment_rate != +fincanceCalulateProps.default_repayment_rate) {
    error_message_manage_assumption = true;
  } else {
    error_message_manage_assumption = false;
  }

  const newState = {
    error_message_manage_assumption,
    final_second_list:                        second_list,
    depreciation:                             !depreciation_form ? 0 : depreciation_form,
    real_cost_of_purchasing,
    tax,
    predicted_return_on_equity_after_sale_PA: !predicted_return_on_equity_after_sale_PA ? 0 : predicted_return_on_equity_after_sale_PA,
    predicted_capital_appreciation_for_sale:  !predicted_capital_appreciation_for_sale ? 0 : predicted_capital_appreciation_for_sale,
    predicted_equity_from_cashflow,
    total_cost_of_purchasing:                 !userPurchasePrice ? 0 : roundingFunction(userPurchasePrice + real_cost_of_purchasing),
    down_payment:                             userPurchasePrice + roundingFunction(real_cost_of_purchasing) - mortgageValue,
    factor:                                   roundingFunction(userPurchasePrice / (12 * coldRent)),
    gross_yield:                              roundingFunction(12 * coldRent / userPurchasePrice * 100),
    net_yield:                                roundingFunction((12 * coldRent + 12 * total_not_allocable_operating_cost - loss_of_rental_income_const) * 100 / (userPurchasePrice + real_cost_of_purchasing)),
    total_not_allocable_operating_cost:       - total_not_allocable_operating_cost,
    const_real_cost_of_purchasing,
    per_year_depreciation,
    calculated_taxes,
    equity:                                   roundingFunction(userPurchasePrice + commission_percentage_value + transfer_cost + notary_cost + registry_cost + renovationCost - mortgageValue),
    loss_of_rental_income_const:              !loss_of_rental_income_const ? 0 : loss_of_rental_income_const,
    real_cost_of_purchasing_slider,
    rent_increase:                            !rent_increase ? 0 : rent_increase,
    loan_amount:                              userPurchasePrice,
    assumed_rental_income_first,
    total_taxable_not_allocable_operating_costs
  };

  return newState;
}
