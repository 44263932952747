import React from 'react';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import styles from './FactsAndFiguresTooltip.module.scss';

const FactsAndFiguresTooltip = ({ note, title, formulas, specialClass, ...props }) => {
  return ( 
    <InfoTooltip { ...props } specialClass={ styles[specialClass] }>
      <FactsAndFigures { ...{ note, title, formulas } } />
    </InfoTooltip>
  );
};

const FactsAndFigures = ({ note, title, formulas }) => {
  return ( 
    <React.Fragment>
      <p>{ note }</p>
      <h6 className={ styles.title }>{ title }:</h6>
      { formulas && formulas.map(formula => <Formula key={ formula.left } { ...formula } />) }
    </React.Fragment>
  );
};
 
const Formula = ({ left, right }) => {
  return (            
    <div className={ styles.Formula }>
      <div className={ styles.left }>
        <span>
          { left } =
        </span>
      </div>
      <div className={ styles.right }>
        <span className={ styles.top }>
          { right.top }
        </span>
        <span className={ styles.bottom }>
          { right.bottom }
        </span>
      </div>
    </div>
  );
};
 
export default FactsAndFiguresTooltip;