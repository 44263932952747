import React, { PureComponent } from 'react'
import images from 'resources/images'

export default class FilterItem extends PureComponent {
  renderImage() {
    const { imageUrl, iconName, iconNameActiveState } = this.props

    if (iconName) {
      return (
        <>
          <i className={`account-manager-preview--icon icon-${iconName}`} />
          <i className={`account-manager-preview--icon icon-${iconNameActiveState} -active`} />
        </>
      )
    } else if (!iconName) {
      let resultingImageUrl

      if (imageUrl && imageUrl.length > 0) {
        resultingImageUrl = imageUrl
      } else {
        resultingImageUrl = images.userAvatarPlaceholderUrl
      }

      return (
        <div
          className="account-manager-preview--avatar"
          style={{ backgroundImage: `url(${resultingImageUrl})` }}
        />
      )
    }
  }

  render() {
    const { text, count, active, onClick } = this.props
    const activeClass = active ? '-active' : ''

    return (
      <div
        className={`js-filter-item account-manager-preview ${activeClass}`}
        onClick={onClick}
      >
        {this.renderImage()}

        <div className="account-manager-preview--name">
          {text}
        </div>
        <div className="account-manager-preview--count text-in-brackets">
          {count}
        </div>
      </div>
    )
  }
}
