/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import I18n from 'i18n-js/index.js.erb';

export default class FactsAndFigures extends PureComponent {

  prepareValue(value) {
    return !value ? 0 : parseFloat(value).toFixed(1);
  }

  render() {
    let {
      factor,
      grossYield,
      netYield,
      predictedReturnOnEquityAfterSalePA
    } = this.props;

    const {
      currencyFormatPercentage,
      downPayment,
      fullHeader,
      hideFactsAndFiguresTitle,
      numberToIntegerCurrency,
      formatNumberWithoutCurrency,
      predictedCapitalAppreciationForSale,
      rentedOut,
      showNetYield
    } = this.props;

    factor = this.prepareValue(factor);
    factor = !factor || isNaN(factor) ? 0 : factor;

    grossYield = this.prepareValue(grossYield);

    netYield = this.prepareValue(netYield);
    predictedReturnOnEquityAfterSalePA = this.prepareValue(predictedReturnOnEquityAfterSalePA);

    const tildeClass = rentedOut ? '' : 'text-with-tilde';

    return (
      <div className='FactsAndFigures'>
        <div className='formHeading'>
          {!hideFactsAndFiguresTitle && <div className='box-primary--title -secondary'>
            <div className='info-tip--row'>
              <span> {I18n.t('js.investment_calculations.facts_figures.facts_and_figure')} </span>
              <span className='info-tip -secondary display-right'>
                <div className='info-tip--content -width-400'>
                  {I18n.t('js.investment_calculations.facts_figures.notes')}
                  <br />
                  <br />
                  <b>{I18n.t('js.investment_calculations.facts_figures.calculation_indicator')}:</b>
                  <br />
                  <div className='formula'>
                    <div className='formula--body'>
                      <div className='formula--left'>
                        <span>
                          {I18n.t('js.investment_calculations.facts_figures.gross_yield')} =
                        </span>
                      </div>
                      <div className='formula--right'>
                        <span className='top'>
                          12 x {I18n.t('js.investment_calculations.facts_figures.monthly_cold_rent')}
                        </span>
                        <span className='bottom'>
                          {I18n.t('js.investment_calculations.facts_figures.purchase_price')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='formula'>
                    <div className='formula--body'>
                      <div className='formula--left -top-10'>
                        <span>
                          {I18n.t('js.investment_calculations.facts_figures.net_yield')} =
                        </span>
                      </div>
                      <div className='formula--right'>
                        <span className='top'>
                          12 x ({I18n.t('js.investment_calculations.facts_figures.monthly_cold_rent')} - {I18n.t('js.investment_calculations.facts_figures.reserve_payment')} - {I18n.t('js.investment_calculations.property_results.non_allocable_operating_cost')} - {I18n.t('js.investment_calculations.facts_figures.rental_loss')} - {I18n.t('js.investment_calculations.property_results.special_property_management')})
                        </span>
                        <span className='bottom'>
                          {I18n.t('js.investment_calculations.facts_figures.purchase_price')}  + {I18n.t('js.investment_calculations.facts_figures.additional_purchasing_costs')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='formula'>
                    <div className='formula--body'>
                      <div className='formula--left'>
                        <span>
                          {I18n.t('js.investment_calculations.facts_figures.factor')} =
                        </span>
                      </div>
                      <div className='formula--right'>
                        <span className='top'>
                          {I18n.t('js.investment_calculations.facts_figures.purchase_price')}
                        </span>
                        <span className='bottom'>
                          12 x {I18n.t('js.investment_calculations.facts_figures.monthly_cold_rent')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
          }
          <hr className="titleUnderline" />
        </div>
        <div className="calculations-table--row head">
          <div className="calculations-table--col">
            <div className="calculations-table--title">
              <span className={`js-gross-yield ${tildeClass}`}>
                {formatNumberWithoutCurrency(factor, { minDigits: 1, maxDigits: 1 })}
              </span>
            </div>
            <div className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.factor')}
            </div>
          </div>
          <div className="calculations-table--col">
            <div className="calculations-table--title">
              <span className={`js-net-yield ${tildeClass}`}>
                {currencyFormatPercentage(grossYield, { minDigits: 1, maxDigits: 2 })}
              </span>
            </div>
            <div className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.gross_yield')}
            </div>
          </div>
          { showNetYield &&
            <div className="calculations-table--col">
              <div className="calculations-table--title">
                <span className={`js-factor ${tildeClass}`}>
                  {currencyFormatPercentage(netYield, { minDigits: 1, maxDigits: 1 })}
                </span>
              </div>
              <div className="calculations-table--subtitle">
                {I18n.t('js.investment_calculations.facts_figures.net_yield')}
              </div>
            </div> }
        </div>
        { fullHeader &&
        <div className="calculations-table--row head secondRow">
          <div className="calculations-table--col">
            <div className="calculations-table--title">
              <span className={`js-net-yield ${tildeClass}`}>
                {numberToIntegerCurrency(downPayment || 0)}
              </span>
            </div>
            <div className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.down_payment')}
            </div>
          </div>
          <div className="calculations-table--col">
            <div className="calculations-table--title">
              <span className={`js-factor ${tildeClass}`}>
                {numberToIntegerCurrency(predictedCapitalAppreciationForSale || 0)}
              </span>
            </div>
            <div className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.projected_wealth')}
            </div>
          </div>
          <div className="calculations-table--col">
            <div className="calculations-table--title">
              <span className={`js-gross-yield ${tildeClass}`}>
                {currencyFormatPercentage(predictedReturnOnEquityAfterSalePA, { minDigits: 1, maxDigits: 1 })}
              </span>
            </div>
            <div className="calculations-table--subtitle">
              {I18n.t('js.investment_calculations.facts_figures.average_equity_yield')}
            </div>
          </div>
        </div> }
      </div>
    )
  }
}
