import React, { PureComponent } from 'react'
import I18n from 'i18n-js/index.js.erb'

export default class PriceRadio extends PureComponent {

  render() {
    return (
      <div className="calculations-table--row -secondary">
        <div className="calculations-table--row-inner">
          { this.props.showRadioLabel &&
            <div className="calculations-table--col main-label small">
              <span className="text-bold text-with-colon">
                {I18n.t('js.investment_calculations.facts_figures.price')}
              </span>
            </div>
          }
          <div className="calculations-table--row radios js-purchase-price-radios">
            <div className={isNaN(this.props.direct_purchase_price) ?
              'hidden' : 'calculations-table--col small'}
            >
              <label className="form--field -radio">
                <input onChange={this.props.handleOptionChangePrice}
                  id="direct_purchase_price"
                  checked={this.props.price_selected_option === "direct_purchase_price"}
                  value={this.props.direct_purchase_price}
                  className="field"
                  name='radio_direct_purchase_price'
                  type="radio"
                />
                <span className="radio secondary"></span>
                <span>
                  {I18n.t('js.investment_calculations.detailed_financing_plan.direct_purchase_price')}
                </span>
              </label>
            </div>
            <div className="calculations-table--col small">
              <label className="form--field -radio">
                <input onChange={this.props.handleOptionChangePrice} id="user_purchase_price"
                  checked={this.props.price_selected_option === "user_purchase_price"}
                  value={this.props.user_purchase_price}
                  className="field"
                  name='radio_user_purchase_price'
                  type="radio"
                />
                <span className="radio secondary"></span>
                <span>{I18n.t('js.investment_calculations.facts_figures.purchase_price')}</span>
              </label>
            </div>
            <div className="calculations-table--col small">
              <label className="form--field -radio">
                <input onChange={this.props.handleOptionChangePrice}
                  id="radio_custom_purchase_price"
                  checked={this.props.price_selected_option === "radio_custom_purchase_price"}
                  value={this.props.custom_user_purchase_price}
                  className="field"
                  name='radio_custom_purchase_price'
                  type="radio"
                />
                <span className="radio secondary"></span>
                <span>{I18n.t('js.investment_calculations.facts_figures.custom')}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

    )
  }

}