/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import "@reach/slider/styles.css";
import DebounceInput from 'uiKit/inputs/DebounceInput/DebounceInput';
import I18n from 'i18n-js/index.js.erb';
import InfoTooltip from '../../../Tooltips/InfoTooltip/InfoTooltip';

export default class EditFinancingForm extends PureComponent {
  handleChange = (event) => {
    const { name, value } = event.target;

    this.props.handleChange(name, value);
  }

  render() {
    const {
      currencyFormatPercentage,
      defaultInterestRate,
      defaultMortgage,
      formatNumber,
      formatNumberWithoutCurrency,
      interestCosts,
      interestRate,
      mortgage,
      mortgageValue,
      renderMadeAssumptionTip,
      repayments,
      repaymentRate
    } = this.props;

    const defaultRepaymentRate = parseFloat(this.props.defaultRepaymentRate);

    return (
      <tbody>
        <tr>
          <td>{I18n.t('js.investment_calculations.detailed_financing_plan.mortgage')}</td>
          <td>
            <p>{formatNumber(mortgageValue)}</p>
          </td>
          <td className="smallWidthInput">
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="mortgage"
              placeholder={0}
              value={formatNumberWithoutCurrency(mortgage)}
              onChange={this.handleChange}
            />
            <span className="field symbol">%</span>
          </td>
          <td><span>{I18n.t('js.investment_calculations.detailed_financing_plan.of_purchasing_price')}</span></td>
          {
            (mortgage != defaultMortgage) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.detailed_financing_plan.mortgage'),
                      value: currencyFormatPercentage(defaultMortgage)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr className="height-40">
          <td> {I18n.t('js.investment_calculations.detailed_financing_plan.equity')}  </td>
          <td>
            <p>{formatNumber(this.props.equity)}
            </p>
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <p className="global-heading -tr- -hsd-">{I18n.t('js.investment_calculations.property_results.per_year')}</p>
          </td>
          <td>
            <p className="global-heading -hsd-">{I18n.t('js.investment_calculations.property_results.per_month')}</p>
          </td>
        </tr>
        <tr className="smallThirdCol">
          <td> {I18n.t('js.investment_calculations.complete_investment_table.interest_costs')} </td>
          <td>
            <p>{formatNumber(interestCosts)}</p>
          </td>
          <td>
            <p>{formatNumber(interestCosts / 12)}</p>
          </td>
          <td className="smallWidthInput">
            <DebounceInput
              className="field js-closing-costs-field"
              type="text" name="interest_rate"
              value={formatNumberWithoutCurrency(interestRate)}
              placeholder={0}
              onChange={this.handleChange}
              max="15"
            />
            <span className="field symbol">%</span>
          </td>
          {
            (interestRate != defaultInterestRate) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.complete_investment_table.interest_costs'),
                      value: currencyFormatPercentage(defaultInterestRate)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
        <tr className="smallThirdCol">
          <td>{I18n.t('js.investment_calculations.complete_investment_table.repayments')}</td>
          <td>
            <p>{formatNumber(repayments)}</p>
          </td>
          <td>
            <p>{formatNumber(repayments / 12)}</p>
          </td>
          <td className="smallWidthInput">
            <DebounceInput
              className="field js-closing-costs-field"
              type="text"
              name="repayment_rate"
              placeholder={0}
              value={formatNumberWithoutCurrency(repaymentRate)}
              onChange={this.handleChange}
              max="15"
            />
            <span className="field symbol">%</span>
          </td>
          {
            (repaymentRate != defaultRepaymentRate) &&
            <td className='tooltipMadeAssumption'>
              <InfoTooltip iconType='danger'>
                {
                  renderMadeAssumptionTip([
                    {
                      name: I18n.t('js.investment_calculations.complete_investment_table.repayments'),
                      value: currencyFormatPercentage(defaultRepaymentRate)
                    }
                  ])
                }
              </InfoTooltip>
            </td>
          }
        </tr>
      </tbody>
    )
  }
}
