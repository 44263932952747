import 'core-js/features/set'
import 'core-js/features/map'
import 'core-js/es/array'

import PlyrPlayer from 'vanilaComponents/PlyrPlayer'

const context = require.context('containers', true)
const ReactRailsUJS = require('react_ujs')

ReactRailsUJS.useContext(context)

document.addEventListener('turbolinks:load', () => {
  PlyrPlayer.setup()
})
